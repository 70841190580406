/*
Template Name: Cannabo
Template URL: https://html.vecuro.com/cannabo
Description: Cannabo - Marijuana and CBD Oil HTML5 Template
Author: vecuro
Author URI: https://themeforest.net/user/vecuro_themes
Version: 1.0.0
*/
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.10. Feature
    4.11. About
    4.12. Service
    4.13. Counter
    4.14. Team
    4.15. Process
    4.16. Accordion
    4.17. Simple Sections
    4.18. Testimonial
    4.19. Popup Side Menu
    4.20. Price Plan
    4.21. Forms Style
    4.22. Projects
    4.23. Contact
05. Spacing
06. Color Plate

*/
/*=================================
    CSS Index End
==================================*/
/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
/*------------------- 1.2. Function -------------------*/
/*------------------- 1.3. Variable-------------------*/
:root {
  --theme-color: #47b32d;
  --theme-color-light: #2f9916;
  --theme-color2: #ffcb24;
  --title-color: #010101;
  --body-color: #000000;
  --text-color: #4e4d4d;
  --text-color2: #cfcfcf;
  --smoke-color: #eff1f5;
  --secondary-color: #eef2fb;
  --bg1-color: #f9eeea;
  --bg2-color: #f5f5f5;
  --bg3-color: #1f2e21;
  --bg4-color: #fafafa;
  --bg4-color: #d9d9d9;
  --bg4-color: #f7f7f7;
  --bgSticky-color: #071c09;
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #fec624;
  --rating-color: #edb500;
  --success-color: #28a745;
  --error-color: #dc3545;
  --vs-border-color: #c1c1c1;
  --vs-border-color2: #57794f;
  --vs-border-color3: #d5d5d5;
  --vs-border-color4: #e0e0e0;
  --vs-border-color5: #e4e4e4;
  --vs-border-color6: #d1d1d1;
  --vs-border-color7: #dcdcdc;
  --vs-border-color8: #dbd8d8;
  --vs-border-color9: #c2c2c2;
  --vs-border-color10: #c3c3c3;
  --bg-color2: #4e614a;
  --gray-color: #f6f6f6;
  --banner-color1: #fff1b7;
  --banner-color2: #f9eeea;
  --bg-color1: #2b5022;
  --title-font: "Jost", sans-serif;
  --body-font: "Epilogue", sans-serif;
  --icon-font: "Font Awesome 5 Pro";
  --main-container: 1320px;
  --container-gutters: 30px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 5s;
}

/*------------------- 1.4. Reset -------------------*/
html,
body {
  scroll-behavior: auto !important;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  /* Small devices */
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 24px;
  }
}

iframe {
  border: none;
  width: 100%;
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--vs-border-color);
}

th {
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
}

td,
th {
  border: 1px solid var(--vs-border-color);
  padding: 9px 12px;
}

a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  transition: all ease 0.4s;
}
a:hover {
  color: var(--title-color);
}
a:active, a:focus, a:hover, a:visited {
  text-decoration: none;
  outline: 0;
}

button {
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

ins {
  text-decoration: none;
}

pre {
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.ajax-loader:empty,
p:empty {
  display: none;
}

p {
  margin: 0 0 18px 0;
  color: var(--text-color);
  font-family: var(--body-font);
  line-height: 1.63;
  /* Small devices */
}
@media (max-width: 767px) {
  p {
    line-height: 1.73;
  }
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.42;
  margin: -0.14em 0 15px 0;
}

.h1,
h1 {
  font-size: 65px;
}

.h2,
h2 {
  font-size: 60px;
}

.h3,
h3 {
  font-size: 40px;
}

.h4,
h4 {
  font-size: 36px;
}

.h5,
h5 {
  font-size: 24px;
}

.h6,
h6 {
  font-size: 20px;
}

/* Large devices */
@media (max-width: 1199px) {
  .h1,
  h1 {
    font-size: 40px;
  }
  .h2,
  h2 {
    font-size: 36px;
  }
  .h3,
  h3 {
    font-size: 30px;
  }
  .h4,
  h4 {
    font-size: 24px;
  }
  .h5,
  h5 {
    font-size: 20px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 30px;
  }
  .h2,
  h2 {
    font-size: 28px;
  }
  .h3,
  h3 {
    font-size: 26px;
  }
  .h4,
  h4 {
    font-size: 22px;
  }
  .h5,
  h5 {
    font-size: 18px;
  }
  .h6,
  h6 {
    font-size: 16px;
  }
}
/*------------------- 1.5. Responsive -------------------*/
/**
@include respond-above(sm) {
  .element {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-below(lg) {
  .element {
    font-weight: bold;
  }
}
@media (max-width: 767px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-between(sm, md) {
  .element {
    font-weight: bold;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .element {
    font-weight: bold;
  }
}
**/
/*------------------- 1.6. Wordpress Default -------------------*/
p.has-drop-cap {
  margin-bottom: 20px;
}

.page--item p:last-child .alignright {
  clear: right;
}

.blog-title,
.pagi-title,
.breadcumb-title {
  word-break: break-word;
}

.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
  color: var(--body-color);
}

.bypostauthor,
.gallery-caption {
  display: block;
}

.page-links,
.clearfix {
  clear: both;
}

.page--item {
  margin-bottom: 30px;
}
.page--item p {
  line-height: 1.8;
}

.content-none-search {
  margin-top: 30px;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.alignleft {
  display: inline;
  float: left;
  margin-bottom: 10px;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-left: 1.5em;
  margin-right: 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.gallery {
  margin-bottom: 1.5em;
  width: 100%;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  padding: 0 5px;
}

.wp-block-columns {
  margin-bottom: 1em;
}

figure.gallery-item {
  margin-bottom: 10px;
  display: inline-block;
}

figure.wp-block-gallery {
  margin-bottom: 14px;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
  font-size: 12px;
  color: var(--body-color);
  line-height: 1.5;
  padding: 0.5em 0;
}

.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: var(--white-color);
}

.wp-block-cover {
  margin-bottom: 15px;
}

.wp-caption-text {
  text-align: center;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}
.wp-caption .wp-caption-text {
  margin: 0.5em 0;
  font-size: 14px;
}

.wp-block-media-text,
.wp-block-media-text.alignwide,
figure.wp-block-gallery {
  margin-bottom: 30px;
}

.wp-block-media-text.alignwide {
  background-color: var(--smoke-color);
}

.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
  line-height: 1.4;
}

.wp-block-latest-comments a {
  color: inherit;
}

.wp-block-button {
  margin-bottom: 10px;
}
.wp-block-button:last-child {
  margin-bottom: 0;
}
.wp-block-button .wp-block-button__link {
  color: #fff;
}
.wp-block-button .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
}
.wp-block-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border-color: var(--title-color);
  color: var(--title-color);
}
.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

ol.wp-block-latest-comments li {
  margin: 15px 0;
}

ul.wp-block-latest-posts {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}
ul.wp-block-latest-posts a {
  color: inherit;
}
ul.wp-block-latest-posts a:hover {
  color: var(--theme-color);
}
ul.wp-block-latest-posts li {
  margin: 15px 0;
}

.wp-block-search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.wp-block-search .wp-block-search__input {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}
.wp-block-search .wp-block-search__button {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}
.wp-block-search .wp-block-search__button:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

ul.wp-block-rss a {
  color: inherit;
}

.wp-block-group.has-background {
  padding: 15px 15px 1px;
  margin-bottom: 30px;
}

.wp-block-table td,
.wp-block-table th {
  border-color: rgba(0, 0, 0, 0.1);
}

.wp-block-table.is-style-stripes {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.logged-in .will-sticky .sticky-active.active,
.logged-in .preloader .vs-btn {
  top: 32px;
}
@media (max-width: 782px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 46px;
  }
}
@media (max-width: 600px) {
  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 0;
  }
}

.post-password-form .theme-input-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 20px;
}
.post-password-form .theme-input-group .theme-input-style {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}
.post-password-form .theme-input-group .submit-btn {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}
.post-password-form .theme-input-group .submit-btn:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
  padding-top: 1em;
}
.page-links > .page-links-title {
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text),
.page-links > a {
  display: inline-block;
  padding: 5px 13px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 10px;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text):hover,
.page-links > a:hover {
  opacity: 0.8;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}
.page-links > span:not(.page-links-title):not(.screen-reader-text).current,
.page-links > a.current {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}
.page-links span.screen-reader-text {
  display: none;
}

.blog-single .wp-block-archives-dropdown {
  margin-bottom: 30px;
}
.blog-single.format-quote, .blog-single.format-link, .blog-single.tag-sticky-2, .blog-single.sticky {
  border-color: transparent;
  position: relative;
}
.blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
  background-color: var(--smoke-color);
  padding: 40px;
  border: none;
}
.blog-single.format-quote .blog-content:before, .blog-single.format-link .blog-content:before, .blog-single.tag-sticky-2 .blog-content:before, .blog-single.sticky .blog-content:before {
  display: none;
}
.blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
  content: "\f0c1";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 5rem;
  opacity: 0.3;
  right: 15px;
  line-height: 1;
  top: 15px;
  color: var(--theme-color);
  z-index: 1;
}
.blog-single.tag-sticky-2::before, .blog-single.sticky::before {
  content: "Featured";
  right: 0;
  top: 0;
  font-size: 18px;
  color: var(--white-color);
  background-color: var(--theme-color);
  font-family: var(--title-font);
  opacity: 1;
  text-transform: capitalize;
  padding: 10px 23px;
  font-weight: 400;
}
.blog-single.format-quote:before {
  content: "\f10e";
}
.blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
.blog-single .blog-content .wp-block-archives-dropdown {
  display: block;
  margin-bottom: 30px;
}

.blog-details .blog-single:before {
  display: none;
}
.blog-details .blog-single .blog-content {
  background-color: transparent;
  overflow: hidden;
}
.blog-details .blog-single.format-chat .blog-meta {
  margin-bottom: 20px;
}
.blog-details .blog-single.format-chat .blog-content > p:nth-child(2n) {
  background: var(--smoke-color);
  padding: 5px 20px;
}
.blog-details .blog-single.tag-sticky-2, .blog-details .blog-single.sticky, .blog-details .blog-single.format-quote, .blog-details .blog-single.format-link {
  box-shadow: none;
  border: none;
  background-color: transparent;
}
.blog-details .blog-single.tag-sticky-2:before, .blog-details .blog-single.sticky:before, .blog-details .blog-single.format-quote:before, .blog-details .blog-single.format-link:before {
  display: none;
}

.vs-search {
  background-color: #f3f3f3;
  margin-bottom: 30px;
  border: 1px solid #f3f3f3;
}
.vs-search .search-grid-content {
  padding: 30px;
  /* Small devices */
}
@media (max-width: 767px) {
  .vs-search .search-grid-content {
    padding: 20px;
  }
}
.vs-search .search-grid-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: -0.2em;
}
.vs-search .search-grid-title a {
  color: inherit;
}
.vs-search .search-grid-title a:hover {
  color: var(--theme-color);
}
.vs-search .search-grid-meta > * {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}
.vs-search .search-grid-meta > *:last-child {
  margin-right: 0;
}
.vs-search .search-grid-meta a,
.vs-search .search-grid-meta span {
  color: var(--body-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
  .blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
    padding: 30px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .blog-single.format-quote:before, .blog-single.format-link:before, .blog-single.tag-sticky-2:before, .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }
  .blog-single.format-quote .blog-content, .blog-single.format-link .blog-content, .blog-single.tag-sticky-2 .blog-content, .blog-single.sticky .blog-content {
    padding: 30px 15px;
  }
}
@media (max-width: 768px) {
  .wp-block-search .wp-block-search__input {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .wp-block-latest-comments {
    padding-left: 10px;
  }
  .page--content.clearfix + .vs-comment-form {
    margin-top: 24px;
  }
}
/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@media only screen and (min-width: 1300px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}
@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1399px) {
  .container-style2 {
    max-width: 1920px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}
.container-style3 {
  --main-container: 1445px;
}

@media (min-width: 1500px) and (max-width: 1921px) {
  .container-style1 {
    max-width: 1576px;
    overflow: hidden;
    margin-right: 0;
    padding-right: 0;
  }
}
/*------------------- 2.2. Grid -------------------*/
.slick-track > [class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 767px) {
  .row:not([class*=gx-]) {
    --bs-gutter-x: 30px;
  }
}
.gy-gx {
  --bs-gutter-y: var(--bs-gutter-x);
}

.gy-30 {
  --bs-gutter-y: 30px;
}

.gy-20 {
  --bs-gutter-y: 20px;
}

.gx-15 {
  --bs-gutter-x: 15px;
}

@media (min-width: 1199px) {
  .gx-45 {
    --bs-gutter-x: 45px;
  }
  .gx-60 {
    --bs-gutter-x: 60px;
  }
  .gx-50 {
    --bs-gutter-x: 50px;
  }
  .gx-70 {
    --bs-gutter-x: 70px;
  }
  .gx-80 {
    --bs-gutter-x: 80px;
  }
}
@media (min-width: 1399px) {
  .gx-20 {
    --bs-gutter-x: 20px;
  }
  .gx-30 {
    --bs-gutter-x: 30px;
  }
  .gx-40 {
    --bs-gutter-x: 40px;
  }
}
/*------------------- 2.3. Input -------------------*/
select,
.form-control,
.form-select,
textarea,
input {
  height: 50px;
  padding: 0 30px 0 28px;
  padding-right: 45px;
  border: 1px solid #e0e0e0;
  color: var(--body-color);
  background-color: var(--white-color);
  border-radius: 9999px;
  border-radius: 0;
  font-size: 14px;
  width: 100%;
}
select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  background-color: var(--white-color);
}
select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}
select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: var(--body-color);
}
select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}
select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}
select.form-control-lg,
.form-control.form-control-lg,
.form-select.form-control-lg,
textarea.form-control-lg,
input.form-control-lg {
  height: 60px;
}
select.form-control-sm,
.form-control.form-control-sm,
.form-select.form-control-sm,
textarea.form-control-sm,
input.form-control-sm {
  height: 40px;
  font-size: 12px;
}
select[readonly],
.form-control[readonly],
.form-select[readonly],
textarea[readonly],
input[readonly] {
  color: var(--title-color);
}

.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea.form-control,
textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
  border-radius: 0;
}

input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=checkbox]:checked ~ label:before {
  content: "\f00c";
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
input[type=checkbox] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}
input[type=checkbox] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--vs-border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=radio] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}
input[type=radio] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0.5px;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  transition: all 0.2s ease;
}
input[type=radio]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}

label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}
.form-group > i {
  position: absolute;
  right: calc(var(--bs-gutter-x) / 2 + 30px);
  top: 18px;
  font-size: 14px;
  color: #a0abb4;
}
.form-group.has-label > i {
  top: 45px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}
textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right calc(0.375em + 0.8875rem);
}

.row.no-gutters > .form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}
.form-messages.mb-0 * {
  margin-bottom: 0;
}
.form-messages.success {
  color: var(--success-color);
  display: block;
}
.form-messages.error {
  color: var(--error-color);
  display: block;
}
.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}

/*------------------- 2.4. Slick Slider -------------------*/
.slick-track > [class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.slick-track {
  min-width: 100%;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 40px 0 30px 0;
  line-height: 0;
  text-align: center;
  height: -moz-max-content;
  height: max-content;
}
.slick-dots li {
  display: inline-block;
  margin-right: 25px;
}
.slick-dots li:last-child {
  margin-right: 0;
}
.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 8px;
  height: 8px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  background-color: var(--dots-bg, #000);
  transition: all ease 0.4s;
  position: relative;
}
.slick-dots button:hover {
  border-color: var(--theme-color);
}
.slick-dots button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  border: 2px solid var(--theme-color);
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}
.slick-dots .slick-active button {
  background-color: var(--theme-color);
}
.slick-dots .slick-active button::before {
  opacity: 1;
  visibility: visible;
}

.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  border: 1px solid var(--smoke-color);
  left: var(--pos-x, -100px);
  width: var(--icon-size, 50px);
  height: var(--icon-size, 50px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 50px) / -2);
  z-index: 2;
  border-radius: 50%;
}
.slick-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}
.slick-arrow.slick-next {
  right: var(--pos-x, -100px);
  left: auto;
}
.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.arrow-margin .slick-arrow {
  top: calc(50% - 30px);
}

.arrow-wrap .slick-arrow {
  opacity: 0;
  visibility: hidden;
}
.arrow-wrap:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.dot-style2 .slick-dots {
  margin-top: 10px;
  margin-bottom: 30px;
}
.dot-style2 .slick-dots button {
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background-color: var(--theme-color);
}
.dot-style2 .slick-dots button:before {
  display: none;
}
.dot-style2 .slick-dots button:hover {
  background-color: var(--title-color);
}
.dot-style2 .slick-dots li {
  margin: 0 5px;
}
.dot-style2 .slick-dots li.slick-active button {
  width: 40px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .slick-arrow {
    --arrow-horizontal: -20px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .slick-arrow {
    --arrow-horizontal: 40px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;
  }
  .slick-arrow.slick-next {
    margin-right: 0;
    margin-left: 40px;
  }
  .slick-dots {
    margin: 8px 0 38px 0;
  }
}
.vs-carousel.style2 .slick-dots button {
  background-color: var(--title-color);
}
.vs-carousel.style2 .slick-dots button::before {
  border-color: var(--title-color);
}
.vs-carousel.style2 .slick-dots .slick-active button {
  background-color: var(--theme-color);
}
.vs-carousel.style2 .slick-dots .slick-active button::before {
  border-color: var(--theme-color);
}

/*------------------- 2.5. Mobile Menu -------------------*/
.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}
.vs-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: var(--bg3-color);
}
.vs-menu-wrapper .mobile-logo a {
  display: inline-block;
}
.vs-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}
.vs-menu-wrapper .vs-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  z-index: 1;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.vs-menu-wrapper .vs-menu-toggle:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.vs-menu-wrapper .vs-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  transition: all ease 1s;
  z-index: 1;
}
.vs-menu-wrapper.vs-body-visible {
  opacity: 1;
  visibility: visible;
}
.vs-menu-wrapper.vs-body-visible .vs-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}
.vs-mobile-menu .new-label {
  margin-left: 5px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.vs-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}
.vs-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}
.vs-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}
.vs-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
}
.vs-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: relative;
  left: 0;
  top: 0;
  margin-right: 10px;
  display: inline-block;
}
.vs-mobile-menu ul li.vs-active > a {
  color: var(--theme-color);
}
.vs-mobile-menu ul li.vs-active > a:before {
  transform: rotate(90deg);
}
.vs-mobile-menu ul li ul li {
  padding-left: 10px;
}
.vs-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}
.vs-mobile-menu ul .vs-item-has-children > a .vs-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.vs-mobile-menu ul .vs-item-has-children > a .vs-mean-expand:before {
  content: "\f067";
  font-family: var(--icon-font);
}
.vs-mobile-menu ul .vs-item-has-children.vs-active > a .vs-mean-expand:before {
  content: "\f068";
}
.vs-mobile-menu > ul {
  padding: 0 40px;
}
.vs-mobile-menu > ul > li:last-child {
  border-bottom: none;
}

.vs-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  display: inline-block;
  background-color: #f2f6ff;
  color: var(--theme-color);
  border-radius: 5px;
}
.vs-menu-toggle.style2 {
  background-color: var(--theme-color);
  border-radius: 50%;
  color: var(--white-color);
  width: 55px;
  height: 55px;
}

@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }
  .vs-mobile-menu > ul {
    padding: 0 20px;
  }
}
.menu-item-has-children.vs-item-has-children.vs-active .vs-mean-expand {
  background-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}

/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: var(--white-color);
}
.preloader .vs-btn {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 14px;
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.preloader-inner img {
  display: block;
  margin: 0 auto 10px auto;
}

.loader {
  animation: loaderspin 1.5s infinite;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
}
.loader:before, .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1.5s infinite;
  background-color: var(--sec-color);
  box-shadow: 30px 0 0 var(--theme-color);
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1.5s infinite;
  background-color: var(--theme-color);
  box-shadow: 30px 0 0 var(--title-color);
}

@keyframes loaderspin {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--theme-color);
  }
  50% {
    box-shadow: 0 0 0 var(--theme-color);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--theme-color);
    margin-bottom: 10px;
  }
}
@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--title-color);
  }
  50% {
    box-shadow: 0 0 0 var(--title-color);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--title-color);
    margin-top: 0;
  }
}
@keyframes spin {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(36deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(72deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(108deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(144deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(180deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(216deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(252deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(288deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(324deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(360deg);
  }
}
@keyframes speed {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes strikes {
  from {
    left: 25px;
  }
  to {
    left: -80px;
    opacity: 0;
  }
}
@keyframes dots {
  from {
    width: 0px;
  }
  to {
    width: 15px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn, .loading-window {
  animation: fadeIn 0.4s both;
}

.loading-window {
  height: 200px;
  left: 50%;
  margin-left: -150px;
  margin-top: -100px;
  position: fixed;
  top: 50%;
  width: 300px;
  z-index: 99;
}
.loading-window .text {
  font-size: 16px;
  position: absolute;
  width: auto;
  top: 75%;
  left: 38%;
  margin: 0 auto;
}
.loading-window .dots {
  display: inline-block;
  width: 5px;
  overflow: hidden;
  vertical-align: bottom;
  animation: dots 1.5s linear infinite;
  transition: 1;
}

.car {
  position: absolute;
  width: 117px;
  height: 42px;
  left: 92px;
  top: 85px;
}
.car .strike {
  position: absolute;
  width: 11px;
  height: 1px;
  background: var(--theme-color);
  animation: strikes 0.2s linear infinite;
}
.car .strike2 {
  top: 11px;
  animation-delay: 0.05s;
}
.car .strike3 {
  top: 22px;
  animation-delay: 0.1s;
}
.car .strike4 {
  top: 33px;
  animation-delay: 0.15s;
}
.car .strike5 {
  top: 44px;
  animation-delay: 0.2s;
}
.car-detail {
  position: absolute;
  display: block;
  background: var(--sec-color);
  animation: speed 0.5s linear infinite;
}
.car-detail.spoiler {
  width: 0;
  height: 0;
  top: 7px;
  background: none;
  border: 20px solid transparent;
  border-bottom: 8px solid var(--sec-color);
  border-left: 20px solid var(--sec-color);
}
.car-detail.back {
  height: 20px;
  width: 92px;
  top: 15px;
  left: 0px;
}
.car-detail.center {
  height: 35px;
  width: 75px;
  left: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 45px 40px;
  border: 4px solid var(--sec-color);
  background: none;
  box-sizing: border-box;
}
.car-detail.center1 {
  height: 35px;
  width: 35px;
  left: 12px;
  border-top-left-radius: 30px;
}
.car-detail.front {
  height: 20px;
  width: 50px;
  top: 15px;
  left: 67px;
  border-top-right-radius: 50px 40px;
  border-bottom-right-radius: 10px;
}
.car-detail.wheel {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 20px;
  left: 12px;
  border: 3px solid var(--theme-color);
  background: linear-gradient(45deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), linear-gradient(-45deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), linear-gradient(90deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), linear-gradient(0deg, transparent 45%, var(--sec-color) 46%, var(--sec-color) 54%, transparent 55%), radial-gradient(var(--sec-color) 29%, transparent 30%, transparent 50%, var(--sec-color) 51%), #333;
  animation-name: spin;
}
.car-detail.wheel2 {
  left: 82px;
}

/*------------------- 3.2. Buttons -------------------*/
.vs-btn {
  position: relative;
  display: inline-block;
  border: none;
  text-transform: capitalize;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 10px 30px;
  border-radius: 40px;
  z-index: 1;
  overflow: hidden;
}
.vs-btn::after, .vs-btn::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: var(--theme-color2);
  transition: all ease 0.5s;
  z-index: -1;
  border-radius: inherit;
}
.vs-btn:before {
  transform: rotateX(90deg);
}
.vs-btn:after {
  transform: rotateY(90deg);
}
.vs-btn > i {
  margin-left: 40px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  color: var(--title-color);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.vs-btn.style1, .vs-btn.style3 {
  padding: 9px 9px 9px 40px;
}
.vs-btn.style2 {
  padding: 21px 54px;
}
.vs-btn.style3 {
  padding: 7px 7px 7px 37px;
}
.vs-btn.style3 > i {
  background-color: var(--theme-color2);
  color: var(--title-color);
  margin-left: 26px;
}
.vs-btn.style4 {
  background-color: transparent;
  padding: 0;
}
.vs-btn.style4 > i {
  margin: 0;
  width: 52px;
  height: 52px;
  background-color: var(--smoke-color);
  color: var(--title-color);
}
.vs-btn.style4 i.fa-arrow-left {
  margin-right: 0;
}
.vs-btn:hover {
  color: var(--title-color);
  background-color: var(--theme-color2);
}
.vs-btn:hover i {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-btn:hover:before {
  transform: rotateX(0);
}
.vs-btn:hover:after {
  transform: rotateY(0);
}

.icon-btn {
  display: inline-block;
  width: var(--btn-size, 67px);
  height: var(--btn-size, 67px);
  line-height: var(--btn-size, 67px);
  font-size: var(--btn-font-size, 20px);
  background-color: var(--theme-color);
  color: var(--white-color);
  border: none;
  text-align: center;
  border-radius: 50%;
  padding: 0;
}
.icon-btn.style2 {
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;
}
.icon-btn.style2:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.icon-btn.style4, .icon-btn.style3 {
  --btn-size: 55px;
  background-color: #f2f6ff;
  color: var(--theme-color);
  border-radius: 5px;
}
.icon-btn.style4 {
  background-color: var(--white-color);
}
.icon-btn.style5 {
  background-color: var(--white-color);
  color: var(--title-color);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.08);
}
.icon-btn.style5:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  box-shadow: none;
}
.icon-btn.style6 {
  background-color: var(--theme-color);
  color: var(--white-color);
  --btn-size: 55px;
  --btn-font-size: 17px;
}
.icon-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: transparent;
}

.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  border: none;
  background-color: transparent;
  padding: 0;
}
.play-btn > i {
  display: inline-block;
  width: var(--icon-size, 124px);
  height: var(--icon-size, 124px);
  line-height: var(--icon-size, 124px);
  font-size: var(--icon-font-size, 1.5em);
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  z-index: 1;
  transition: all ease 0.4s;
}
.play-btn:after, .play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  transition: all ease 0.4s;
}
.play-btn:after {
  animation-delay: 2s;
}
.play-btn.style3 i, .play-btn.style2 i {
  --icon-size: 60px;
  border-radius: 5px;
  --icon-font-size: 18px;
}
.play-btn.style2:after, .play-btn.style2:before, .play-btn.style5:after, .play-btn.style5:before {
  border-radius: 5px;
}
.play-btn.style3 i {
  background-color: rgba(14, 89, 242, 0.7);
}
.play-btn.style3:after, .play-btn.style3::before {
  display: none;
}
.play-btn.style4 {
  --icon-size: 120px;
  --icon-font-size: 30px;
}
.play-btn.style4:after, .play-btn.style4:before,
.play-btn.style4 i {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.play-btn.style5:after, .play-btn.style5:before {
  background-color: var(--theme-color);
}
.play-btn.style6 {
  --icon-size: 60px;
  --icon-font-size: 18px;
}
.play-btn.style7::before, .play-btn.style7::after {
  border: 2px solid var(--white-color);
  background-color: transparent;
  border-radius: 50%;
  animation-name: ripple2;
  animation-duration: 3s;
}
.play-btn.style7 i {
  border-radius: 50%;
  --icon-size: 80px;
  --icon-font-size: 18px;
  background-color: var(--white-color);
  color: var(--title-color);
}
.play-btn:hover:after, .play-btn:hover::before,
.play-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}
.play-btn:hover::before, .play-btn:hover::after {
  border-color: var(--title-color);
}

.link-btn {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;
}
.link-btn i {
  margin-left: 7px;
  font-size: 0.9rem;
}
.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: var(--theme-color);
  transition: all ease 0.4s;
}
.link-btn:hover {
  color: var(--theme-color);
}
.link-btn:hover::before {
  width: 100%;
}

.scroll-btn {
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  border-radius: 50%;
  /* Small devices */
}
.scroll-btn i {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 50px);
  z-index: 2;
  border-radius: inherit;
  position: relative;
  transition: all ease 0.8s;
}
.scroll-btn:before {
  content: "";
  position: absolute;
  left: var(--extra-shape, -6px);
  top: var(--extra-shape, -6px);
  right: var(--extra-shape, -6px);
  bottom: var(--extra-shape, -6px);
  background-color: transparent;
  border-radius: inherit;
  z-index: 1;
  border: 2px dashed var(--theme-color);
  animation: spin 13s infinite linear;
}
.scroll-btn:focus i, .scroll-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}
.scroll-btn.show {
  bottom: 120px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .scroll-btn {
    --btn-size: 40px;
    --extra-shape: -4px;
    right: 15px;
    bottom: 50px;
  }
  .scroll-btn.show {
    bottom: 15px;
  }
}

.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 96;
}
.scrollToTop.show {
  bottom: 60px;
  opacity: 1;
  visibility: visible;
}

/* Large devices */
@media (max-width: 1199px) {
  .play-btn.style4 {
    --icon-size: 80px;
    --icon-font-size: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .play-btn {
    --icon-size: 60px;
  }
  .scrollToTop {
    right: 20px;
  }
  .scrollToTop.show {
    bottom: 20px;
  }
}
/*------------------- 3.3. Titles -------------------*/
.sec-icon {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: -0.07em 0 20px 0;
}
.sec-icon::before {
  position: absolute;
  content: "";
  height: 1px;
  background-color: var(--theme-color);
  width: 182px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.sec-title {
  text-transform: capitalize;
  line-height: 1.35;
  margin: -0.25em 0 18px 0;
}

.sec-title2 {
  font-size: 38px;
  text-align: center;
  margin: -10px 0 30px 0;
}
.sec-title2 i {
  margin-right: 15px;
}

.sec-subtitle {
  text-transform: uppercase;
  display: block;
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 900;
  margin: -0.25em 0 15px 0;
  letter-spacing: 0.12em;
}
.sec-subtitle.style2 {
  color: var(--title-color);
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 20px);
}

.title-area2 {
  margin-bottom: calc(var(--section-title-space) - 0px);
}

.sec-btns {
  padding: 0px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: calc(var(--section-title-space) - 20px);
}
.sec-btns i.fa-arrow-left {
  margin-left: 0;
  margin-right: 10px;
}

.sec-line-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
  margin: 0 0 80px 0;
}
.sec-line-wrap .sec-title2 {
  margin: -0.3em 0 -0.4em 0;
}

.sec-line {
  background-color: var(--theme-color);
  flex: 1;
  height: 2px;
}

.border-title {
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 0;
}
.border-title-border {
  width: 48px;
  height: 2px;
  display: inline-block;
  background-color: var(--theme-color);
  margin-bottom: 25px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .sec-title4 {
    font-size: 42px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .sec-title4 {
    font-size: 36px;
    line-height: 1.4;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 36px;
  }
  .sec-line-wrap {
    margin: 0 0 60px 0;
  }
  .sec-subtitle2 {
    margin-bottom: 15px;
  }
  .title-area {
    margin-bottom: 40px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 30px;
  }
  .sec-subtitle {
    font-size: 16px;
  }
  .sec-line-wrap {
    margin: 0 0 40px 0;
    gap: 10px;
  }
  .sec-title4 {
    font-size: 30px;
    line-height: 1.4;
  }
}
/*------------------- 3.4. Common -------------------*/
.image-scale-hover {
  overflow: hidden;
}
.image-scale-hover img {
  transition: all ease 0.4s;
  transform: scale(1.001);
}
.image-scale-hover:hover img {
  transform: scale(1.2);
}

.alert-dismissible .btn-close {
  font-size: 18px;
  line-height: 1;
}

.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 3;
}

.z-index-n1 {
  z-index: -1;
}

.media-body {
  flex: 1;
}

.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.35em 0.55em;
  border-radius: 50%;
}

.hr-style1 {
  background-color: var(--white-color);
  margin: 20px 0 30px 0;
}

.new-label {
  font-size: 12px;
  background-color: var(--error-color);
  color: var(--white-color);
  line-height: 1;
  padding: 1px 5px;
  border-radius: 4px;
  text-transform: capitalize;
  position: relative;
  top: -1px;
}

.big-name {
  font-size: 450px;
  color: var(--white-color);
  -webkit-text-stroke: 2px #eef0ff;
  line-height: 1;
  letter-spacing: -0.08em;
  font-weight: 400;
  position: relative;
  z-index: 31;
  text-align: right;
  margin: -234px 30px -59px 0;
}

.sec-big-text2,
.sec-big-text {
  font-size: 160px;
  font-weight: 700;
  font-family: var(--title-font);
  line-height: 1;
  display: block;
  color: #f1f3f9;
  position: absolute;
  left: 0;
  right: 0;
  top: -37px;
  z-index: -1;
}

.sec-big-text2 {
  top: auto;
  left: auto;
  right: 120px;
  bottom: -26px;
}

.home-five-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f6f7fc;
  width: 100%;
  max-width: 1255px;
  z-index: -3;
}

.home-five-shape2 {
  position: absolute;
  left: -428px;
  bottom: 250px;
  width: 1024px;
  height: 1024px;
  border: 50px solid var(--theme-color);
  border-radius: 50%;
  z-index: -2;
}

.home-five-shape3 {
  position: absolute;
  right: 60px;
  bottom: 325px;
}

.home-five-wrap1 {
  position: relative;
  border-bottom: 7px solid var(--theme-color);
  z-index: 1;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .ls-wrapper > .ls-layer.d-hd-none,
  .ls-wrapper > a > .ls-layer.d-hd-none,
  .d-hd-none {
    display: none !important;
  }
}
@media (min-width: 1500px) {
  .d-xxxl-block {
    display: block !important;
  }
}
/* Extra large devices */
@media (max-width: 1500px) {
  .big-name {
    font-size: 320px;
    margin: -168px 30px -59px 0;
  }
  .home-five-shape3 {
    right: 30px;
    bottom: 50px;
    max-width: 200px;
  }
  .home-five-shape2 {
    left: -18%;
    bottom: 34%;
    width: 500px;
    height: 500px;
  }
  .home-five-shape1 {
    max-width: 100%;
    right: 60px;
    width: auto;
  }
  .sec-big-text2,
  .sec-big-text {
    font-size: 130px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .big-name {
    font-size: 245px;
    margin: -128px 40px -42px 0;
  }
  .home-five-shape1 {
    right: 0;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .big-name {
    font-size: 190px;
    margin: -100px 40px -42px 0;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .big-name {
    font-size: 146px;
    margin: -78px 40px -25px 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .big-name {
    font-size: 75px;
    margin: -20px 0 -12px 0;
    letter-spacing: 0;
    text-align: center;
  }
}
.bg-one {
  background-color: var(--bg2-color);
}

.round-30 {
  border-radius: 30px;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 22px;
}
.social-links .links-title {
  font-size: 16px;
  font-weight: 700;
}
.social-links ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 9px;
}
.social-links ul li a {
  display: inline-flex;
  color: var(--title-color);
  --icon-size: 36px;
  width: var(--icon-size);
  height: var(--icon-size);
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  border-radius: 50%;
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
  border: 1px solid var(--vs-border-color);
}
.social-links ul li a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}

/*------------------- 3.6. Font -------------------*/
.font-icon {
  font-family: var(--icon-font);
}

.font-title {
  font-family: var(--title-font);
}

.font-body {
  font-family: var(--body-font);
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

/*------------------- 3.7. Background -------------------*/
.bg-theme {
  background-color: var(--theme-color) !important;
}

.bg-smoke {
  background-color: var(--smoke-color) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-title {
  background-color: var(--title-color) !important;
}

.bg-gray {
  background-color: #e5e5e5;
}

.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.bg-auto {
  background-size: auto auto;
}

/*------------------- 3.8. Text Color -------------------*/
.text-theme {
  color: var(--theme-color) !important;
}

.text-title {
  color: var(--title-color) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.text-inherit, .footer-info-list .info a {
  color: inherit;
}
.text-inherit:hover, .footer-info-list .info a:hover {
  color: var(--theme-color);
}

a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}

.text-light2 {
  color: #919398;
}

/*------------------- 3.9. Overlay -------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-overlay] {
  position: relative;
}
[data-overlay] [class^=col-],
[data-overlay] [class*=col-] {
  z-index: 1;
}

[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

[data-overlay=theme]:before {
  background-color: var(--theme-color);
}

[data-overlay=title]:before {
  background-color: var(--title-color);
}

[data-overlay=white]:before {
  background-color: var(--white-color);
}

[data-overlay=black]:before {
  background-color: var(--black-color);
}

[data-opacity="1"]:before {
  opacity: 0.1;
}

[data-opacity="2"]:before {
  opacity: 0.2;
}

[data-opacity="3"]:before {
  opacity: 0.3;
}

[data-opacity="4"]:before {
  opacity: 0.4;
}

[data-opacity="5"]:before {
  opacity: 0.5;
}

[data-opacity="6"]:before {
  opacity: 0.6;
}

[data-opacity="7"]:before {
  opacity: 0.7;
}

[data-opacity="8"]:before {
  opacity: 0.8;
}

[data-opacity="9"]:before {
  opacity: 0.9;
}

[data-opacity="10"]:before {
  opacity: 1;
}

/*------------------- 3.10. Animation -------------------*/
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.wow-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadein {
  --animation-name: fadein-custom;
}

.slideinup {
  --animation-name: slideinup;
}

.slideindown {
  --animation-name: slideindown;
}

.slideinleft {
  --animation-name: slideinleft;
}

.slideinright {
  --animation-name: slideinright;
}

.animated {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: var(--animation-name);
}

.ripple-animation, .play-btn:after, .play-btn:before {
  animation-duration: var(--ripple-ani-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideinleft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideinright {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fadein-custom {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.vsZoomOut {
  animation-name: vsZoomOut;
}

@keyframes vsZoomOut {
  from {
    opacity: 0;
    -webkit-transform: scale3d(10, 10, 10);
    -moz-transform: scale3d(10, 10, 10);
    -ms-transform: scale3d(10, 10, 10);
    -o-transform: scale3d(10, 10, 10);
    transform: scale3d(10, 10, 10);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
/*------------------- 3.11. Range Slider -------------------*/
.calc-range {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 15px;
}
.calc-range .range-title {
  flex: none;
  margin-bottom: 0;
  font-weight: 700;
  margin-right: 29px;
  padding-top: 7px;
}
.calc-range #slider-range-min,
.calc-range #slider-range-min2 {
  min-width: 358px;
  height: 16px;
}
@media (max-width: 767px) {
  .calc-range #slider-range-min,
  .calc-range #slider-range-min2 {
    min-width: 100%;
  }
}
.calc-range #slider-range-min .ui-widget-content,
.calc-range #slider-range-min2 .ui-widget-content {
  border: 2px solid white;
  background-color: transparent;
}
.calc-range #slider-range-min .ui-widget.ui-widget-content,
.calc-range #slider-range-min2 .ui-widget.ui-widget-content {
  border: 2px solid #fff;
}
.calc-range #slider-range-min .ui-state-default,
.calc-range #slider-range-min .ui-widget-content .ui-state-default,
.calc-range #slider-range-min2 .ui-state-default,
.calc-range #slider-range-min2 .ui-widget-content .ui-state-default {
  border: 0;
  background-color: var(--theme-color);
  border-radius: 50%;
  outline: none;
  box-shadow: none;
}
.calc-range .ui-corner-all,
.calc-range .ui-corner-bottom,
.calc-range .ui-corner-right,
.calc-range .ui-corner-br {
  border-bottom-right-radius: 7.82px;
}
.calc-range .ui-corner-all,
.calc-range .ui-corner-bottom,
.calc-range .ui-corner-left,
.calc-range .ui-corner-bl {
  border-bottom-left-radius: 7.82px;
}
.calc-range .ui-corner-all,
.calc-range .ui-corner-top,
.calc-range .ui-corner-right,
.calc-range .ui-corner-tr {
  border-top-right-radius: 7.82px;
}
.calc-range .ui-corner-all,
.calc-range .ui-corner-top,
.calc-range .ui-corner-left,
.calc-range .ui-corner-tl {
  border-top-left-radius: 7.82px;
}
.calc-range .ui-widget.ui-widget-content {
  border: 2px solid #fff;
  background-color: transparent;
}
.calc-range .ui-slider .ui-slider-handle {
  width: 26px;
  height: 26px;
}
.calc-range .ui-slider-horizontal .ui-slider-handle {
  top: -7px;
  margin-left: -16px;
}
.calc-range .ui-widget-header {
  background: var(--theme-color);
  margin: -2px -2px;
  height: 16px;
}
.calc-range .range-amount {
  padding: 0;
  min-width: auto;
  flex: 1;
  background-color: transparent;
  border: 0;
  height: auto;
  font-size: 16px;
  font-weight: 700;
  margin-left: 16px;
}

/*=================================
    04. Template Style
==================================*/
/*------------------- 4.1. Widget  -------------------*/
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget_nav_menu > ul,
.widget_meta > ul,
.widget_pages > ul,
.widget_archive > ul,
.widget_categories > ul {
  margin: 0 0 -38px 0;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: block;
  background-color: transparent;
  color: var(--body-color);
  margin: 0 0 18px 0;
  padding: 0 0 17px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  border-bottom: 1px solid #dadfec;
}
.widget_nav_menu a::before,
.widget_meta a::before,
.widget_pages a::before,
.widget_archive a::before,
.widget_categories a::before {
  content: "\f178";
  position: absolute;
  right: 0;
  top: 0;
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--theme-color);
}
.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
  color: var(--theme-color);
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}
.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a {
  border-bottom: none;
}
.widget_nav_menu li > span,
.widget_meta li > span,
.widget_pages li > span,
.widget_archive li > span,
.widget_categories li > span {
  text-align: center;
  position: absolute;
  right: 0;
  top: -4.5px;
  font-size: 16px;
  transition: all ease 0.4s;
}
.widget_nav_menu li:hover > span,
.widget_meta li:hover > span,
.widget_pages li:hover > span,
.widget_archive li:hover > span,
.widget_categories li:hover > span {
  color: var(--theme-color);
}
.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}

.widget_nav_menu .sub-menu {
  margin-left: 10px;
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.wp-block-archives a:not(:hover) {
  color: inherit;
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

/* Small devices */
@media (max-width: 767px) {
  .widget_nav_menu a,
  .widget_meta a,
  .widget_pages a,
  .widget_archive a,
  .widget_categories a {
    font-size: 14px;
  }
}
/*------------------- 4.1. Widget  -------------------*/
.widget {
  padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 40px);
  background-color: var(--smoke-color);
  position: relative;
  margin-bottom: 30px;
  border-radius: 30px;
  border: 1px solid var(--vs-border-color5);
}
.widget:last-child {
  margin-bottom: 0;
}
.widget select,
.widget input {
  height: 55px;
  border: none;
  background-color: #fff;
  padding-left: 20px;
  font-weight: 400;
}

.widget_title {
  position: relative;
  font-size: 26px;
  font-weight: 700;
  line-height: 1em;
  margin: -0.07em 0 30px 0;
  font-family: var(--title-font);
  padding: 0 0 10px 0;
}
.widget_title::after {
  position: absolute;
  content: "";
  width: 40px;
  height: 4px;
  background-color: var(--theme-color);
  bottom: 0;
  left: 0;
}

.widget .search-form {
  position: relative;
  display: flex;
  background-color: var(--white-color);
  border-radius: 9999px;
  overflow: hidden;
  padding: 9px;
  border: 1px solid var(--vs-border-color5);
}
.widget .search-form input {
  flex: 1;
  height: auto;
}
.widget .search-form button {
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  height: 49px;
  width: 49px;
  line-height: 1;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.widget .search-form button:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
}

.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 9.5px 16.5px;
  margin-right: 5px;
  margin-bottom: 10px;
  color: var(--title-color);
  background-color: var(--smoke-color);
  border-radius: 4px;
}
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;
}
.tagcloud a {
  background-color: var(--white-color);
  color: var(--title-color);
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.recent-post:last-child {
  margin-bottom: 0;
}
.recent-post .media-img {
  --icon-size: 100px;
  margin-right: 20px;
  width: var(--icon-size);
  height: var(--icon-size);
  overflow: hidden;
  border-radius: 10px;
}
.recent-post .media-img img {
  width: 100%;
  border-radius: 4px;
  transition: all ease 0.4s;
}
.recent-post .post-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px 0;
  padding-right: 20px;
}
.recent-post .recent-post-meta a {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
  color: var(--text-color);
}
.recent-post .recent-post-meta a i {
  margin-right: 5px;
  color: var(--theme-color);
}
.recent-post:hover .media-img img {
  transform: scale(1.1);
}

.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;
}
.wp-block-calendar span[class*=wp-calendar-nav],
.calendar_wrap span[class*=wp-calendar-nav] {
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  z-index: 1;
}
.wp-block-calendar span[class*=wp-calendar-nav] a,
.calendar_wrap span[class*=wp-calendar-nav] a {
  color: inherit;
}
.wp-block-calendar span.wp-calendar-nav-next,
.calendar_wrap span.wp-calendar-nav-next {
  left: auto;
  right: 20px;
}
.wp-block-calendar caption,
.calendar_wrap caption {
  caption-side: top;
  text-align: center;
  color: var(--title-color);
  background-color: #f1dbbe;
}
.wp-block-calendar th,
.calendar_wrap th {
  font-size: 14px;
  padding: 5px 5px;
  border: none;
  text-align: center;
  border-right: 1px solid #fff;
  color: #01133c;
  font-weight: 500;
}
.wp-block-calendar th a,
.calendar_wrap th a {
  color: inherit;
}
.wp-block-calendar td,
.calendar_wrap td {
  font-size: 14px;
  padding: 5px;
  color: #01133c;
  border: 1px solid #ededed;
  text-align: center;
  background-color: transparent;
  transition: all ease 0.4s;
}
.wp-block-calendar #today,
.calendar_wrap #today {
  color: var(--theme-color);
  background-color: var(--white-color);
  border-color: #ededed;
}
.wp-block-calendar thead,
.calendar_wrap thead {
  background-color: #f6f6f6;
}
.wp-block-calendar .wp-calendar-table,
.calendar_wrap .wp-calendar-table {
  margin-bottom: 0;
}
.wp-block-calendar .wp-calendar-nav .pad,
.calendar_wrap .wp-calendar-nav .pad {
  display: none;
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  border-radius: 5px;
}
.sidebar-gallery .gallery-thumb img {
  width: 100%;
  transform: scale(1);
  transition: all ease 0.4s;
}
.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.2);
}

.widget_shopping_cart {
  text-align: left;
}
.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget_shopping_cart .mini_cart_item {
  position: relative;
  border-bottom: 1px solid var(--sec-color);
  padding: 0 0 32px 110px;
  margin: 0 0 27px 0;
  min-height: 90px;
}
.widget_shopping_cart .remove {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--title-color);
  line-height: 1;
  font-size: 18px;
}
.widget_shopping_cart .remove:hover {
  color: var(--theme-color);
}
.widget_shopping_cart .img {
  position: absolute;
  left: 0;
  top: 3px;
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 1px solid var(--sec-color);
}
.widget_shopping_cart .product-title {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 400;
  margin-bottom: 0;
  display: inline-block;
}
.widget_shopping_cart .amount {
  display: block;
  font-weight: 600;
  color: var(--title-color);
  font-size: 16px;
}
.widget_shopping_cart .quantity {
  display: inline-flex;
  margin-top: 12px;
}
.widget_shopping_cart .qut-btn {
  border: 1px solid var(--sec-color);
  background-color: transparent;
  display: inline-block;
  background-color: transparent;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: 12px;
  z-index: 1;
  position: relative;
}
.widget_shopping_cart .qut-btn:hover {
  background-color: var(--title-color);
  border-color: transparent;
  color: var(--white-color);
}
.widget_shopping_cart .qty-input {
  border: 1px solid var(--sec-color);
  text-align: center;
  width: max-content;
  min-width: 40px;
  font-size: 12px;
  padding: 0;
  height: 25px;
  margin: 0 -1px;
}
.widget_shopping_cart .subtotal {
  float: right;
  text-align: right;
  font-size: 12px;
  margin-top: 19px;
}
.widget_shopping_cart .subtotal > span:not(.amount) {
  color: var(--body-color);
  font-weight: 300;
}
.widget_shopping_cart .subtotal .amount {
  font-size: 12px;
  display: inline-block;
}
.widget_shopping_cart .total {
  color: var(--title-color);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 25px;
  font-size: 18px;
}
.widget_shopping_cart .total .amount {
  font-size: inherit;
  display: inline-block;
  color: var(--theme-color);
}
.widget_shopping_cart .buttons {
  margin: 0;
  gap: 15px;
  flex-wrap: wrap;
}

.sidebar-area {
  margin-bottom: 30px;
  position: sticky;
  top: 30px;
}
.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}
.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}
.sidebar-area .newsletter-form button {
  text-transform: capitalize;
  margin-top: 15px;
  padding: 22px 47px;
  font-family: var(--body-font);
}
.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}
.sidebar-area .wp-block-group__inner-container h2 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 20px;
  margin-top: -0.07em;
}
.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}
.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.recent-product {
  display: flex;
}
.recent-product .product-title {
  font-size: 16px;
}

.widget-workhours ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.widget-workhours li {
  background-color: var(--white-color);
  padding: 14.5px 20px;
  margin: 0 0 15px 0;
  font-family: var(--title-font);
  font-weight: 500;
}
.widget-workhours li:last-child {
  margin-bottom: 0;
}
.widget-workhours li i {
  margin-right: 10px;
}

.quote-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;
}
.quote-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 26, 62, 0.7);
}
.quote-box__title {
  color: var(--white-color);
  position: relative;
  z-index: 1;
  max-width: 200px;
  margin: -0.3em auto 25px auto;
}

/* Large devices */
@media (max-width: 1199px) {
  .widget {
    --widget-padding-y: 30px;
    --widget-padding-x: 30px;
  }
  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .recent-post .post-title {
    font-size: 14px;
    line-height: 22px;
  }
  .recent-post .recent-post-meta a {
    font-size: 12px;
  }
  .widget-workhours li {
    padding: 14.5px 20px;
    font-size: 14px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .sidebar-area {
    padding-top: 30px;
  }
  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .contact-widget .icon {
    --icon-size: 30px;
    --icon-font-size: 12px;
    top: 5px;
  }
  .contact-widget .contact {
    padding: 0 0 0 45px;
    margin: 0 0 20px 0;
    border-bottom: none;
  }
  .sidebar-gallery {
    gap: 10px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .widget {
    padding: 30px 20px;
  }
}
.widget_categories {
  padding: 0;
  margin-bottom: 0;
}
.widget_categories li {
  margin: 0 0 3px 0;
}
.widget_categories li:last-child {
  margin: 0 0 0px 0;
}
.widget_categories li a {
  background-color: var(--white-color);
  padding: 5px 5px 5px 30px;
  margin: 0 0 0px 0;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  font-weight: 700;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--title-color);
}
.widget_categories li a::before {
  display: none;
}
.widget_categories li a span {
  min-width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color2);
  color: var(--text-color);
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.widget_categories li a span::before {
  content: "\f061";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--icon-font);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.widget_categories li a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.widget_categories li a:hover span {
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.widget_links {
  padding: 0;
  margin: 0;
  list-style: none;
}
.widget_links li {
  margin-bottom: 10px;
}
.widget_links li:last-child {
  margin-bottom: 0;
}
.widget_links li a {
  display: block;
  position: relative;
  color: var(--text-color);
  padding-left: 15px;
  font-family: var(--body-font);
}
.widget_links li a::before {
  content: "\f105";
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 4px;
  line-height: 1;
}
.widget_links li a:hover {
  color: var(--theme-color);
}
.widget_links.style2 li a {
  padding-left: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bg4-color);
}
.widget_links.style2 li a::before {
  display: none;
}
.widget_links.style2 li:last-child a {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.widget.form {
  background-color: var(--bg3-color);
}
.widget .newsletter-form {
  background-color: transparent;
  padding: 0;
}
.widget .newsletter-form .form-control {
  min-width: 100%;
  background-color: var(--white-color);
}
.widget .newsletter-form .newsletter-text {
  color: var(--text-color2);
}
.widget .newsletter-form .vs-btn:hover {
  color: var(--title-color);
}

.sidebar-area.product {
  background-color: var(--smoke-color);
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .sidebar-area.product {
    padding: 15px;
  }
}

.widget--product {
  padding: 0;
  border: 0;
}
.widget--product:last-child {
  margin-bottom: 0;
}
.widget--product .search-form {
  position: relative;
  display: flex;
  background-color: var(--white-color);
  border-radius: 999px;
  height: 55px;
  align-items: center;
  padding-right: 10px;
}
.widget--product .search-form input {
  flex: 1;
  border-radius: 999px;
  font-size: 14px;
  height: 55px;
}
.widget--product .search-form button {
  border: none;
  background-color: var(--theme-color2);
  color: var(--title-color);
  height: 40px;
  width: 40px;
  font-size: 18px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.widget--product .search-form button:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

/*------------------- 4.1. Widget  -------------------*/
.footer-widget {
  margin-bottom: 40px;
}
.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
}
.footer-widget .widget_title {
  margin: 0 0 45px 0;
  font-size: 20px;
  font-family: var(--body-font);
  font-weight: 800;
}
.footer-widget .widget_title.style2, .footer-widget .widget_title.style3 {
  color: var(--white-color);
}
@media (max-width: 1199px) {
  .footer-widget .widget_title.style2, .footer-widget .widget_title.style3 {
    font-size: 18px;
  }
}
.footer-widget.widget_meta, .footer-widget.widget_pages, .footer-widget.widget_archive, .footer-widget.widget_categories, .footer-widget.widget_nav_menu {
  margin-bottom: 40px;
}
.footer-widget.widget_meta a, .footer-widget.widget_pages a, .footer-widget.widget_archive a, .footer-widget.widget_categories a, .footer-widget.widget_nav_menu a {
  width: max-content;
  display: block;
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  max-width: 100%;
  padding: 0;
}
.footer-widget.widget_meta a:hover, .footer-widget.widget_pages a:hover, .footer-widget.widget_archive a:hover, .footer-widget.widget_categories a:hover, .footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: var(--theme-color);
}
.footer-widget.widget_meta a:before, .footer-widget.widget_pages a:before, .footer-widget.widget_archive a:before, .footer-widget.widget_categories a:before, .footer-widget.widget_nav_menu a:before {
  display: none;
}
.footer-widget.widget_meta li > span, .footer-widget.widget_pages li > span, .footer-widget.widget_archive li > span, .footer-widget.widget_categories li > span, .footer-widget.widget_nav_menu li > span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: var(--body-color);
  line-height: 1;
}
.footer-widget.widget_meta li:last-child a, .footer-widget.widget_pages li:last-child a, .footer-widget.widget_archive li:last-child a, .footer-widget.widget_categories li:last-child a, .footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}
.footer-widget .footer-links > ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.footer-widget .footer-links a {
  margin-bottom: 21px;
}

.footer-logo {
  margin-bottom: 25px;
}

.footer-text {
  max-width: 80%;
  line-height: 30px;
}
@media (max-width: 1199px) {
  .footer-text {
    max-width: 100%;
  }
}
.footer-text.style2, .footer-text.style3 {
  color: var(--white-color);
}

.footer-info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  text-align: left;
}
.footer-info:last-child {
  margin-bottom: 0;
}
.footer-info__icon {
  min-width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color2);
  color: var(--white-color);
  font-size: 30px;
  margin: 0 17px 0 0;
  border-radius: 50%;
}
.footer-info__label {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--body-font);
  display: block;
  margin: 0 0 0px 0;
}
.footer-info__link {
  color: var(--black-color);
  margin: 0 0 -0.15em 0;
  padding-right: 0px;
}
.footer-info__link p {
  margin-bottom: 0;
}
.footer-info__link a {
  color: inherit;
}
.footer-info__link a:hover {
  color: var(--theme-color);
}
.footer-info.style2 .footer-info__link, .footer-info.style2 .footer-info__label, .footer-info.style3 .footer-info__link, .footer-info.style3 .footer-info__label {
  color: var(--white-color);
}
.footer-info.style2 .footer-info__link p,
.footer-info.style2 .footer-info__link a, .footer-info.style2 .footer-info__label p,
.footer-info.style2 .footer-info__label a, .footer-info.style3 .footer-info__link p,
.footer-info.style3 .footer-info__link a, .footer-info.style3 .footer-info__label p,
.footer-info.style3 .footer-info__label a {
  color: var(--white-color);
}
.footer-info.style2 .footer-info__link a:hover, .footer-info.style2 .footer-info__label a:hover, .footer-info.style3 .footer-info__link a:hover, .footer-info.style3 .footer-info__label a:hover {
  color: var(--theme-color2);
}
.footer-info.style3 {
  padding-right: 50px;
}
@media (max-width: 1199px) {
  .footer-info.style3 {
    padding-right: 0;
  }
}
.footer-info.style3 .footer-info__link a:hover, .footer-info.style3 .footer-info__label a:hover {
  color: var(--theme-color2);
}

.footer-number {
  border-top: 1px solid #14284f;
  padding: 43px 0 0 0;
  margin: 34px 0 0 0;
  position: relative;
}
.footer-number:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -60px;
  width: 9999px;
  height: 1px;
  border: inherit;
}
.footer-number .info {
  color: var(--body-color);
  margin: 0;
  line-height: 1;
  display: inline-block;
}
.footer-number .info:hover {
  color: var(--theme-color);
}
.footer-number .title {
  margin: 0 0 7px 0;
}

.footer-map {
  max-width: 400px;
  width: 100%;
}
.footer-map frame {
  max-width: 100%;
}

.footer-social .social-title {
  font-family: var(--title-font);
  color: var(--title-color);
  font-size: 20px;
  display: inline-block;
  margin-right: 20px;
  font-weight: 700;
}
.footer-social a {
  display: inline-flex;
  align-items: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  margin: 0 5px 0 0;
  color: var(--black-color);
  background-color: var(--white-color);
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  border: 1px solid var(--vs-border-color);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
}
.footer-social a:last-child {
  margin-right: 0;
}
.footer-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border: 1px solid var(--theme-color);
}
.footer-social.style2, .footer-social.style3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.footer-social.style2 a, .footer-social.style3 a {
  background-color: transparent;
  outline-offset: -2px;
  color: var(--white-color);
  border: 1px solid var(--white-color);
  margin: 0 0 0 0;
}
.footer-social.style2 a:hover, .footer-social.style3 a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}
.footer-social.style3 {
  justify-content: flex-start;
}

.footer-newsletter {
  display: flex;
}
.footer-newsletter input {
  background-color: transparent;
  border-radius: 5px 0 0 5px;
  border: 1px solid #14284f;
  height: 55px;
  color: var(--white-color);
  flex: 1;
}
.footer-newsletter input::-moz-placeholder {
  color: var(--white-color);
}
.footer-newsletter input::-webkit-input-placeholder {
  color: var(--white-color);
}
.footer-newsletter input:-ms-input-placeholder {
  color: var(--white-color);
}
.footer-newsletter input::placeholder {
  color: var(--white-color);
}
.footer-newsletter .vs-btn {
  border-radius: 0 5px 5px 0;
}
.footer-newsletter .vs-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.footer-newsletter .vs-btn:after, .footer-newsletter .vs-btn::before {
  display: none;
}

.footer-schedule table {
  border: none;
  margin: -0.3em 0 0 0;
}
.footer-schedule th,
.footer-schedule td {
  padding: 0 0 10px 0;
  color: var(--body-color);
  border: none;
  font-weight: 400;
}

.footer-number1 {
  font-size: 30px;
  font-weight: 700;
  color: var(--body-color);
  font-family: var(--title-font);
  margin-bottom: 25px;
  display: block;
  width: max-content;
}

.footer-mail1,
.footer-address1 {
  padding-left: 25px;
  position: relative;
}
.footer-mail1 > i:first-child,
.footer-address1 > i:first-child {
  position: absolute;
  left: 0;
  top: 4px;
}

.footer-address1 {
  max-width: 250px;
}

.footer-mail1 {
  color: var(--body-color);
  text-decoration: underline;
}
.footer-mail1 i:first-child {
  top: 2px;
}

.footer-info-list {
  margin: 35px 0 22px 0;
}
.footer-info-list .info {
  position: relative;
  margin-bottom: 21px;
  padding-left: 30px;
  max-width: 250px;
}
.footer-info-list .info i:first-child {
  position: absolute;
  left: 0;
  top: 4px;
  color: var(--theme-color);
}
.newsletter-form2 .form_text {
  margin: -0.3em 0 22px 0;
  display: block;
  max-width: 300px;
}
.newsletter-form2 input {
  background-color: #ebecf1;
  margin-bottom: 15px;
  border-radius: 7px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .menu-all-pages-container.footer-links {
    width: 330px;
    max-width: 100%;
  }
  .footer-number:before {
    display: none;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .footer-widget {
    margin-bottom: 40px;
  }
  .footer-widget .widget_title {
    font-size: 26px;
  }
  .footer-number {
    padding: 27px 0 0 0;
    margin: 17px 0 0 0;
  }
  .footer-info_icon {
    margin: 0 0 20px 0;
  }
  .footer-info_group {
    padding: 0;
    border: none;
  }
}
@media (min-width: 991px) and (max-width: 1399px) {
  .footer-newsletter {
    display: block;
  }
  .footer-newsletter input {
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .footer-newsletter .vs-btn {
    border-radius: 5px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .footer-widget .widget_title {
    font-size: 22px;
  }
  .footer-layout1 .footer-widget.widget_meta a,
  .footer-layout1 .footer-widget.widget_pages a,
  .footer-layout1 .footer-widget.widget_archive a,
  .footer-layout1 .footer-widget.widget_categories a,
  .footer-layout1 .footer-widget.widget_nav_menu a {
    margin-left: auto;
    margin-right: auto;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .footer-widget.widget_meta a,
  .footer-widget.widget_pages a,
  .footer-widget.widget_archive a,
  .footer-widget.widget_categories a,
  .footer-widget.widget_nav_menu a {
    font-size: 14px;
  }
  .footer-newsletter {
    display: block;
  }
  .footer-newsletter input {
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .footer-newsletter .vs-btn {
    border-radius: 5px;
  }
}
.footer-menu--style > ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  text-align: left;
  column-count: 2;
}
.footer-menu--style > ul > li {
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .footer-menu--style > ul > li {
    margin-bottom: 10px;
  }
}
.footer-menu--style > ul > li > a {
  font-size: 16px;
  color: var(--black-color);
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-weight: 400;
}
@media (max-width: 1199px) {
  .footer-menu--style > ul > li > a {
    padding-left: 15px;
    font-size: 15px;
  }
}
.footer-menu--style > ul > li > a::before {
  position: absolute;
  content: "\f105";
  font-family: var(--icon-font);
  top: calc((100% - 32px) / 2);
  left: 0;
  line-height: 30px;
  color: var(--theme-color);
  font-size: 17px;
}
.footer-menu--style > ul > li > a:hover {
  color: var(--theme-color);
  transform: translateX(10px);
}
.footer-menu--style > ul > li > a:hover::before {
  border-color: transparent transparent transparent var(--theme-color);
}
.footer-menu--style.style2 > ul > li > a, .footer-menu--style.style3 > ul > li > a {
  color: var(--white-color);
}
.footer-menu--style.style2 > ul > li > a::before, .footer-menu--style.style3 > ul > li > a::before {
  color: var(--white-color);
}
.footer-menu--style.style2 > ul > li > a:hover, .footer-menu--style.style3 > ul > li > a:hover {
  color: var(--theme-color2);
}
.footer-menu--style.style2 > ul > li > a:hover::before, .footer-menu--style.style3 > ul > li > a:hover::before {
  color: var(--theme-color2);
}

.footer-widget.widget_nav_menu {
  margin-bottom: 0;
}
.footer-widget.widget_nav_menu .menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 15px;
}
@media (max-width: 767px) {
  .footer-widget.widget_nav_menu .menu {
    flex-direction: column;
    text-align: center;
  }
}
.footer-widget.widget_nav_menu .menu > li > a {
  margin-bottom: 0;
  background-color: transparent;
  color: var(--black-color);
  font-family: var(--title-font);
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid var(--vs-border-color);
}
.footer-widget.widget_nav_menu .menu > li > a:hover {
  color: var(--theme-color);
}
@media (max-width: 767px) {
  .footer-widget.widget_nav_menu .menu > li > a {
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
    display: block;
    width: 100%;
  }
}
.footer-widget.widget_nav_menu .menu > li:first-child > a {
  padding-left: 0;
  margin-left: 0;
  border-left: 0;
}
.footer-widget.widget_nav_menu.style2 .menu > li > a {
  color: var(--white-color);
  border-color: var(--white-color);
}
.footer-widget.widget_nav_menu.style2 .menu > li > a:hover {
  color: var(--theme-color2);
}
.footer-widget.widget_nav_menu.style3 .menu > li > a {
  border-color: var(--title-color);
  position: relative;
}
.footer-widget.widget_nav_menu.style3 .menu > li > a span {
  position: relative;
}
.footer-widget.widget_nav_menu.style3 .menu > li > a span::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--title-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.footer-widget.widget_nav_menu.style3 .menu > li > a:hover {
  color: var(--title-color);
}
.footer-widget.widget_nav_menu.style3 .menu > li > a:hover span::after {
  width: 100%;
  left: 0;
}

/*------------------- 4.2. Header  -------------------*/
.vs-header {
  position: relative;
  z-index: 41;
}

.header-logo {
  padding: 20px 0;
}
@media (max-width: 767px) {
  .header-logo {
    max-width: 150px;
  }
}

.will-sticky .sticky-active {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  transition: all ease 0.8s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
}
.will-sticky .sticky-active.active {
  top: 0;
}

.main-menu {
  text-align: center;
}
.main-menu a {
  display: block;
  position: relative;
  font-weight: 700;
  font-size: 18px;
  color: var(--title-color);
  /* Large devices */
}
@media (max-width: 1199px) {
  .main-menu a {
    font-size: 16px;
  }
}
.main-menu a:hover {
  color: var(--theme-color);
}
.main-menu > ul > li {
  margin: 0 18px;
}
@media (max-width: 1399px) {
  .main-menu > ul > li {
    margin: 0 10px;
  }
}
.main-menu > ul > li > a {
  padding: 37px 0;
}
.main-menu > ul > li > a .new-label {
  position: absolute;
  top: -10px;
  right: -27px;
  font-size: 11px;
  border-radius: 3px;
}
.main-menu > ul > li > a .has-new-lable {
  position: relative;
}
.main-menu ul {
  margin: 0;
  padding: 0;
}
.main-menu ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}
.main-menu ul li.menu-item-has-children > a:after {
  content: "\f078";
  position: relative;
  font-family: var(--icon-font);
  margin-left: 5px;
  font-size: 0.8rem;
}
.main-menu ul li:last-child {
  margin-right: 0;
}
.main-menu ul li:first-child {
  margin-left: 0;
}
.main-menu ul li:hover > ul.sub-menu,
.main-menu ul li:hover ul.mega-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 9;
}
.main-menu ul.sub-menu,
.main-menu ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  min-width: 190px;
  width: max-content;
  padding: 7px;
  left: -14px;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border-bottom: 3px solid var(--theme-color);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09), 0px 3px 0px 0px rgba(231, 13, 60, 0.004);
  transform-origin: top center;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
}
.main-menu ul.sub-menu a,
.main-menu ul.mega-menu a {
  font-size: 16px;
  line-height: 30px;
}
.main-menu ul.sub-menu {
  padding: 18px 20px;
  left: -27px;
}
.main-menu ul.sub-menu:before {
  content: "";
  position: absolute;
  left: 34.5px;
  top: 30px;
  width: 1px;
  background-color: #ededed;
  height: calc(100% - 60px);
  opacity: 0;
}
.main-menu ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 3px 9px;
}
.main-menu ul.sub-menu li.menu-item-has-children > a:after {
  content: "\f105";
  float: right;
  top: 3px;
}
.main-menu ul.sub-menu li a {
  position: relative;
  padding-left: 21px;
}
.main-menu ul.sub-menu li a:before {
  content: "\f111";
  position: absolute;
  top: 9px;
  left: 0;
  font-family: var(--icon-font);
  width: 11px;
  height: 11px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.2em;
  line-height: 11.5px;
  color: var(--theme-color);
  font-weight: 700;
  background-color: var(--white-color);
  box-shadow: inset 0px 2px 4px 0px rgba(173, 136, 88, 0.4);
}
.main-menu ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}
.main-menu ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}
.main-menu .mega-menu-wrap {
  position: static;
}
.main-menu ul.mega-menu {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  transform: translateX(-50%);
}
.main-menu ul.mega-menu .main-menu ul.mega-menu > li > ul > li > a {
  position: relative;
}
.main-menu ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0 15px;
}
.main-menu ul.mega-menu li li {
  padding: 4px 0;
}
.main-menu ul.mega-menu li a {
  display: inline-block;
}
.main-menu ul.mega-menu > li > a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after, .main-menu ul.mega-menu > li > a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}
.main-menu ul.mega-menu > li > a::after {
  width: calc(100% - 20px);
  left: 20px;
}
.main-menu ul.mega-menu > li > a:hover {
  padding-left: 0;
}
.main-menu ul.mega-menu > li > ul > li > a:before {
  content: "\f105";
  font-family: var(--icon-font);
  font-weight: 400;
  margin: 0 10px 0 0;
}

.menu-style2 a {
  color: var(--white-color);
  font-size: 18px;
  /* Large devices */
}
@media (max-width: 1199px) {
  .menu-style2 a {
    font-size: 16px;
  }
}
.menu-style2 ul.sub-menu a,
.menu-style2 ul.mega-menu a {
  color: var(--title-color);
}
.menu-style2 ul.sub-menu a:hover,
.menu-style2 ul.mega-menu a:hover {
  color: var(--theme-color);
}

.header-text {
  margin: 0;
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-weight: 600;
}
.header-text span {
  font-weight: 800;
  padding-left: 5px;
}
.header-text i {
  margin-right: 14px;
  font-size: 36px;
  display: inline-flex;
}
.header-text.style2 {
  color: var(--white-color);
  font-weight: 400;
}
.header-text.style2 i {
  color: var(--theme-color2);
}

.header-info {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  margin-left: 40px;
  padding-left: 40px;
  border-left: 1px solid #e7ebf3;
}
.header-info:nth-child(2) {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}
.header-info_icon {
  color: var(--theme-color);
  font-size: 24px;
  margin-right: 20px;
}
.header-info_label {
  font-size: 14px;
  color: var(--body-color);
  display: block;
  margin-bottom: 2px;
}
.header-info_link {
  color: var(--title-color);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.header-info_link a {
  color: inherit;
}
.header-info_link a:hover {
  color: var(--theme-color);
}

.header-search {
  display: inline-flex;
  max-width: 100%;
  align-items: flex-end;
  margin-right: 31px;
  transition: all 0.3s ease-in-out;
}
.header-search input {
  flex: 1;
  border: none;
  padding: 10px 0px 0 12px;
  height: 25px;
  width: 68px;
  border-radius: 0;
  transition: all ease 0.4s;
  font-size: 16px;
  font-weight: 500;
}
.header-search button {
  border: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-size: 22px;
}
.header-search.style2 input {
  background-color: transparent;
  color: var(--white-color);
}
.header-search.style2 input::placeholder {
  color: var(--white-color);
}
.header-search.style2 button {
  color: var(--white-color);
}

.header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-links li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--title-font);
  padding: 0 30px 0 0;
  margin: 0 26px 0 0;
  border-right: 1px solid #cad4f1;
  line-height: 23px;
}
.header-links li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.header-links i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}
.header-links a {
  color: inherit;
}
.header-links a:hover {
  color: var(--theme-color);
}
.header-links.style-white i,
.header-links.style-white li {
  color: var(--white-color);
  border-color: #558eff;
}

.header-social {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 16px;
}
@media (max-width: 991px) {
  .header-social {
    justify-content: center;
  }
}
.header-social a {
  display: inline-flex;
  color: var(--title-color);
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 50%;
}
.header-social a:hover {
  background-color: var(--title-color);
  color: var(--theme-color2);
}
.header-social.style2 a {
  background-color: rgba(255, 255, 255, 0.21);
  color: var(--white-color);
}
.header-social.style2 a:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.header-social.style-white .social-title,
.header-social.style-white a {
  color: var(--white-color);
}
.header-social.style-white a:hover {
  color: var(--title-color);
}

.header-call {
  display: flex;
  align-items: center;
  color: var(--text-color);
}
.header-call i {
  font-size: 36px;
  margin-right: 10px;
}
.header-call span {
  font-size: 16px;
  font-weight: 600;
}
.header-call a {
  color: var(--text-color);
  margin-left: 5px;
  font-weight: 700;
}
.header-call a:hover {
  color: var(--theme-color);
}
.header-call.style2 {
  color: var(--white-color);
}
.header-call.style2 a {
  color: var(--white-color);
  font-weight: 400;
}
.header-call.style2 a:hover {
  color: var(--theme-color2);
}
.header-call.style2 i {
  color: var(--theme-color2);
}

.header-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.header-btns button,
.header-btns a {
  position: relative;
  height: 56px;
  width: 56px;
  border: 1px solid var(--vs-border-color);
  font-size: 22px;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  color: var(--body-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .header-btns button,
  .header-btns a {
    width: 50px;
    height: 50px;
  }
}
.header-btns button:hover,
.header-btns a:hover {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
}
.header-btns.style2 button,
.header-btns.style2 a {
  color: var(--white-color);
}
.header-btns .sideCartToggler {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}
.header-btns .sideCartToggler span {
  position: absolute;
  top: -5px;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: var(--title-color);
  font-size: 12px;
  border-radius: 50%;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-family: var(--title-font);
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}
.header-btns .sideCartToggler:hover span {
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.header-btns .sideCartToggler.style2 span {
  background-color: var(--theme-color2);
}

.header-box {
  border: 10px solid var(--theme-color);
  background-color: var(--white-color);
  padding: 20px;
}
.header-box__icon {
  border-radius: 5px;
  margin: 0 15px 0 0;
  font-size: 18px;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
}
.header-box__title {
  display: inline-block;
  color: var(--title-color);
  font-weight: 600;
  font-family: var(--title-font);
}
.header-box__number {
  display: block;
  width: fit-content;
  font-family: var(--title-font);
  font-size: 22px;
  font-weight: 700;
  color: var(--theme-color);
  margin: 15px 0 0 0;
}

.header-dropdown > .dropdown-toggle {
  color: var(--title-color);
}
.header-dropdown > .dropdown-toggle i {
  margin: 0 10px 0 0;
}
.header-dropdown > .dropdown-toggle:after {
  content: "\f107";
  font-family: var(--icon-font);
  border: none;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 5px;
}
.header-dropdown > .dropdown-toggle:hover {
  color: var(--theme-color);
}
.header-dropdown ul.dropdown-menu {
  padding: 10px 15px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
  margin: 0;
  margin-left: -15px !important;
  margin-top: 15px !important;
  width: max-content;
  min-width: auto;
  position: relative;
  z-index: 1;
  border: none;
}
.header-dropdown ul.dropdown-menu li {
  margin-bottom: 10px;
}
.header-dropdown ul.dropdown-menu li:last-child {
  margin-bottom: 0;
}
.header-dropdown ul.dropdown-menu a {
  display: block;
  margin: 0 0;
  width: max-content;
  padding: 2px 10px;
  color: var(--title-color);
  font-weight: 400;
  font-size: 14px;
}
.header-dropdown ul.dropdown-menu a:hover {
  color: var(--theme-color);
}
.header-dropdown ul.dropdown-menu a:first-child {
  margin-top: 0;
  padding-top: 0;
}
.header-dropdown ul.dropdown-menu a:last-of-type, .header-dropdown ul.dropdown-menu a:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.header-dropdown ul.dropdown-menu:before {
  content: "";
  position: absolute;
  left: 47px;
  top: -7px;
  width: 14px;
  height: 14px;
  background-color: var(--white-color);
  z-index: -1;
  transform: rotate(45deg);
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
}
.header-dropdown.style-white > .dropdown-toggle {
  color: var(--white-color);
}
.header-dropdown.style-white > .dropdown-toggle:hover {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.7);
}

.login-tab {
  display: flex;
  border-radius: 9999px;
  position: relative;
  z-index: 1;
}
.login-tab:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  border: 2px solid var(--theme-color);
  z-index: -1;
}
.login-tab a {
  display: inline-block;
  color: var(--theme-color);
  background-color: transparent;
  font-family: var(--title-font);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding: 18.5px 37px;
  line-height: 1;
  border-radius: inherit;
}
.login-tab a:hover, .login-tab a.active {
  color: var(--white-color);
}
.login-tab .indicator {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  background-color: var(--theme-color);
  z-index: -1;
  transition: all ease 0.4s;
  border-radius: inherit;
}

.header-layout1 {
  background-color: var(--white-color);
}
.header-layout1 .header-top {
  background-color: var(--theme-color2);
  padding: 7px 0;
}

.header-layout1 .menu-top {
  border-bottom: 1px solid #e7ebf3;
}

.header-layout2 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.header-layout2 .header-top {
  padding: 10px 0;
}
.header-layout2 .sticky-wrapper {
  border-top: 1.5px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.15);
}
.header-layout2 .will-sticky .sticky-active {
  background-color: var(--bgSticky-color);
}

.header-layout3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header-top.style3 {
  padding: 10px 0;
}

.sticky-wrapper.style3 {
  background-color: var(--white-color);
  box-shadow: 0px 21px 25px 0px rgba(4, 4, 4, 0.05);
  border-radius: 50px;
  padding: 0 36px;
}
@media (max-width: 1399px) {
  .sticky-wrapper.style3 {
    padding: 0 10px;
  }
}
.sticky-wrapper.style3.will-sticky .sticky-active.active {
  padding: 0 30px;
}

/*------------------- 4.3. Footer  -------------------*/
.widget-area {
  padding: 253px 0 80px 0;
}
.widget-area.style2 {
  padding: 205px 0 80px 0;
}
@media (max-width: 991px) {
  .widget-area.style2 {
    padding: 150px 0 20px 0;
  }
}
.widget-area.style3 {
  padding: 120px 0 160px 0;
  background-color: var(--bg3-color);
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .widget-area.style3 {
    padding: 80px 0 110px 0;
  }
}
.widget-area.style3 .footer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 1199px) {
  .widget-area.style3 .footer-overlay {
    display: none;
  }
}

.copyright-wrap {
  padding: 37px 0;
}
.copyright-wrap.style2 {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.copyright-text {
  margin: 0;
}
.copyright-text a {
  font-weight: 700;
  color: var(--black-color);
  position: relative;
}
.copyright-text a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--title-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.copyright-text a:hover {
  color: var(--title-color);
}
.copyright-text a:hover::after {
  width: 100%;
  left: 0;
}
.copyright-text.style3 {
  color: var(--title-color);
}
.copyright-text.style3 a {
  color: var(--title-color);
  position: relative;
}
.copyright-text.style3 a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--title-color);
  bottom: -2px;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.copyright-text.style3 a:hover {
  color: var(--title-color);
}
.copyright-text.style3 a:hover::after {
  width: 100%;
  left: 0;
}
.copyright-text.style2 {
  color: var(--white-color);
}
.copyright-text.style2 a {
  color: var(--white-color);
}
.copyright-text.style2 a::after {
  background-color: var(--theme-color2);
}
.copyright-text.style2 a:hover {
  color: var(--theme-color2);
}

.footer-layout1 {
  background-color: var(--bg1-color);
  margin-top: -133px;
}

.footer-layout2 {
  background-color: var(--bg3-color);
  margin-top: -85px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.footer-layout2 .footer-ele1 {
  top: 0;
}

.footer-layout3 {
  background-color: var(--theme-color2);
}

/* Medium devices */
@media (max-width: 991px) {
  .footer-layout1 {
    text-align: center;
  }
  .footer-layout1 .footer-text {
    margin-left: auto;
    margin-right: auto;
  }
}
.footer-payment {
  text-align: center;
}

.footer-ele1 {
  position: absolute;
  top: 86px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

/*------------------- 4.4. Breadcrumb  -------------------*/
.breadcumb-menu {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.breadcumb-menu li {
  display: inline-block;
  list-style: none;
  position: relative;
  color: var(--theme-color2);
}
.breadcumb-menu li:after {
  content: "|";
  position: relative;
  margin: 0 8px 0 12px;
  color: var(--white-color);
}
.breadcumb-menu li:last-child:after {
  display: none;
}
.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  word-break: break-word;
  font-weight: 700;
  font-size: 18px;
  font-family: var(--body-font);
  text-transform: uppercase;
}
.breadcumb-menu a {
  color: var(--white-color);
}

.breadcumb-title {
  width: 100%;
  font-size: 70px;
  color: var(--white-color);
  line-height: 1.1;
  margin: -0.14em 0 15px 0;
}

.breadcumb-content {
  position: relative;
  z-index: 1;
}

.breadcumb-wrapper {
  background-color: var(--title-color);
  padding: 140px 0;
  /* Medium devices */
}
@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding: 120px 0;
  }
}
.breadcumb-wrapper .breadcumb-shape {
  display: none;
}
.breadcumb-wrapper .hero-leaf3 {
  display: none;
}

/* Medium devices */
@media (max-width: 991px) {
  .breadcumb-content {
    display: block;
  }
  .breadcumb-title {
    max-width: 100%;
  }
  .breadcumb-menu {
    margin-top: 15px;
  }
}
.breadcumb-img .img1 {
  border-radius: 30px;
}

.breadcumb-shape {
  position: absolute;
  min-width: 852px;
  height: 100%;
  right: 0;
  background-color: var(--theme-color2);
  top: 0;
  border-radius: 50px 0 0 50px;
  z-index: -1;
}
.breadcumb-shape.style2 {
  min-height: 626px;
  max-height: 626px;
}

/*------------------- 4.5. Pagination  -------------------*/
.post-pagination {
  padding: 40px 0;
}

.post-pagi-box {
  display: flex;
  align-items: center;
}
.post-pagi-box > a {
  color: var(--title-color);
  font-weight: 700;
  font-size: 20px;
  font-family: var(--title-font);
}
.post-pagi-box > a:hover {
  color: var(--theme-color);
}
.post-pagi-box img {
  width: 80px;
  margin-right: 25px;
}
.post-pagi-box.next {
  flex-direction: row-reverse;
}
.post-pagi-box.next img {
  margin-right: 0;
  margin-left: 25px;
}

.pagi-icon {
  color: var(--theme-color);
  font-size: 40px;
}
.pagi-icon:hover {
  color: var(--theme-color);
}

/* Small devices */
@media (max-width: 767px) {
  .vs-pagination span,
  .vs-pagination a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    padding: 0 10px;
  }
  .post-pagi-box > a {
    font-size: 12px;
  }
  .post-pagi-box img {
    width: 40px;
    margin-right: 15px;
  }
  .post-pagi-box.next img {
    margin-right: 0;
    margin-left: 15px;
  }
}
.vs-pagination {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
  background: var(--bg2-color);
  padding: 13px;
  border-radius: 30px;
}
.vs-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 0;
  background-color: var(--bg2-color);
  padding: 0px 20px;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .vs-pagination ul {
    padding: 0px 5px;
  }
}
.vs-pagination ul li a::before {
  display: none;
}
.vs-pagination ul li a:hover {
  color: var(--theme-color);
}
.vs-pagination span,
.vs-pagination a {
  position: relative;
  display: inline-block;
  text-align: center;
  border: none;
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  width: auto;
  height: auto;
  line-height: 1;
  z-index: 1;
  text-transform: capitalize;
  background-color: transparent;
  border-radius: 0;
  color: var(--text-color);
  padding: 0 0;
}
.vs-pagination span::before,
.vs-pagination a::before {
  content: "";
  position: absolute;
  left: 3px;
  right: 3px;
  bottom: 3px;
  top: 3px;
  background-color: var(--vs-secondary-color);
  border-radius: inherit;
  transition: all ease 0.4s;
  transform: scale(0.6);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.vs-pagination span.active, .vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--title-color);
  border-color: rgba(0, 0, 0, 0);
}
.vs-pagination span.active::before, .vs-pagination span:hover::before,
.vs-pagination a.active::before,
.vs-pagination a:hover::before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.vs-pagination li {
  display: inline-flex;
  list-style-type: none;
  align-items: center;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid var(--vs-border-color);
}
@media (max-width: 991px) {
  .vs-pagination li {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.vs-pagination li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}
.vs-pagination .pagi-btn {
  width: 31px;
  height: 31px;
  line-height: 1;
  padding: 0;
  background-color: var(--white-color);
  color: var(--title-color);
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
}
.vs-pagination .pagi-btn:hover {
  background-color: var(--theme-color);
}
.vs-pagination .pagi-btn::before {
  background-color: var(--theme-color);
}

.post-pagination {
  padding: 0px 0 40px;
  margin: 0 0 30px;
}

.post-pagi-box {
  display: flex;
  align-items: center;
  background-color: var(--smoke-color);
  padding: 20px;
  border-radius: 20px;
}
.post-pagi-box > a {
  color: var(--title-color);
  font-weight: 600;
  font-size: 16px;
  font-family: var(--title-font);
  flex: none;
}
.post-pagi-box > a:hover {
  color: var(--theme-color);
}
.post-pagi-box .post-pagi-name {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--title-font);
  display: inline-block;
  margin-bottom: 10px;
  color: var(--text-color);
}
.post-pagi-box .post-pagi-title {
  font-size: 18px;
  margin-bottom: 0;
}
.post-pagi-box .post-pagi-title a {
  color: var(--title-color);
}
.post-pagi-box .post-pagi-title a:hover {
  color: var(--theme-color);
}
.post-pagi-box img {
  width: 100px;
  margin-right: 15px;
  border-radius: 20px;
}
.post-pagi-box.next {
  flex-direction: row-reverse;
  text-align: right;
}
.post-pagi-box.next img {
  margin-right: 0;
  margin-left: 15px;
}

.pagi-icon {
  color: #f0f0f0;
  font-size: 40px;
}

/*------------------- 4.6. Blog  -------------------*/
blockquote {
  display: block;
  position: relative;
  overflow: hidden;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
  background-color: var(--quote-bg, #eff1f5);
  border-left: 4px solid var(--theme-color);
  padding: 43px 50px 42px 35px;
  margin: 35px 0;
}
blockquote p {
  font-family: inherit;
  color: inherit;
  z-index: 3;
  width: 100%;
  margin-bottom: 0 !important;
  line-height: 1.5;
  position: relative;
}
blockquote:before {
  content: "\f10e";
  font-family: var(--icon-font);
  position: absolute;
  right: 40px;
  bottom: 30px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;
  color: var(--theme-color);
  opacity: 0.3;
}
blockquote p {
  margin-bottom: 0;
}
blockquote p a {
  color: inherit;
}
blockquote cite {
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  line-height: 1;
  margin-top: 20px;
  font-style: normal;
}
blockquote cite:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 30px;
  height: 2px;
  border-top: 2px solid var(--theme-color);
}

blockquote.vs-quote {
  text-align: left;
  padding: 50px 50px;
  border-left: 0px solid var(--theme-color);
  border-radius: 30px;
  border: 1px solid var(--vs-border-color7);
  margin: 41px 0;
  overflow: visible;
}
blockquote.vs-quote p {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--title-font);
  padding-right: 5px;
}
blockquote.vs-quote::after {
  position: absolute;
  content: "";
  height: 160px;
  width: 4px;
  background-color: var(--theme-color);
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0 5px 5px 0;
}
blockquote.vs-quote:before {
  position: absolute;
  top: auto;
  right: 38px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: var(--theme-color);
  display: inline-block;
  opacity: 23%;
  bottom: 29px;
  font-size: 80px;
}
blockquote.vs-quote cite {
  padding: 0;
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
blockquote.vs-quote cite::before {
  position: static;
}
blockquote.vs-quote .quote-author {
  display: block;
  font-size: 14px;
  color: #74787c;
  font-family: var(--title-font);
  display: block;
  font-weight: 400;
}

.blog-meta span,
.blog-meta a {
  display: inline-block;
  margin-right: 16px;
  font-size: 16px;
  color: #8b919c;
}
.blog-meta span:last-child,
.blog-meta a:last-child {
  margin-right: 0;
}
.blog-meta span i,
.blog-meta a i {
  color: var(--theme-color);
}
.blog-meta a:hover {
  color: var(--theme-color);
}

.blog-category {
  margin-bottom: -10px;
}
.blog-category a {
  display: inline-block;
  color: var(--white-color);
  padding: 4.5px 24.5px;
  margin-right: 5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  background-color: var(--theme-color);
}
.blog-category a:hover {
  background-color: var(--white-color);
  color: var(--body-color);
  border-color: var(--theme-color);
}

.blog-title a {
  color: inherit;
}
.blog-title a:hover {
  color: var(--theme-color);
}

.vs-blog {
  margin-bottom: 40px;
  border: 1px solid var(--vs-border-color3);
  border-radius: 50px;
}
.vs-blog:last-child {
  margin-bottom: 30px;
}
.vs-blog .blog-date {
  width: 100px;
  height: 100px;
  background: var(--theme-color2);
  font-size: 40px;
  font-weight: 700;
  font-family: var(--body-font);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  flex-direction: column;
  border-radius: 30px;
  line-height: 1;
  margin: -75px 0 30px 45px;
}
.vs-blog .blog-date span {
  display: block;
  font-size: 13px;
  line-height: 1;
}
.vs-blog .blog-img {
  border-radius: 50px;
  overflow: hidden;
  margin: -1px;
}
.vs-blog .blog-img .img {
  border-radius: 50px;
}
.vs-blog .blog-img.blog-video {
  position: relative;
}
.vs-blog .blog-img.blog-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vs-blog .blog-title {
  margin: -0.14em 0 19px 0;
  max-width: 89%;
  line-height: 1.3;
  /* Large devices */
}
@media (max-width: 1199px) {
  .vs-blog .blog-title {
    max-width: 100%;
  }
}
.vs-blog .blog-title br {
  /* Large devices */
}
@media (max-width: 1199px) {
  .vs-blog .blog-title br {
    display: none;
  }
}
.vs-blog .blog-text {
  margin-bottom: 0;
}
.vs-blog .blog-meta {
  margin: 0 0 10px 0;
}
.vs-blog .blog-content {
  padding: 0 45px 55px 45px;
}
.vs-blog .blog-meta {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}
.vs-blog .blog-meta .blog-meta-icon {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
}
.vs-blog .blog-meta .blog-meta-icon img {
  width: 24px;
  height: 24px;
}
.vs-blog .blog-meta .blog-meta-icon:hover {
  color: var(--theme-color);
}
.vs-blog .blog-meta span,
.vs-blog .blog-meta a {
  color: var(--text-color);
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.vs-blog .blog-meta span span,
.vs-blog .blog-meta a span {
  text-decoration: none;
}
.vs-blog .blog-meta span:hover span,
.vs-blog .blog-meta a:hover span {
  color: var(--theme-color);
}
.vs-blog .blog-text + .blog-footer {
  padding-top: 30px;
}
.vs-blog .blog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 22px;
  border-top: 1px solid var(--vs-border-color7);
  margin-top: 70px;
  flex-wrap: wrap;
  gap: 15px;
}
.vs-blog .vs-btn {
  font-size: 18px;
  padding: 21px 45px;
}

.blog-style1 {
  border: 0;
}
.blog-style1 .blog-date {
  width: 100px;
  height: 100px;
  background: var(--theme-color2);
  font-size: 40px;
  font-weight: 700;
  font-family: var(--body-font);
  display: flex;
  margin-top: -50px;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  flex-direction: column;
  margin-left: 26px;
  border-radius: 30px;
  line-height: 1;
  margin-bottom: 26px;
}
.blog-style1 .blog-date span {
  display: block;
  font-size: 13px;
  line-height: 1;
}
.blog-style1 .blog-img {
  border-radius: 30px;
}
.blog-style1 .blog-img .img {
  border-radius: 30px;
}
.blog-style1 .blog-meta {
  margin: 0 0 10px 0;
}
.blog-style1 .blog-content {
  padding: 0 0px 0 0;
}
@media (max-width: 1199px) {
  .blog-style1 .blog-content {
    padding: 0 0 0 0;
  }
}
.blog-style1 .blog-title {
  margin-bottom: 0;
  line-height: 34px;
  max-width: 91%;
}
@media (max-width: 1399px) {
  .blog-style1 .blog-title {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (max-width: 1199px) {
  .blog-style1 .blog-title {
    font-size: 18px;
    line-height: 28px;
  }
}
.blog-style1 .blog-meta {
  display: flex;
  align-items: center;
  margin: 0 0 14px 0;
}
.blog-style1 .blog-meta .blog-meta-icon {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
}
.blog-style1 .blog-meta .blog-meta-icon img {
  width: 24px;
  height: 24px;
}
.blog-style1 .blog-meta span,
.blog-style1 .blog-meta a {
  color: var(--text-color);
  font-weight: 500;
}
.blog-style1 .blog-meta span span,
.blog-style1 .blog-meta a span {
  text-decoration: underline;
}

.blog-inner-title {
  font-size: 40px;
  margin-top: -0.22em;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 0px;
}
.blog-inner-title::after {
  position: absolute;
  content: "";
  width: 66px;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--theme-color);
}

.blog-single {
  position: relative;
  margin-bottom: 30px;
  border: 0;
}
.blog-single .blog-body {
  border: 1px solid var(--vs-border-color3);
  border-radius: 50px;
}
.blog-single p {
  font-weight: 500;
}
.blog-single .blog-img {
  border-radius: 30px;
  margin: 0;
}
.blog-single .blog-img > img {
  width: 100%;
}
.blog-single .blog-img.main-img {
  margin: -1px;
}
.blog-single .blog-meta {
  margin: -0.2em 0 10px 0;
}
.blog-single .blog-title {
  margin-bottom: 17px;
}
.blog-single .blog-title br {
  /* Large devices */
}
@media (max-width: 1199px) {
  .blog-single .blog-title br {
    display: none;
  }
}
.blog-single .link-btn {
  width: fit-content;
  display: block;
  margin-top: 26px;
}
.blog-single .blog-date {
  color: var(--body-color);
  margin-left: 35px;
  margin-bottom: 30px;
}
.blog-single .blog-date i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}
.blog-single .share-links-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--title-color);
  font-family: var(--title-font);
  margin: 0 15px 0 0;
  display: inline-block;
}
.blog-single .share-links {
  margin: 16px 0 0 0;
}
.blog-single .share-links .row {
  align-items: center;
  --bs-gutter-y: 15px;
}
.blog-single .share-links .tagcloud {
  display: inline-block;
}
.blog-single .share-links .tagcloud a {
  padding: 0;
  border-radius: 0;
  color: var(--body-color);
}
.blog-single .share-links .tagcloud a:not(:last-child):after {
  content: ",";
}
.blog-single .social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-block;
}
.blog-single .social-links li {
  display: inline-block;
  margin-right: 13px;
}
.blog-single .social-links li:last-child {
  margin-right: 0;
}
.blog-single .social-links a {
  line-height: 1;
  font-size: 16px;
  color: var(--body-color);
  text-align: center;
  display: inline-flex;
  align-items: center;
}
.blog-single .social-links a:hover {
  color: var(--white-color);
}
.blog-single .blog-content {
  padding: 0 40px 40px;
  position: relative;
  /* Large devices */
}
@media (max-width: 1199px) {
  .blog-single .blog-content {
    padding: 0 15px 15px;
  }
}
.blog-single .blog-audio {
  line-height: 1;
}
.blog-single .blog-audio,
.blog-single .blog-img {
  position: relative;
}
.blog-single .blog-audio img,
.blog-single .blog-audio > *,
.blog-single .blog-img img,
.blog-single .blog-img > * {
  border-radius: 5px 5px 0 0;
}
.blog-single .blog-img .slick-arrow {
  --pos-x: 30px;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 5px;
}
.blog-single .blog-img .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.blog-single .blog-img .play-btn {
  --icon-size: 80px;
  --icon-font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
}
.blog-single .post-admin {
  background-color: var(--banner-color2);
  border-radius: 30px;
  display: flex;
  gap: 26px;
  align-items: center;
  padding: 35px 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 50px;
  margin-top: 50px;
  /* Small devices */
}
@media (max-width: 767px) {
  .blog-single .post-admin {
    flex-direction: column;
    padding: 15px;
  }
}
.blog-single .post-admin::before {
  position: absolute;
  content: "";
  width: 140px;
  height: 100%;
  background-color: var(--theme-color2);
  z-index: -1;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 30px;
  /* Small devices */
}
@media (max-width: 767px) {
  .blog-single .post-admin::before {
    display: none;
  }
}
.blog-single .post-admin .post-img {
  flex: 0 150px;
  --image-size: 150px;
  width: var(--image-size);
  height: var(--image-size);
  max-width: var(--image-size);
  max-height: var(--image-size);
  min-width: var(--image-size);
  min-height: var(--image-size);
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--white-color);
  box-shadow: 0px 13px 9px 0px rgba(0, 0, 0, 0.15);
}
.blog-single .post-admin .post-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}
.blog-single .post-admin .post-header h4 {
  margin-bottom: 0;
}
.blog-single .post-admin .post-text {
  margin-bottom: 0;
  padding-right: 36px;
}
.blog-single .post-admin .social-links li {
  margin-right: 0;
}
.blog-single .post-admin .social-links a {
  --icon-size: 41px;
  color: var(--text-color);
  font-size: 13.41px;
  border: 1.5px solid var(--vs-border-color8);
  box-shadow: none;
}
.blog-single .post-admin .social-links a:hover {
  background-color: var(--theme-color2);
  border-color: var(--theme-color2);
  color: var(--title-color);
}
.blog-single:hover .blog-img .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.blog-author {
  background-color: var(--smoke-color);
  padding: 30px;
  display: flex;
  align-items: center;
  margin: var(--blog-space-y, 60px) 0;
}
.blog-author .media-img {
  width: 189px;
  margin: 0 30px 0 0;
}
.blog-author .author-name {
  font-size: 24px;
  font-weight: 700;
  margin: -0.3em 0 0px 0;
}
.blog-author .author-degi {
  color: var(--theme-color);
}
.blog-author .author-text {
  margin: 0 0 -0.1em 0;
}

.blog-details .blog-single {
  background: transparent;
  box-shadow: none;
  padding: 0;
}
.blog-details .blog-img {
  margin: 0 0 40px 0;
}
.blog-details .blog-img img {
  border-radius: 0;
}
.blog-details .blog-content {
  margin: 0;
}

.blog-tab-area {
  position: relative;
}
.blog-tab-area .vs-slider-tab {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 9;
}
@media (max-width: 1199px) {
  .blog-tab-area .vs-slider-tab {
    gap: 10px;
  }
}
@media (max-width: 991px) {
  .blog-tab-area .vs-slider-tab {
    position: static;
    justify-content: center;
  }
}
.blog-tab-area .vs-slider-tab .tab-btn {
  border: 3px solid var(--white-color);
  background: transparent;
  padding: 0;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1199px) {
  .blog-tab-area .vs-slider-tab .tab-btn {
    max-width: 130px;
  }
}
@media (max-width: 991px) {
  .blog-tab-area .vs-slider-tab .tab-btn {
    max-width: 100%;
  }
}
.blog-tab-area .vs-slider-tab .tab-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  opacity: 0.8;
  transform: scale(0) rotate(180deg);
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
.blog-tab-area .vs-slider-tab .tab-btn.active::before {
  visibility: visible;
  transform: scale(1) rotate(0);
  transition: all 0.3s ease-in-out;
}

.blog-style2 {
  border: 0;
}
.blog-style2 .blog-img img {
  border-radius: 30px;
}
.blog-style2 .blog-content {
  padding: 45px 68px 45px 50px;
  border: 2px solid var(--theme-color2);
  border-radius: 30px;
  margin-left: 90px;
  margin-top: -134px;
  background-color: var(--white-color);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
}
@media (max-width: 1399px) {
  .blog-style2 .blog-content {
    padding: 45px 30px 45px 45px;
    margin-left: 30px;
  }
}
@media (max-width: 1199px) {
  .blog-style2 .blog-content {
    padding: 15px;
  }
}
@media (max-width: 991px) {
  .blog-style2 .blog-content {
    margin-left: 0;
    margin-top: 0;
  }
}
.blog-style2 .blog-meta-list {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 767px) {
  .blog-style2 .blog-meta-list {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.blog-style2 .blog-date {
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  background: var(--theme-color2);
  padding: 10px 25px 6px;
  line-height: 1;
  align-items: center;
  border-radius: 999px;
  width: auto;
  height: auto;
  margin: 0;
}
.blog-style2 .blog-meta-comment {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--title-color);
  font-weight: 600;
}
.blog-style2 .blog-meta-comment i {
  color: var(--theme-color);
}
.blog-style2 .blog-title {
  font-size: 26px;
  line-height: 36px;
  max-width: 100%;
}
.blog-style2 .blog-text {
  margin-bottom: 0;
}

.blog-style4 {
  border: none;
}
.blog-style4 .blog-img {
  margin-bottom: 40px;
}
.blog-style4 .blog-body {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}
.blog-style4 .blog-content {
  padding: 0;
}
.blog-style4 .blog-date {
  margin-top: 0;
  margin-left: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  blockquote {
    font-size: 20px;
    padding: 23px 30px 26px 30px;
  }
  blockquote cite {
    font-size: 16px;
  }
  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 30px;
  }
  .blog-single .blog-title {
    font-size: 24px;
  }
  .blog-meta span,
  .blog-meta a {
    font-size: 14px;
  }
  .blog-style3 .blog-content {
    padding: 25px 25px 30px 25px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  blockquote.vs-quote {
    padding: 40px 15px;
  }
  blockquote.vs-quote p {
    line-height: 1.8;
    font-size: 16px;
  }
  blockquote.vs-quote cite {
    padding: 0;
    margin-top: 10px;
  }
  .blog-inner-title {
    font-size: 26px;
  }
  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 20px;
  }
  .blog-single .blog-title {
    font-size: 20px;
  }
  .blog-single .blog-img .play-btn {
    --icon-size: 60px;
    --icon-font-size: 20px;
  }
  .blog-author {
    display: block;
    padding: 30px 15px;
  }
  .blog-author .media-img {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .blog-author .media-img img {
    width: 100%;
  }
  .blog-author .author-name {
    font-size: 18px;
  }
  .blog-author .author-degi {
    margin-bottom: 10px;
  }
}
.wp-block-tag-cloud {
  display: flex;
  align-items: center;
  /* Medium devices */
}
@media (max-width: 991px) {
  .wp-block-tag-cloud {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.wp-block-tag-cloud .title {
  font-weight: 700;
  margin-right: 7px;
}
.wp-block-tag-cloud .tag-cloud-link {
  margin: 0 5px 0 0;
  padding: 0 0;
  background-color: transparent;
  color: var(--text-color);
  font-weight: 500;
}
.wp-block-tag-cloud .tag-cloud-link:hover {
  background-color: transparent;
  color: var(--theme-color);
}

.blog-social {
  display: flex;
  align-items: center;
  /* Medium devices */
}
@media (max-width: 991px) {
  .blog-social {
    flex-wrap: wrap;
  }
}
.blog-social .title {
  font-weight: 700;
  margin-right: 17px;
}
.blog-social a {
  width: 49px;
  height: 49px;
  min-width: 49px;
  min-height: 49px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg2-color);
  border: 1px solid var(--vs-border-color8);
  color: var(--text-color);
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
  /* Medium devices */
}
.blog-social a:last-child {
  margin-right: 0;
}
@media (max-width: 991px) {
  .blog-social a {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}
.blog-social a:hover {
  background-color: var(--theme-color2);
  border-color: var(--theme-color2);
  color: var(--title-color);
}

/*------------------- 4.7. Comments  -------------------*/
.comment-respond {
  margin: var(--blog-space-y, 60px) 0 30px 0;
  position: relative;
}
.comment-respond .form-title a#cancel-comment-reply-link {
  font-size: 0.7em;
  text-decoration: underline;
}
.comment-respond .custom-checkbox.notice {
  margin-top: 10px;
  margin-bottom: 25px;
}
.comment-respond .row {
  --bs-gutter-x: 20px;
}
.comment-respond .form-group:last-child {
  margin-top: 10px;
}
.comment-respond .form-control {
  background-color: var(--white-color);
  height: 60px;
  border: 1px solid var(--vs-border-color4);
  border-radius: 30px;
  padding: 15px 42px;
  color: var(--text-color);
  font-size: 16px;
}
.comment-respond .form-control::placeholder {
  color: var(--text-color);
}
.comment-respond textarea.form-control {
  padding: 25px 42px;
}
.comment-respond .form-group > i {
  top: 20.5px;
}
.comment-respond input[type=checkbox] ~ label:before {
  background-color: var(--white-color);
  border: 1px solid var(--theme-color);
  border-radius: 3px;
}
.comment-respond input[type=checkbox]:checked ~ label:before {
  background-color: var(--theme-color);
  border-color: transparent;
}
.comment-respond .form-text {
  margin-bottom: 25px;
}

.question-form .comment-respond,
.review-form .comment-respond {
  margin: 0 0 20px 0;
}
.question-form .form-control,
.review-form .form-control {
  background-color: transparent;
  border: 1px solid #e4e4e4;
}
.question-form .rating-select,
.review-form .rating-select {
  display: flex;
  align-items: center;
}
.question-form .rating-select label,
.review-form .rating-select label {
  font-weight: 500;
  color: var(--title-color);
}

.vs-comments-wrap {
  margin: var(--blog-space-y, 60px) 0 var(--blog-space-y, 60px) 0;
}
.vs-comments-wrap .description p:last-child {
  margin-bottom: 0;
}
.vs-comments-wrap .comment-respond {
  margin: 30px 0;
}
.vs-comments-wrap pre {
  background: #ededed;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.vs-comments-wrap blockquote {
  background-color: #eaf8f9;
}
.vs-comments-wrap li {
  margin: 0;
}
.vs-comments-wrap ul.comment-list {
  list-style: none;
  padding: 0;
}
.vs-comments-wrap ul.comment-list ul ul,
.vs-comments-wrap ul.comment-list ul ol,
.vs-comments-wrap ul.comment-list ol ul,
.vs-comments-wrap ul.comment-list ol ol {
  margin-bottom: 0;
}
.vs-comments-wrap .comment-top {
  display: flex;
  justify-content: space-between;
}
.vs-comments-wrap .text:last-of-type {
  margin-bottom: 0;
}
.vs-comments-wrap .children {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 70px;
}
.vs-comments-wrap .children .replay-btn {
  background-color: var(--theme-color);
}
.vs-comments-wrap .replay-btn {
  font-weight: 700;
  font-size: 13px;
  font-family: var(--body-font);
  position: absolute;
  right: 15px;
  top: 15px;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  background: var(--title-color);
  color: var(--white-color);
  padding: 10px 17px 7px;
  border-radius: 999px;
  line-height: 1;
  z-index: 1;
}
.vs-comments-wrap .replay-btn i {
  margin-right: 7px;
}
.vs-comments-wrap .replay-btn:hover {
  background-color: var(--theme-color2);
  color: var(--white-color);
}
.vs-comments-wrap .star-rating {
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
  top: 5px;
  right: 0;
  width: 80px;
}

.vs-post-comment {
  display: flex;
  position: relative;
  padding: 29px 40px;
  margin: 20px 0 20px 0;
  border: 1px solid var(--vs-border-color3);
  border-radius: 30px;
  align-items: center;
  z-index: 1;
}
@media (max-width: 767px) {
  .vs-post-comment {
    padding: 50px 15px 15px 15px;
    flex-direction: column;
    gap: 40px;
  }
}
.vs-post-comment::before {
  position: absolute;
  content: "";
  width: 110px;
  height: 100%;
  background-color: var(--bg2-color);
  z-index: -1;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 30px;
}
@media (max-width: 767px) {
  .vs-post-comment::before {
    display: none;
  }
}

.comment-avater {
  --image-size: 130px;
  width: var(--image-size);
  height: var(--image-size);
  margin-right: 28px;
  overflow: hidden;
  background-color: var(--white-color);
  border-radius: 50%;
  border: 3px solid var(--white-color);
  box-shadow: 0px 13px 9px 0px rgba(0, 0, 0, 0.15);
}
.comment-avater img {
  width: 100%;
}

.comment-content {
  flex: 1;
  position: relative;
}
.comment-content .name {
  margin-bottom: 7px;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  gap: 13px;
  font-family: var(--body-font);
}
.comment-content .text {
  margin-bottom: 0;
}
.comment-content .commented-on {
  font-size: 14px;
  display: block;
  margin-bottom: 4px;
  margin-top: 0;
  font-weight: 600;
  color: var(--text-color);
  text-transform: uppercase;
}
.comment-content .commented-on i {
  margin-right: 7px;
  font-size: 0.9rem;
}

.vs-comments-wrap.vs-comment-form {
  margin: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-comments-wrap .vs-post-comment {
    display: block;
  }
  .vs-comments-wrap .star-rating {
    position: relative;
    top: 0;
    right: 0;
  }
  .vs-comments-wrap .comment-top {
    display: block;
  }
  .vs-comments-wrap .comment-avater {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .vs-comments-wrap .children {
    margin-left: 40px;
  }
  .vs-comments-wrap .reply_and_edit {
    position: relative;
    top: 0;
    margin: 15px 0 0 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .vs-comments-wrap .children {
    margin-left: 20px;
  }
  .vs-comments-wrap .name {
    font-size: 18px;
  }
}
/*------------------- 4.8. Hero Area  -------------------*/
.hero-style1 {
  position: relative;
  padding: 70px 0;
}
.hero-style1 .slick-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
}
.hero-style1 .slick-dots li button {
  background-color: var(--title-color);
}
.hero-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--bg1-color);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.hero-text {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color);
  padding-right: 50px;
  /* Large devices */
}
@media (max-width: 1199px) {
  .hero-text {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .hero-text {
    font-size: 20px;
  }
}
.hero-title {
  font-weight: 800;
  line-height: 1.15;
  margin: -0.14em 0 25px 0;
}
.hero-subtitle {
  display: flex;
  align-items: center;
  font-family: var(--body-font);
  font-size: 22px;
  font-weight: 600;
  gap: 12px;
  margin-bottom: 45px;
}
.hero-img {
  text-align: center;
  position: relative;
  z-index: 1;
}
.hero-img .circle {
  border-radius: 50%;
  width: 542px;
  height: 542px;
  background-color: var(--white-color);
  display: inline-block;
  position: absolute;
  top: 37px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
@media (max-width: 767px) {
  .hero-img .circle {
    width: 300px;
    height: 300px;
  }
}
.hero-img .circle.yellow-bg {
  background-color: var(--theme-color2);
}
.hero-leaf {
  position: absolute;
  top: 0;
  left: -109px;
}
@media (max-width: 991px) {
  .hero-leaf {
    left: 0;
  }
}
.hero-leaf2, .hero-leaf3 {
  position: absolute;
}
@media (max-width: 767px) {
  .hero-leaf2, .hero-leaf3 {
    display: none;
  }
}
.hero-leaf2 {
  top: 48px;
  left: 0;
}
@media (max-width: 991px) {
  .hero-leaf2 {
    display: none;
  }
}
.hero-leaf3 {
  top: auto;
  bottom: 48px;
  left: 48px;
}
@media (max-width: 1499px) {
  .hero-leaf3 {
    display: none;
  }
}
.hero-certificate {
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 0;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color2);
  padding: 5px;
}
.hero-certificate > img {
  animation: spin 10s linear infinite;
}
.hero-certificate.white-bg {
  background-color: var(--white-color);
}

.hero-style2 .hero-content {
  padding: 315px 0 170px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .hero-style2 .hero-content {
    padding: 210px 0 80px;
  }
}
.hero-style2 .hero-content .hero-title {
  margin-bottom: 25px;
  font-size: 60px;
  /* Small devices */
}
@media (max-width: 767px) {
  .hero-style2 .hero-content .hero-title {
    font-size: 36px;
  }
}
.hero-style2 .hero-content .hero-text {
  margin-bottom: 30px;
  max-width: 80%;
  font-weight: 400;
}
@media (max-width: 767px) {
  .hero-style2 .hero-content .hero-text {
    max-width: 100%;
  }
}

.hero-carousel2 {
  position: relative;
}
.hero-carousel2 .hero-logo {
  position: absolute;
  right: 312px;
  top: 240px;
  /* Large devices */
}
@media (max-width: 1199px) {
  .hero-carousel2 .hero-logo {
    display: none;
  }
}
.hero-carousel2 .slick-dots {
  position: absolute;
  bottom: 40px;
  max-width: calc(var(--main-container) + var(--container-gutters));
  padding-left: calc(var(--container-gutters) / 2);
  padding-right: calc(var(--container-gutters) / 2);
  margin-right: auto;
  margin-left: auto;
  right: 0;
  left: 0;
  text-align: left;
}
.hero-carousel2 .slick-dots button {
  background-color: var(--dots-bg, #fff);
}
.hero-carousel2 .slick-dots .slick-active button {
  background-color: var(--white-color);
}
.hero-carousel2 .slick-dots .slick-active button::before {
  border-color: var(--white-color);
}
.hero-carousel2 .hero-leaf1,
.hero-carousel2 .hero-leaf2 {
  position: absolute;
}
.hero-carousel2 .hero-leaf1 {
  top: 216px;
  left: 227px;
}
.hero-carousel2 .hero-leaf2 {
  bottom: 160px;
  top: auto;
  left: 66px;
}

.hero-style3 {
  position: relative;
  padding: 225px 0 65px 0;
}
@media (max-width: 767px) {
  .hero-style3 {
    padding: 190px 0 65px 0;
  }
}
.hero-style3 .slick-dots {
  margin: 20px 0 0 0;
}
.hero-style3 .hero-shape {
  position: absolute;
  min-width: 45%;
  height: 100%;
  right: 0;
  background-color: var(--theme-color2);
  border-radius: 50px 0 0 50px;
  top: auto;
  bottom: 0;
}
@media (max-width: 991px) {
  .hero-style3 .hero-shape {
    display: none;
  }
}
.hero-style3 .hero-leaf3i3 {
  bottom: 97px;
  right: 97px;
}
.hero-style3 .hero-leaf3i1 {
  bottom: 232px;
  left: 39px;
  /* Extra large devices */
}
@media (max-width: 1500px) {
  .hero-style3 .hero-leaf3i1 {
    display: none;
  }
}
.hero-style3 .hero-leaf3i2 {
  left: auto;
  right: 86px;
  top: 236px;
  /* Large devices */
}
@media (max-width: 1199px) {
  .hero-style3 .hero-leaf3i2 {
    display: none;
  }
}

.hero-content.style3 {
  margin-bottom: 30px;
}
.hero-content.style3 .hero-title {
  font-size: 60px;
  line-height: 83px;
  margin-bottom: 25px;
  /* Extra large devices */
}
@media (max-width: 1500px) {
  .hero-content.style3 .hero-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .hero-content.style3 .hero-title {
    font-size: 30px;
    line-height: 40px;
  }
}
.hero-content.style3 .hero-text {
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
}

.hero-img.style3 {
  position: relative;
  margin-bottom: 40px;
  text-align: left;
}
.hero-img.style3 .img1 {
  border-radius: 50px;
}
.hero-img.style3 .img2 {
  position: absolute;
  bottom: -40px;
  background: white;
  right: 30px;
  border-radius: 50px;
  border: 2px solid var(--theme-color2);
}
@media (max-width: 767px) {
  .hero-img.style3 .img2 {
    position: static;
  }
}

.hero-leaf3i1,
.hero-leaf3i2,
.hero-leaf3i3 {
  position: absolute;
}

/*------------------- 4.9. Error  -------------------*/
.error-content {
  text-align: center;
}
.error-content .sec-title {
  font-size: 210px;
  font-weight: 800;
  line-height: 0.74;
  margin: 0 0 25px 0;
}
.error-content .sec-subtitle {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0;
  text-transform: capitalize;
  color: var(--title-color);
  margin-bottom: 30px;
}
.error-content .sec-subtitle span {
  color: var(--theme-color);
}
.error-content .sec-text {
  font-size: 18px;
}

.subscriber-form {
  position: relative;
  margin-bottom: 30px;
}
.subscriber-form .form-control {
  min-width: 584px;
  height: 68px;
  border-radius: 999px;
  border: 1px solid var(--vs-border-color);
}
.subscriber-form .vs-btn {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  background-color: var(--theme-color2);
  padding: 0;
}
.subscriber-form .vs-btn::after, .subscriber-form .vs-btn::before {
  background-color: var(--theme-color);
}
.subscriber-form .vs-btn > i {
  margin-left: 0;
  background-color: transparent;
  color: var(--title-color);
}

.error-img {
  position: relative;
  z-index: 1;
  left: -15%;
}
.error-img::before {
  border-radius: 50%;
  content: "";
  width: 542px;
  height: 542px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  z-index: -1;
}

/*------------------- 4.10. Feature  -------------------*/
.call-card {
  margin-bottom: calc(var(--section-title-space) - 20px);
  display: flex;
  align-items: center;
  gap: 24px;
  text-align: right;
}
.call-card__icon {
  --icon-size: 69px;
  width: var(--icon-size);
  height: var(--icon-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border-radius: 50%;
  border: 5px solid var(--theme-color-light);
}
.call-card__title {
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--theme-color);
}
.call-card__number {
  font-size: 24px;
  font-weight: 700;
  color: var(--black-color);
  position: relative;
}
.call-card__number::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--black-color);
  bottom: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.call-card__number:hover {
  color: var(--theme-color);
}
.call-card__number:hover::before {
  background-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}

.feature-item {
  margin-bottom: 30px;
}
.feature-item .feature-icon-bg {
  position: absolute;
  bottom: 2px;
  z-index: -1;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.feature-item .feature-header {
  position: relative;
  z-index: 1;
  margin-bottom: 22px;
  padding-top: 38px;
}
.feature-item .feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 89px;
  height: 89px;
  z-index: 1;
  position: relative;
  background-color: var(--white-color);
  border: 3px solid var(--theme-color);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.feature-item .feature-icon img {
  filter: none;
  transition: all 0.3s ease-in-out;
}
.feature-item .feature-title {
  font-size: 22px;
  margin-bottom: 15px;
}
.feature-item .feature-title a {
  color: var(--title-color);
}
.feature-item .feature-title a:hover {
  color: var(--theme-color);
}
.feature-item .feature-text {
  margin-bottom: 0;
  padding-right: 30px;
}
.feature-item:hover .feature-icon {
  background-color: var(--theme-color);
  border-color: var(--theme-color-light);
  transition: all 0.3s ease-in-out;
}
.feature-item:hover .feature-icon img {
  filter: brightness(0) invert(1);
  transition: all 0.3s ease-in-out;
}
.feature-item.style2 {
  padding: 76px 60px 55px;
  border: 2px solid var(--vs-border-color);
  border-radius: 30px;
  position: relative;
  background-color: var(--white-color);
  margin-bottom: 65px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .feature-item.style2 {
    margin-bottom: 40px;
  }
}
@media (max-width: 1399px) {
  .feature-item.style2 {
    padding: 76px 35px 55px;
  }
}
.feature-item.style2::before {
  position: absolute;
  content: "";
  width: 90%;
  border: 2px solid var(--vs-border-color);
  height: 100%;
  border-radius: 30px;
  z-index: -1;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}
.feature-item.style2 .feature-header {
  padding-top: 0;
}
.feature-item.style2 .feature-text {
  padding-right: 0;
}
.feature-item.style2:hover {
  border-color: var(--theme-color);
}
.feature-item.style2:hover::before {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}
.feature-item.style3 {
  display: flex;
  gap: 29px;
  max-width: 84%;
  margin-bottom: 52px;
}
@media (max-width: 1199px) {
  .feature-item.style3 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .feature-item.style3 {
    flex-direction: column;
  }
}
.feature-item.style3:last-child {
  margin-bottom: 0;
}
.feature-item.style3 .feature-icon {
  --icon-size: 140px;
  width: var(--icon-size);
  min-width: var(--icon-size);
  height: var(--icon-size);
  position: relative;
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
}
@media (max-width: 1199px) {
  .feature-item.style3 .feature-icon {
    --icon-size: 125px;
  }
}
@media (max-width: 991px) {
  .feature-item.style3 .feature-icon {
    --icon-size: 115px;
  }
}
.feature-item.style3 .feature-title {
  font-size: 26px;
}
.feature-item.style3 .feature-text {
  padding-right: 0;
  line-height: 30px;
}
.feature-item.style3 .feature-number {
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--theme-color2);
  --icon-size: 40px;
  min-width: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14.67px;
  font-weight: 800;
}
.feature-item.style3:hover .feature-icon {
  background-color: var(--white-color);
}
.feature-item.style3:hover .feature-icon img {
  filter: none;
}
.feature-item.style4 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 25px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .feature-item.style4 {
    gap: 10px;
  }
}
.feature-item.style4:last-child {
  margin-bottom: 0;
}
.feature-item.style4 .feature-icon {
  background-color: var(--theme-color);
  --icon-size: 100px;
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
}
@media (max-width: 991px) {
  .feature-item.style4 .feature-icon {
    --icon-size: 90px;
  }
}
.feature-item.style4 .feature-icon img,
.feature-item.style4 .feature-icon svg,
.feature-item.style4 .feature-icon i {
  filter: none;
}
@media (max-width: 991px) {
  .feature-item.style4 .feature-icon img,
  .feature-item.style4 .feature-icon svg,
  .feature-item.style4 .feature-icon i {
    max-width: 30px;
  }
}
.feature-item.style4 .feature-text {
  color: var(--text-color);
  line-height: 28px;
  padding-right: 33px;
}
@media (max-width: 1199px) {
  .feature-item.style4 .feature-text {
    padding-right: 0;
  }
}

.filter-item:nth-child(1), .filter-item:nth-child(3) {
  margin-top: 70px;
}
@media (max-width: 991px) {
  .filter-item:nth-child(1), .filter-item:nth-child(3) {
    margin-top: 0;
  }
}
.filter-item:nth-child(5) {
  margin-top: -70px;
}
@media (max-width: 991px) {
  .filter-item:nth-child(5) {
    margin-top: 0;
  }
}

.img-box2 {
  position: relative;
  z-index: 1;
  text-align: center;
  display: inline-block;
  margin-bottom: 30px;
}
.img-box2 .img-circle {
  bottom: auto;
  position: absolute;
  width: 537.24px;
  height: 536.08px;
  background-color: var(--theme-color2);
  border-radius: 50%;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
@media (max-width: 767px) {
  .img-box2 .img-circle {
    width: 240px;
    height: 240px;
  }
}

.keyf {
  background-color: rgba(31, 46, 33, 0.9);
  padding: 70px;
  border-radius: 50px;
  position: relative;
}
@media (max-width: 1199px) {
  .keyf {
    padding: 20px;
  }
}
@media (max-width: 991px) {
  .keyf {
    max-width: 520px;
  }
}
@media (max-width: 767px) {
  .keyf {
    max-width: 100%;
    border-radius: 0;
  }
}
.keyf-title {
  color: var(--white-color);
  margin: -0.14em 0 25px 0;
}
.keyf-text {
  color: var(--white-color);
  font-size: 18px;
  max-width: 91%;
  margin: 0 0 25px 0;
}
.keyf .keyf-element1 {
  position: absolute;
  right: 42px;
  bottom: 60px;
}

.list-style3 {
  margin-bottom: 40px;
}
.list-style3 ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-style3 ul li {
  font-size: 18px;
  color: var(--white-color);
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.list-style3 ul li:last-child {
  margin-bottom: 0;
}
.list-style3 ul li::before {
  position: absolute;
  content: "";
  min-width: 14px;
  width: 14px;
  height: 14px;
  background-color: var(--theme-color);
  left: 0;
  top: 4px;
  border-radius: 50%;
}

.img-box7 {
  position: relative;
  border: 3px dashed var(--theme-color2);
  padding: 10px;
  border-radius: 50%;
}
.img-box7 .img1 {
  border-radius: 50%;
  width: 100%;
}
@media (max-width: 1199px) {
  .img-box7 .img1 {
    max-width: 360px;
  }
}
@media (max-width: 991px) {
  .img-box7 .img1 {
    max-width: 100%;
  }
}
.img-box7 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-box7 .play-btn::before, .img-box7 .play-btn::after {
  border: 2px solid var(--white-color);
  background-color: transparent;
  border-radius: 50%;
  animation-name: ripple2;
  animation-duration: 3s;
}
.img-box7 .play-btn i {
  border-radius: 50%;
  --icon-size: 80px;
  --icon-font-size: 18px;
  background-color: var(--white-color);
  color: var(--title-color);
}
.img-box7 .img2,
.img-box7 .img3,
.img-box7 .img4 {
  position: absolute;
}
.img-box7 .img4 {
  left: -62px;
  bottom: -25px;
}
@media (max-width: 767px) {
  .img-box7 .img4 {
    max-width: 120px;
    left: 0;
    bottom: 0;
  }
}
.img-box7 .img3 {
  left: -50px;
  top: 169px;
}
.img-box7 .img2 {
  left: -100px;
  top: 0;
}

.feature-element1 {
  position: absolute;
  right: 0;
  top: 67px;
}
@media (max-width: 1199px) {
  .feature-element1 {
    display: none;
  }
}

.img-box8 {
  position: relative;
  z-index: 1;
  text-align: center;
}
.img-box8::before {
  --circle-size: 610px;
  min-width: var(--circle-size);
  min-height: var(--circle-size);
  width: var(--circle-size);
  height: var(--circle-size);
  background-color: var(--white-color);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
}
@media (max-width: 767px) {
  .img-box8::before {
    display: none;
  }
}

.feature-layout2 {
  padding-bottom: 65px;
  /* Medium devices */
}
@media (max-width: 991px) {
  .feature-layout2 {
    padding-bottom: 40px;
  }
}

/*------------------- 4.11. About  -------------------*/
.img-box1 {
  position: relative;
}
.img-box1 .img1 {
  text-align: center;
  display: inline-block;
  position: relative;
}
.img-box1 .img1::before {
  position: absolute;
  content: "";
  width: 291px;
  height: 178px;
  background-color: var(--theme-color2);
  bottom: 0;
  right: 0;
  border-radius: 35px 0 0 0;
}
.img-box1 .img1 .img {
  border-radius: 40px;
}
.img-box1 .video-thumb1 {
  margin-top: -166px;
  width: 410px;
  height: 291px;
  margin-left: auto;
  margin-right: -40px;
  position: relative;
}
@media (max-width: 991px) {
  .img-box1 .video-thumb1 {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .img-box1 .video-thumb1 {
    width: 100%;
    margin-top: 0;
  }
}
.img-box1 .video-thumb1 .img {
  border-radius: 30px;
}
.img-box1 .video-thumb1 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease;
}
.img-box1 .video-thumb1 .play-btn:hover::before, .img-box1 .video-thumb1 .play-btn:hover::after {
  border-color: var(--theme-color2);
}
.img-box1 .video-thumb1 .play-btn:hover > i {
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.img-box1 .img-card {
  position: absolute;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
  border: 1px solid var(--vs-border-color);
  display: flex;
  max-width: 316px;
  left: -5%;
  top: 70px;
}
@media (max-width: 1399px) {
  .img-box1 .img-card {
    top: auto;
    bottom: 0;
    max-width: 370px;
  }
}
@media (max-width: 1199px) {
  .img-box1 .img-card {
    left: 0;
  }
}
@media (max-width: 767px) {
  .img-box1 .img-card {
    position: static;
    max-width: 100%;
  }
}
.img-box1 .img-card .img-side {
  flex: none;
  margin-top: -29px;
}
.img-box1 .img-card .text {
  margin-bottom: 0;
  padding: 35px 42px 29px 27px;
}

.about-content1 .about-text {
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 35px;
  padding-right: 36px;
}
@media (max-width: 1399px) {
  .about-content1 .about-text {
    padding-right: 0;
  }
}
.about-content1 .about-body {
  padding-left: 85px;
}
@media (max-width: 991px) {
  .about-content1 .about-body {
    padding-left: 0;
  }
}

.list-style1 ul,
.list-style2 ul {
  padding-left: 0;
  margin-bottom: 0;
}
.list-style1 ul li,
.list-style2 ul li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 18px;
  gap: 16px;
  color: var(--text-color);
}
.list-style1 ul li:last-child,
.list-style2 ul li:last-child {
  margin-bottom: 0;
}
.list-style1 ul li i,
.list-style2 ul li i {
  width: 51px;
  height: 51px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color2);
  border-radius: 50%;
  border: 3px solid #f0bb10;
}

.about-text {
  line-height: 32px;
  padding-right: 21px;
}

.about-author {
  display: flex;
  gap: 22px;
}
.about-author .author-img {
  display: inline-flex;
  max-width: 81px;
  height: 81px;
  border-radius: 50%;
  overflow: hidden;
  padding: 6px;
  border: 1px solid var(--vs-border-color);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
}
.about-author .author-img img {
  border-radius: 50%;
}
.about-author .author-name {
  margin-bottom: 0;
  font-size: 20px;
}
.about-author .author-desig {
  display: block;
  margin-bottom: 20px;
}
.about-author .author-content {
  padding-top: 10px;
}

.img-box3 {
  padding-left: 80px;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: auto;
}
.img-box3::before {
  position: absolute;
  content: "";
  width: 412px;
  height: 374.98px;
  left: auto;
  right: -17px;
  background-color: var(--theme-color2);
  border-radius: 0 30px 0 0;
  z-index: -1;
  top: -17px;
  /* Large devices */
}
@media (max-width: 1199px) {
  .img-box3::before {
    display: none;
  }
}
@media (max-width: 1399px) {
  .img-box3 {
    padding-left: 0;
  }
}
.img-box3 .img-circle {
  position: absolute;
  width: 427px;
  height: 427px;
  background-color: var(--theme-color2);
  z-index: -1;
  right: -106px;
  top: -106px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .img-box3 .img-circle {
    display: none;
  }
}

.img-box3i {
  display: inline-block;
  position: relative;
  width: 456px;
  /* Medium devices */
}
@media (max-width: 991px) {
  .img-box3i {
    width: 100%;
  }
}
.img-box3i::before {
  position: absolute;
  content: "";
  width: 246px;
  height: 310px;
  background-color: var(--theme-color2);
  top: 50%;
  transform: translateY(-50%);
  left: -14px;
  z-index: -1;
  border-radius: 40px 0 0 40px;
  /* Large devices */
}
@media (max-width: 1199px) {
  .img-box3i::before {
    display: none;
  }
}

.about-content2 {
  padding-left: 94px;
}
@media (max-width: 1199px) {
  .about-content2 {
    padding-left: 0;
  }
}

.simple-link {
  font-weight: 700;
  color: var(--black-color);
  margin-right: 25px;
}
.simple-link:hover {
  color: var(--theme-color);
}

.img-box5 {
  display: inline-block;
  position: relative;
}
.img-box5 .img1,
.img-box5 .img2,
.img-box5 .img3 {
  display: inline-block;
}
.img-box5 .img1 .img,
.img-box5 .img2 .img,
.img-box5 .img3 .img {
  border-radius: 30px;
}
.img-box5 .img2 {
  margin-top: -72px;
  padding-left: 238px;
}
@media (max-width: 767px) {
  .img-box5 .img2 {
    margin-top: 0;
    padding-left: 0;
  }
}
.img-box5 .img3 {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.item-card {
  padding-right: 60px;
}
@media (max-width: 1199px) {
  .item-card {
    padding-right: 0;
  }
}
.item-card .item-icon {
  border: 2px dotted var(--theme-color);
  display: inline-flex;
  --icon-size: 90px;
  min-width: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  margin-bottom: 20px;
}
.item-card .item-icon::after {
  position: absolute;
  content: "";
  --icon-size: 78.75px;
  min-width: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--theme-color);
  border-radius: 50%;
  z-index: -1;
}
.item-card .item-title {
  font-size: 22px;
  margin-bottom: 10px;
}
.item-card .item-text {
  margin-bottom: 0;
}

.history-box1 {
  margin-bottom: 60px;
}
.history-box1 .img1 {
  border-radius: 50px;
}

.history-content1 .history-text {
  font-size: 18px;
  padding-right: 31px;
  margin-bottom: 35px;
}

.history-video1 {
  position: relative;
}
.history-video1 .img1 {
  border-radius: 30px;
  box-shadow: 0px 4px 68px 0px rgba(0, 0, 0, 0.17);
}
.history-video1 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.history-item {
  padding-right: 11%;
}
@media (max-width: 991px) {
  .history-item {
    padding-right: 0;
  }
}
.history-item .history-title {
  margin: -0.14em 0 25px 0;
}
.history-item .history-text {
  font-size: 18px;
  margin-bottom: 0;
}
.history-item .list-style3 ul li {
  color: var(--title-color);
}

.history-year {
  --icon-size: 154px;
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  background: var(--theme-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 800;
  font-family: var(--title-font);
  border-radius: 50%;
  color: var(--white-color);
  position: relative;
}
.history-year::before {
  position: absolute;
  content: "";
  --icon-size: 170px;
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  border: 3px solid var(--theme-color2);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  z-index: -1;
}
.history-year .timeline-line {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background-color: var(--theme-color2);
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  z-index: -2;
}
@media (max-width: 1399px) {
  .history-year .timeline-line {
    height: 40%;
  }
}

.history-img {
  border-radius: 30px;
}
.history-img img {
  border-radius: 30px;
}

.history-item,
.history-year,
.history-img {
  margin-bottom: 50px;
}

.history-col:nth-last-child(2) .timeline-line {
  display: none;
}
@media (max-width: 991px) {
  .history-col:nth-last-child(2) .timeline-line {
    display: block;
  }
}

.history-layout {
  padding: 120px 0 70px 0;
}

.about-ele1 {
  position: absolute;
  right: 153px;
  bottom: 68px;
}

.about-layout2 .ele1 {
  position: absolute;
  bottom: 85px;
  right: 130px;
  /* Large devices */
}
@media (max-width: 1199px) {
  .about-layout2 .ele1 {
    display: none;
  }
}

/*------------------- 4.12. Service  -------------------*/
.service-layout2 {
  padding-bottom: 65px;
}

.service-single .blog-body {
  border: 0;
}
.service-single .blog-img.main-img {
  margin: 0 0 40px 0;
}
.service-single .blog-content {
  padding: 0;
}
.service-single .subtitle {
  font-size: 30px;
}
.service-single blockquote.vs-quote {
  padding: 50px 45px;
}

/*------------------- 4.13. Counter  -------------------*/
.offer-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  background: var(--white-color);
  margin-bottom: 36px;
  min-height: 110px;
  align-items: center;
  flex-wrap: wrap;
}
.offer-counter > li {
  line-height: 1;
  padding: 16px 30px 16px 30px;
  position: relative;
}
.offer-counter > li::before {
  content: "";
  position: absolute;
  height: 37px;
  width: 1px;
  background: var(--grey-color);
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.49px;
}
@media (max-width: 767px) {
  .offer-counter > li::before {
    display: none;
  }
}
.offer-counter > li:first-child {
  padding-left: 0;
}
.offer-counter > li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.offer-counter > li:last-child::before {
  display: none;
}
@media (max-width: 767px) {
  .offer-counter > li {
    padding-right: 0;
    padding-left: 0;
  }
}
.offer-counter .count-number {
  font-size: 40px;
  font-weight: 800;
  color: var(--theme-color);
  font-family: var(--title-font);
  margin-bottom: 5px;
}
.offer-counter .count-name {
  font-size: 18px;
  color: var(--black-color);
  font-family: var(--title-font);
  font-weight: 800;
  text-transform: lowercase;
  display: inline-block;
}

.counter-style {
  border: 2px solid var(--theme-color2);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1399px) {
  .counter-style {
    flex-wrap: wrap;
  }
}
.counter-style .offer-counter {
  background-color: transparent;
  margin-bottom: 0;
}
.counter-style .offer-counter > li {
  padding: 30px 22px 20px 22px;
}
@media (max-width: 1399px) {
  .counter-style .offer-counter > li {
    padding: 30px 15px 20px 15px;
  }
}
@media (max-width: 767px) {
  .counter-style .offer-counter > li {
    padding: 7px;
  }
}
.counter-style .offer-counter > li::before {
  background-color: var(--vs-border-color2);
}
.counter-style .offer-counter .count-number {
  font-size: 24px;
}
.counter-style .offer-counter .count-number,
.counter-style .offer-counter .count-name {
  color: var(--white-color);
  font-family: var(--body-font);
}
.counter-style .offer-counter .count-name {
  font-size: 14px;
  font-weight: 400;
}

.counter-wrap1 {
  padding-top: 100px;
}

.counter-media {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  align-items: center;
}
.counter-media__icon {
  min-width: 90px;
  height: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--theme-color);
  border-radius: 50%;
  background-color: var(--white-color);
}
.counter-media__title {
  font-size: 60px;
  margin: -0.25em 0 0px 0;
}
.counter-media__text {
  margin-bottom: 0;
  font-weight: 500;
}

.element1,
.element2,
.element3 {
  position: absolute;
}

.element3 {
  right: 0;
  bottom: 153px;
}

.element2 {
  bottom: 248px;
  left: 85px;
}

.element1 {
  left: -20px;
  bottom: 378px;
}

/*------------------- 4.14. Team  -------------------*/
.team-style1 {
  text-align: center;
  border: 2px solid var(--theme-color2);
  border-radius: 30px;
  padding: 35px 30px 50px 30px;
  position: relative;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
}
@media (max-width: 1399px) {
  .team-style1 {
    padding: 30px 8px 30px 8px;
  }
}
.team-style1 .team-shape {
  position: absolute;
  top: -3px;
  height: 155px;
  width: 102%;
  background-color: var(--theme-color2);
  left: -3px;
  border-radius: 30px;
  z-index: 1;
  right: -3px;
}
.team-style1 .team-name {
  font-size: 24px;
  margin: -0.18em 0 10px 0;
  transition: all 0.3s ease-in-out;
}
.team-style1 .team-desig {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color);
  margin-bottom: 25px;
  transition: all 0.3s ease-in-out;
}
.team-style1 .team-social {
  transition: all 0.3s ease-in-out;
}
.team-style1 .team-img {
  width: 238px;
  height: 238px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px dashed var(--theme-color2);
  padding: 4px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}
.team-style1 .team-img > img {
  border-radius: 50%;
}
.team-style1:hover {
  background-color: var(--bg3-color);
  border-color: var(--bg3-color);
}
.team-style1:hover .team-name,
.team-style1:hover .team-desig {
  color: var(--white-color);
}
.team-style1:hover .team-social {
  margin-top: 0px;
  opacity: 1;
  visibility: visible;
}

/*------------------- 4.15. Process  -------------------*/
/*------------------- 4.16. Accordion  -------------------*/
.img-box4 {
  position: relative;
}
.img-box4 .img1 img,
.img-box4 .img2 img {
  border-radius: 30px;
}
.img-box4 .img1 {
  padding-left: 149px;
}
@media (max-width: 767px) {
  .img-box4 .img1 {
    padding-left: 0;
  }
}
.img-box4 .img2 {
  margin-top: -75px;
}
@media (max-width: 767px) {
  .img-box4 .img2 {
    margin-top: 0;
  }
}
.img-box4 .sticker-logo {
  position: absolute;
  bottom: 65px;
  right: 150px;
}

.accordion-style1 .accordion-item,
.accordion-style2 .accordion-item {
  margin: 0 0 20px 0;
  background: transparent;
  border-radius: 10px;
}
.accordion-style1 .accordion-item:last-child,
.accordion-style2 .accordion-item:last-child {
  margin-bottom: 0;
}
.accordion-style1 .accordion-item:first-of-type .accordion-button,
.accordion-style2 .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.accordion-style1 .accordion-item:last-of-type .accordion-button.collapsed, .accordion-style1 .accordion-item:last-of-type .accordion-collapse,
.accordion-style2 .accordion-item:last-of-type .accordion-button.collapsed,
.accordion-style2 .accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.accordion-style1 .accordion-header,
.accordion-style2 .accordion-header {
  margin: 0;
}
.accordion-style1 .accordion-button,
.accordion-style2 .accordion-button {
  background-color: transparent;
  border: none;
  padding: 20px 15px 20px 0;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: left;
  border-top: 1px solid var(--vs-border-color);
  border-bottom: 1px solid var(--vs-border-color);
  gap: 15px;
  border-radius: 0;
}
.accordion-style1 .accordion-button.collapsed::before,
.accordion-style2 .accordion-button.collapsed::before {
  content: "\f067";
}
.accordion-style1 .accordion-button::after,
.accordion-style2 .accordion-button::after {
  display: none;
}
.accordion-style1 .accordion-button:before,
.accordion-style2 .accordion-button:before {
  content: "\f068";
  font-family: var(--icon-font);
  font-weight: 600;
  color: var(--title-color);
  border: none;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  background-image: none;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  color: var(--white-color);
  font-size: 11px;
}
.accordion-style1 .accordion-button:hover,
.accordion-style2 .accordion-button:hover {
  color: var(--theme-color);
}
.accordion-style1 .accordion-button:hover::before,
.accordion-style2 .accordion-button:hover::before {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.accordion-style1 .accordion-button:focus,
.accordion-style2 .accordion-button:focus {
  box-shadow: none;
}
.accordion-style1 .accordion-button:not(.collapsed),
.accordion-style2 .accordion-button:not(.collapsed) {
  color: var(--theme-color);
}
.accordion-style1 .accordion-button:not(.collapsed):after,
.accordion-style2 .accordion-button:not(.collapsed):after {
  color: var(--theme-color);
  content: "\f070";
}
.accordion-style1 .accordion-body,
.accordion-style2 .accordion-body {
  padding: 17px 11px 0px 51px;
}
@media (max-width: 767px) {
  .accordion-style1 .accordion-body,
  .accordion-style2 .accordion-body {
    padding: 15px;
  }
}
.accordion-style1 .accordion-body p:last-child,
.accordion-style2 .accordion-body p:last-child {
  margin-bottom: 0;
}
.accordion-style1 .accordion-collapse,
.accordion-style2 .accordion-collapse {
  border: none;
}
.accordion-style1 .vs-product,
.accordion-style2 .vs-product {
  padding: 5px;
  background-color: var(--bg2-color);
  border-color: var(--bg2-color);
}

.accordion-style2 .accordion-item:first-of-type .accordion-button {
  border-radius: 40px;
}
.accordion-style2 .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 40px;
}
.accordion-style2 .accordion-item .accordion-button {
  border-radius: 40px;
  background-color: var(--theme-color2);
  border-color: var(--theme-color2);
  color: var(--title-color);
  padding: 20px 15px 20px 30px;
}
.accordion-style2 .accordion-item .accordion-button.collapsed {
  background-color: var(--bg2-color);
  border-color: var(--bg2-color);
}
.accordion-style2 .accordion-body {
  padding: 30px 70px 0 80px;
}
.accordion-style2 .accordion-body .list-style4 {
  margin-bottom: 0;
}
.accordion-style2 .accordion-body .list-style4 ul li {
  font-size: 16px;
}

/*------------------- 4.17. Simple Sections  -------------------*/
.review-wrap {
  background-color: var(--theme-color2);
  border-radius: 50px;
  padding: 47px;
}
@media (max-width: 991px) {
  .review-wrap {
    padding: 15px;
  }
}
.review-title {
  font-family: var(--body-font);
  margin: 0 0 15px 0;
}
.review-content {
  display: flex;
  align-items: center;
  gap: 27px;
  max-width: 95%;
}
@media (max-width: 991px) {
  .review-content {
    gap: 27px;
    max-width: 100%;
    flex-direction: column;
    text-align: center;
  }
}
.review-content__left {
  flex: none;
}
.review-text {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 0;
  line-height: 32px;
}
@media (max-width: 767px) {
  .review-text {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;
  }
}
.review-subtitle {
  font-size: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}
.review-star {
  margin-bottom: 13px;
}
.review-subtitle2 {
  font-size: 14px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .review-subtitle2 {
    margin-bottom: 10px;
  }
}

.review-wrap.style2 {
  display: inline-flex;
  align-items: center;
  gap: 23px;
  min-width: 590px;
}
@media (max-width: 991px) {
  .review-wrap.style2 {
    min-width: 100%;
  }
}
.review-wrap.style2 .review-title {
  font-family: var(--body-font);
  margin: 0 0 15px 0;
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .review-wrap.style2 .review-title {
    font-size: 20px;
  }
}
.review-wrap.style2 .review-subtitle2 {
  margin-bottom: 0;
}

.shape-1 {
  position: absolute;
  width: 100%;
  bottom: -75.53px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .shape-1 {
    bottom: -68.53px;
  }
}
@media (max-width: 991px) {
  .shape-1 {
    bottom: -30px;
  }
}
@media (max-width: 767px) {
  .shape-1 {
    bottom: 0px;
  }
}

.instagram-feed {
  position: relative;
  z-index: 1;
}
.instagram-feed::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.instagram-feed .instagram-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0) rotate(180deg);
  z-index: 1;
  color: var(--white-color);
  font-size: 40px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.instagram-feed > img {
  width: 100%;
}
.instagram-feed:hover::before {
  opacity: 0.9;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.instagram-feed:hover .instagram-icon {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1) rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.instagram-feed:hover .instagram-icon:hover {
  color: var(--title-color);
}

.img-box6 {
  position: relative;
  border: dashed 3px var(--theme-color2);
  border-radius: 50%;
  padding: 10px;
}
@media (max-width: 1199px) {
  .img-box6 {
    max-width: 425px;
  }
}
@media (max-width: 991px) {
  .img-box6 {
    max-width: 100%;
  }
}
.img-box6 > img, .img-box6 > .img1 {
  border-radius: 50%;
}
.img-box6 > .img1 {
  width: 100%;
}
@media (max-width: 1199px) {
  .img-box6 > .img1 {
    max-width: 425px;
  }
}
@media (max-width: 991px) {
  .img-box6 > .img1 {
    max-width: 100%;
  }
}
.img-box6 .img-badge {
  --badge-size: 182px;
  width: var(--badge-size);
  height: var(--badge-size);
  min-width: var(--badge-size);
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--theme-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .img-box6 .img-badge {
    --badge-size: 135px;
  }
}
.img-box6 .img-badge span {
  display: block;
  font-weight: 700;
  line-height: 1;
  font-family: var(--title-font);
}
.img-box6 .img-badge .badge-percentage {
  font-size: 44px;
}
@media (max-width: 767px) {
  .img-box6 .img-badge .badge-percentage {
    font-size: 30px;
  }
}
.img-box6 .img-badge .badge-text {
  text-transform: uppercase;
  font-size: 18px;
}
@media (max-width: 767px) {
  .img-box6 .img-badge .badge-text {
    font-size: 16px;
  }
}

.ref-form {
  background-color: var(--white-color);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
  border: 2px solid var(--theme-color2);
  padding: 40px;
  border-radius: 30px;
}
@media (max-width: 767px) {
  .ref-form {
    font-size: 18px;
  }
}
.ref-form .form-title {
  font-size: 16px;
  margin-bottom: 10px;
}
.ref-form .form-control {
  border-radius: 27px;
  background-color: var(--smoke-color);
  height: 54px;
  margin-bottom: 15px;
}
.ref-form .form-control:focus {
  border-color: var(--theme-color2);
}

.ref-text {
  font-size: 18px;
  padding-right: 20px;
}
.ref-text a {
  font-weight: 700;
  color: var(--title-color);
}

.insta-layout3 {
  margin-top: -80px;
  position: relative;
  z-index: 2;
}

.list-style4 {
  margin-bottom: 40px;
}
.list-style4 ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.list-style4 ul li {
  font-size: 18px;
  color: var(--text-color);
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.list-style4 ul li:last-child {
  margin-bottom: 0;
}
.list-style4 ul li::before {
  position: absolute;
  content: "";
  min-width: 14px;
  width: 14px;
  height: 14px;
  background-color: var(--theme-color);
  left: 0;
  top: 4px;
  border-radius: 50%;
}

.gallery-style {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}
.gallery-style::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.gallery-style img {
  width: 100%;
}
.gallery-style .gallery-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0) rotate(180deg);
  z-index: 1;
  color: var(--white-color);
  font-size: 40px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.gallery-style:hover::before {
  opacity: 0.9;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.gallery-style:hover .gallery-icon {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1) rotate(0deg);
}
.gallery-style:hover .gallery-icon:hover {
  color: var(--title-color);
}

.video-gallery {
  margin-bottom: 30px;
}
.video-gallery .video-thumb {
  position: relative;
  margin-bottom: 30px;
}
.video-gallery .video-thumb .img {
  border-radius: 30px;
}
.video-gallery .video-thumb .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.video-gallery .video-title {
  font-size: 26px;
  line-height: 36px;
}
@media (max-width: 991px) {
  .video-gallery .video-title {
    font-size: 22px;
    line-height: 32px;
  }
}
.video-gallery .video-title a {
  color: var(--title-color);
}
.video-gallery .video-title a:hover {
  color: var(--theme-color);
}
.video-gallery .video-auth {
  color: var(--text-color);
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  margin-right: 16px;
  font-size: 16px;
}
.video-gallery .video-auth span {
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
.video-gallery .video-auth span:hover {
  color: var(--theme-color);
}

.insta-layout1 {
  position: relative;
  z-index: 9;
}

/*------------------- 4.18. Testimonial  -------------------*/
.testi-style1 {
  padding: 50px 50px 45px 50px;
  border: 2px solid var(--vs-border-color3);
  border-radius: 30px;
  position: relative;
  margin-bottom: 13px;
  background-color: var(--white-color);
}
@media (max-width: 1199px) {
  .testi-style1 {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .testi-style1 {
    text-align: center;
  }
}
.testi-style1::before {
  position: absolute;
  content: "";
  width: 560px;
  height: 328px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -15px;
  border: 2px solid var(--vs-border-color3);
  border-radius: 30px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1399px) {
  .testi-style1::before {
    width: 510px;
  }
}
@media (max-width: 1199px) {
  .testi-style1::before {
    width: 400px;
  }
}
@media (max-width: 767px) {
  .testi-style1::before {
    width: 250px;
  }
}
.testi-style1 .star-rating {
  width: 114px;
  font-size: 15px;
  margin-bottom: 10px;
}
.testi-style1 .star-rating::before {
  letter-spacing: 7px;
}
.testi-style1 .star-rating span::before {
  letter-spacing: 7px;
}
.testi-style1 .testi-author {
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 5px;
}
.testi-style1 .testi-author a {
  font-weight: 700;
  color: var(--text-color);
}
.testi-style1 .testi-image {
  min-width: 140px;
  max-height: 140px;
  display: inline-flex;
  position: relative;
}
@media (max-width: 1199px) {
  .testi-style1 .testi-image {
    min-width: 120px;
    max-height: 120px;
  }
}
.testi-style1 .testi-image .img1 {
  min-width: 140px;
  max-height: 140px;
  border-radius: 50%;
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
}
@media (max-width: 1199px) {
  .testi-style1 .testi-image .img1 {
    min-width: 120px;
    max-height: 120px;
  }
}
.testi-style1 .testi-icon {
  position: absolute;
  top: 0;
  right: -8px;
  width: 54px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border-radius: 50%;
}
.testi-style1 .testi-content {
  display: flex;
  gap: 40px;
}
@media (max-width: 767px) {
  .testi-style1 .testi-content {
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}
.testi-style1 .testi-text {
  margin-bottom: 0;
  line-height: 30px;
}
.testi-style1:hover::before {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.testimonials-layout2 {
  background-color: var(--bg2-color);
}
.testimonials-layout2 .slick-dots {
  margin: 75px 0 24px 0;
}

.testi-style2 {
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 50px 50px 0 50px;
  margin-bottom: 33px;
}
@media (max-width: 1199px) {
  .testi-style2 {
    padding: 15px 15px 0 15px;
  }
}
.testi-style2 .testi-title {
  font-size: 22px;
  margin-bottom: 20px;
}
.testi-style2 .testi-author {
  color: var(--title-color);
}
.testi-style2 .testi-author a {
  color: var(--title-color);
  font-weight: 700;
  text-decoration: underline;
}
.testi-style2 .star-rating {
  margin-bottom: 10px;
}
.testi-style2 .testi-footer {
  display: flex;
  justify-content: space-between;
}
.testi-style2 .testi-ratings {
  flex: none;
}
.testi-style2 .testi-image {
  margin-bottom: -33px;
  border-radius: 33px;
  overflow: hidden;
  border: 1px solid var(--white-color);
  margin-right: -20px;
}
@media (max-width: 1199px) {
  .testi-style2 .testi-image {
    max-width: 100px;
  }
}
.testi-style2 .testi-text {
  margin: 0 26px 45px 0;
  line-height: 30px;
}

.testi-ele1,
.testi-ele2,
.testi-ele3 {
  position: absolute;
}

.testi-ele1 {
  top: 424px;
  left: 0;
}

.testi-ele2 {
  left: 91px;
  bottom: 237px;
}

.testi-ele3 {
  left: auto;
  top: 268px;
  right: 39px;
}

/*------------------- 4.19. Popup Side Menu  -------------------*/
.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;
}
.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: none;
  color: var(--white-color);
  background-color: var(--theme-color);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  transform: rotate(0);
  transition: all ease 0.4s;
}
.sidemenu-wrapper .closeButton:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  transform: rotate(90deg);
}
.sidemenu-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 40px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  transition-delay: 1s;
  transition: right ease 1s;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  margin: 0 0 40px 0;
  background-color: transparent;
}
.sidemenu-wrapper .widget_title {
  border-bottom: 1px solid var(--sec-color);
  padding: 0 0 20px 0;
  margin: 0 0 25px 0;
}
.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: all ease 0.8s;
}
.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.offcanvas-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;
}
.offcanvas-wrapper .closeButton {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 20px;
  padding: 0;
  background-color: var(--theme-color);
  color: var(--white-color);
  border: none;
  border-radius: 50%;
  transform: rotate(0);
  transition: all ease 0.4s;
  z-index: 33;
}
.offcanvas-wrapper .closeButton i {
  line-height: inherit;
}
.offcanvas-wrapper .closeButton:hover {
  color: var(--title-color);
  border-color: transparent;
  transform: rotate(90deg);
  background-color: var(--theme-color2);
}
.offcanvas-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 50px 30px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  transition-delay: 1s;
  transition: right ease 1s;
}
.offcanvas-wrapper .sidemenu-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.offcanvas-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.offcanvas-wrapper .widget {
  padding: 0;
  border: none;
  background-color: transparent;
  margin-bottom: 50px;
}
.offcanvas-wrapper .widget .mini_cart_item {
  position: relative;
  border-bottom: 1px solid var(--vs-border-color);
  padding: 0 0 16px 0px;
  margin: 0 0 25px 0;
  min-height: 90px;
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: center;
}
.offcanvas-wrapper .footer-text {
  max-width: 100%;
}
.offcanvas-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: all ease 0.8s;
}
.offcanvas-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.popup-search-box {
  position: fixed;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.95);
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  transform: translateX(-50%);
  transition: all ease 0.4s;
}

.popup-search-box button.searchClose {
  width: 60px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: 40px;
  right: 40px;
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 30px;
  border-radius: 50%;
  transition: all ease 0.4s;
}

.popup-search-box button.searchClose i {
  line-height: inherit;
}

.popup-search-box button.searchClose:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
}

.popup-search-box form {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding-bottom: 40px;
  cursor: auto;
  width: 100%;
  max-width: 700px;
  transform: translate(-50%, -50%) scale(0);
  transition: transform ease 0.4s;
}

@media (max-width: 1199px) {
  .popup-search-box form {
    max-width: 600px;
  }
}
.popup-search-box form input {
  font-size: 14px;
  height: 70px;
  width: 100%;
  border: 2px solid var(--theme-color);
  background-color: rgba(0, 0, 0, 0);
  padding-left: 30px;
  color: #fff;
  border-radius: 50px;
}

.popup-search-box form input::-moz-placeholder {
  color: #fff;
}

.popup-search-box form input::placeholder {
  color: #fff;
}

.popup-search-box form button {
  position: absolute;
  top: 0px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #fff;
  font-size: 20px;
  right: 13px;
  color: var(--white-color);
  cursor: pointer;
  width: 70px;
  height: 70px;
  transition: all ease 0.4s;
  transform: scale(1.001);
}

.popup-search-box form button:hover {
  transform: scale(1.1);
}

.popup-search-box.show {
  opacity: 1;
  visibility: visible;
  width: 100.1%;
  height: 100%;
  transition: all ease 0.4s;
  border-radius: 0;
}

.popup-search-box.show form {
  transition-delay: 0.5s;
  transform: translate(-50%, -50%) scale(1);
}

/*------------------- 4.20. Price Plan  -------------------*/
.price-style {
  background-color: var(--white-color);
  background-image: transparent;
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
  border: 1px solid var(--theme-color2);
  border-radius: 30px;
  padding: 7px;
  transition: all 0.3s ease-in-out;
}
.price-style .price-header {
  background-color: var(--theme-color);
  border-radius: 30px;
  padding: 45px;
  position: relative;
}
.price-style .price-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 61px;
  height: 61px;
  background-color: var(--theme-color2);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.price-style .price-package {
  color: var(--white-color);
  font-size: 20px;
  font-weight: 700;
}
.price-style .price-amount {
  font-family: var(--title-font);
  color: var(--white-color);
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
}
.price-style .price-duration {
  font-size: 16px;
}
.price-style .price-body {
  padding: 45px;
}
.price-style .price-text {
  margin-bottom: 0;
  line-height: 27px;
  transition: all 0.3s ease-in-out;
}
.price-style .price-features {
  padding: 25px 0 30px;
}
.price-style .price-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.price-style .price-features ul li {
  font-size: 16px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 15px;
  font-weight: 600;
}
.price-style .price-features ul li:last-child {
  margin-bottom: 0;
}
.price-style .price-features ul li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--theme-color);
  border-radius: 50%;
  left: 0;
  top: 7px;
}
.price-style .vs-btn {
  display: inline-flex;
}
.price-style:hover, .price-style.price-active {
  background-color: var(--bg3-color);
  color: var(--white-color);
  border-color: var(--bg3-color);
}
.price-style:hover .price-text, .price-style.price-active .price-text {
  color: var(--white-color);
}

/*------------------- 4.21. Forms Style  -------------------*/
.form-style1 {
  background-color: var(--theme-color);
  --bs-gutter-x: 20px;
  padding: 70px 80px 80px 80px;
}
.form-style1 .form-title {
  color: var(--white-color);
  text-align: center;
  margin: 0 0 27px 0;
  padding: 0 0 17px 0;
  position: relative;
}
.form-style1 .form-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #0844bc;
  background-color: var(--white-color);
  opacity: 0.3;
}
.form-style1 .vs-btn {
  width: 100%;
}
.form-style1 textarea,
.form-style1 input {
  height: 55px;
  background-color: var(--white-color);
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--body-color);
  border-radius: 5px;
}
.form-style1 textarea {
  min-height: 180px;
  resize: none;
}

.newsletter-form,
.newsletter-form2,
.newsletter-form3 {
  border-radius: 0;
  padding: 40px 0px;
  background-color: var(--white-color);
}
.newsletter-form .title-area,
.newsletter-form2 .title-area,
.newsletter-form3 .title-area {
  margin-bottom: 0;
  display: flex;
  gap: 23px;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
}
@media (max-width: 991px) {
  .newsletter-form .title-area,
  .newsletter-form2 .title-area,
  .newsletter-form3 .title-area {
    justify-content: center;
  }
}
.newsletter-form .title-area .title-icon,
.newsletter-form2 .title-area .title-icon,
.newsletter-form3 .title-area .title-icon {
  flex: none;
}
.newsletter-form .title-area .sec-title,
.newsletter-form2 .title-area .sec-title,
.newsletter-form3 .title-area .sec-title {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .newsletter-form .title-area .sec-title,
  .newsletter-form2 .title-area .sec-title,
  .newsletter-form3 .title-area .sec-title {
    font-size: 22px;
  }
}
.newsletter-form .sec-text,
.newsletter-form2 .sec-text,
.newsletter-form3 .sec-text {
  margin-bottom: 0;
}
.newsletter-form .form-control,
.newsletter-form2 .form-control,
.newsletter-form3 .form-control {
  margin-bottom: 0;
  height: 60px;
  min-width: 493px;
  border-radius: 30px;
  background-color: transparent;
  width: auto;
}
.newsletter-form .form-control:focus,
.newsletter-form2 .form-control:focus,
.newsletter-form3 .form-control:focus {
  border-color: var(--theme-color);
}
@media (max-width: 1199px) {
  .newsletter-form .form-control,
  .newsletter-form2 .form-control,
  .newsletter-form3 .form-control {
    min-width: 250px;
  }
}
@media (max-width: 767px) {
  .newsletter-form .form-control,
  .newsletter-form2 .form-control,
  .newsletter-form3 .form-control {
    min-width: 100%;
  }
}
.newsletter-form .vs-btn,
.newsletter-form2 .vs-btn,
.newsletter-form3 .vs-btn {
  position: relative;
  top: 0;
  right: 0;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 22px 30px;
  flex: none;
}
@media (max-width: 767px) {
  .newsletter-form .vs-btn,
  .newsletter-form2 .vs-btn,
  .newsletter-form3 .vs-btn {
    flex: auto;
  }
}
.newsletter-form .vs-btn:hover,
.newsletter-form2 .vs-btn:hover,
.newsletter-form3 .vs-btn:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.newsletter-form .form-group,
.newsletter-form2 .form-group,
.newsletter-form3 .form-group {
  display: flex;
  gap: 17px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .newsletter-form2 .form-group {
    justify-content: center;
  }
}

.calc-form {
  background-color: var(--theme-color2);
  margin-left: -300px;
  padding: 80px 100px 80px 300px;
  position: relative;
  border-radius: 30px;
}
@media (max-width: 991px) {
  .calc-form {
    margin-left: 0;
    padding: 20px;
  }
}
.calc-form .sec-title {
  font-size: 45px;
}
@media (max-width: 1199px) {
  .calc-form .sec-title {
    font-size: 40px;
  }
}
.calc-form .form-control {
  border-radius: 27px;
  border: 0;
  height: 54px;
  margin-bottom: 35px;
}
.calc-form .vs-btn {
  padding: 17px 65px;
}
.calc-form .vs-btn::before, .calc-form .vs-btn::after {
  background-color: var(--title-color);
}
.calc-form .vs-btn:hover {
  color: var(--white-color);
}

.newsletter-form2,
.newsletter-form3 {
  background-color: var(--theme-color2);
  border-radius: 50px;
  padding: 53px 65px;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .newsletter-form2,
  .newsletter-form3 {
    padding: 15px;
  }
}
.newsletter-form2 .form-control,
.newsletter-form3 .form-control {
  min-width: 435px;
  width: auto;
}

.newsletter-form2 .form-group {
  gap: 9px;
}
.newsletter-form2 .form-control {
  border-color: var(--vs-border-color);
  background-color: var(--white-color);
}
@media (max-width: 1499px) {
  .newsletter-form2 .form-control {
    min-width: 420px;
  }
}
@media (max-width: 1399px) {
  .newsletter-form2 .form-control {
    min-width: 267px;
  }
}
.newsletter-form2 .vs-btn::before, .newsletter-form2 .vs-btn::after {
  background-color: var(--title-color);
}
.newsletter-form2 .vs-btn:hover {
  color: var(--white-color);
}

.newsletter-form3 {
  padding: 30px 30px 0 80px;
}
@media (max-width: 1399px) {
  .newsletter-form3 {
    padding: 15px 15px 0 15px;
  }
}
@media (max-width: 991px) {
  .newsletter-form3 {
    text-align: center;
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .newsletter-form3 {
    padding: 15px;
  }
}
.newsletter-form3 .sec-title {
  font-size: 50px;
  margin: -0.25em 0 20px 0;
  max-width: 88%;
}
@media (max-width: 1399px) {
  .newsletter-form3 .sec-title {
    font-size: 40px;
  }
}
@media (max-width: 1199px) {
  .newsletter-form3 .sec-title {
    font-size: 30px;
  }
}
@media (max-width: 991px) {
  .newsletter-form3 .sec-title {
    max-width: 100%;
  }
}
.newsletter-form3 .sec-text {
  font-size: 18px;
  margin-bottom: 30px;
}
.newsletter-form3 .form-control {
  height: 62px;
  background-color: var(--white-color);
  border: 0;
  flex: auto;
}
@media (max-width: 991px) {
  .newsletter-form3 .form-control {
    min-width: 100%;
  }
}
.newsletter-form3 .newsletter-thumb {
  border-radius: 50px;
  overflow: hidden;
  margin-bottom: -30px;
}
@media (max-width: 1399px) {
  .newsletter-form3 .newsletter-thumb {
    max-width: 500px;
  }
}
@media (max-width: 1199px) {
  .newsletter-form3 .newsletter-thumb {
    max-width: 420px;
    margin: 0 auto -30px;
  }
}
@media (max-width: 991px) {
  .newsletter-form3 .newsletter-thumb {
    max-width: 100%;
    margin: 0 auto 0px;
  }
}
.newsletter-form3 .newsletter-thumb > img {
  width: 100%;
}
.newsletter-form3 .vs-btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .newsletter-form3 .vs-btn {
    position: static;
  }
}
.newsletter-form3 .vs-btn::before, .newsletter-form3 .vs-btn::after {
  background-color: var(--title-color);
}
.newsletter-form3 .vs-btn:hover {
  color: var(--white-color);
}

.reviews-form {
  background-color: var(--bg2-color);
  padding: 60px;
  border-radius: 30px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .reviews-form {
    padding: 20px;
  }
}
.reviews-form .form-group {
  margin-bottom: 20px;
}
.reviews-form .form-group:last-child {
  margin-top: 10px;
}
.reviews-form textarea.form-control {
  padding-top: 20px;
}
.reviews-form .form-control {
  height: 60px;
  border-radius: 30px;
}
.reviews-form .form-control:focus {
  border-color: var(--theme-color);
}
.reviews-form .blog-inner-title {
  padding-bottom: 0;
  margin-bottom: 10px;
}
.reviews-form .blog-inner-title::after {
  display: none;
}
.reviews-form .form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .reviews-form .form-title {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}
.reviews-form .form-title .form-left {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .reviews-form .form-title .form-left {
    margin-bottom: 10px;
  }
}
.reviews-form .form-title .rating-select {
  margin-bottom: 30px;
  margin-top: 0;
}
.reviews-form .form-title .rating-select label {
  margin-right: 0;
  text-align: right;
  margin-bottom: 10px;
}
.reviews-form .form-title .rating-select .stars {
  font-size: 18px;
}
.reviews-form .form-title .rating-select .stars a {
  height: 20px;
  width: 20px;
}
.reviews-form .form-title .rating-select .stars a::after {
  height: 20px;
  width: 20px;
}
.reviews-form .form-title .rating-select .stars a::before {
  color: var(--text-color);
  font-weight: 600;
}
.reviews-form .form-title .rating-select label {
  display: block;
  color: var(--text-color);
}
@media (max-width: 767px) {
  .reviews-form .form-title .rating-select label {
    text-align: center;
  }
}

.form-style {
  padding: 60px;
  background-color: var(--bg2-color);
  border-radius: 50px;
}
.form-style .sec-title {
  margin: -0.25em 0 10px 0;
}
.form-style .sec-text {
  margin: 0 0 30px 0;
}
.form-style .form-control {
  min-height: 62px;
  border-radius: 999px;
  border: 1px solid var(--vs-border-color);
}
.form-style .form__header__icon {
  position: absolute;
  right: 23px;
  top: 23px;
  width: 72px;
  height: 72px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color2);
  border-radius: 50%;
}
.form-style .form-group:last-child {
  margin-bottom: 0;
}
.form-style .form__check .form__label {
  margin-bottom: 0;
  text-decoration: underline;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.form-style .form__check .form__label:hover {
  color: var(--theme-color);
}
.form-style .form__link {
  color: var(--title-color);
  font-weight: 500;
  text-decoration: underline;
}
.form-style .form__link:hover {
  color: var(--theme-color);
}
.form-style.style2 {
  background-color: var(--bg3-color);
}
.form-style.style2 .form-control {
  border-color: var(--white-color);
}

.form-style2 {
  flex: 1;
  padding: 65px;
}
.form-style2 .form-control {
  height: 62px;
  border-radius: 999px;
}
.form-style2 .form-control:focus {
  border-color: var(--theme-color2);
}
.form-style2 textarea.form-control {
  border-radius: 30px;
  min-height: 120px;
}

.newsletter-form4 {
  transition: all 0.3s ease-in-out;
}
.newsletter-form4 .form-control {
  height: 62px;
  background-color: var(--white-color);
  border: 0;
  flex: auto;
  border: 2px solid var(--theme-color);
  border-radius: 999px;
  transition: all 0.3s ease-in-out;
}
.newsletter-form4 .vs-btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 22px 30px;
  flex: none;
}
.newsletter-form4 .vs-btn:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.newsletter-form4:hover .form-control, .newsletter-form4:focus .form-control {
  border-color: var(--theme-color2);
  transition: all 0.3s ease-in-out;
}

/*------------------- 4.22. Projects  -------------------*/
/*------------------- 4.23. Contact  -------------------*/
.contact-wrapper {
  border: 1px solid var(--vs-border-color);
  border-radius: 30px;
}

.contact-box {
  background-color: var(--bg2-color);
  min-width: 480px;
  overflow: hidden;
  height: auto;
  padding: 60px;
  border-radius: 30px;
  margin: -1px;
}
.contact-box__title {
  font-size: 40px;
  margin-top: -0.22em;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 6px;
}
.contact-box__title::after {
  position: absolute;
  content: "";
  width: 66px;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--theme-color);
}
.contact-box__text {
  max-width: 84%;
}
.contact-box__item {
  display: flex;
  gap: 17px;
  align-items: center;
  margin-bottom: 35px;
}
.contact-box__icon {
  width: 46px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color2);
  border-radius: 50%;
}
.contact-box__label {
  font-size: 16px;
  font-weight: 700;
  margin: -0.14em 0 5px 0;
}
.contact-box__info {
  color: var(--title-color);
  margin-bottom: 0;
}
.contact-box__info a {
  color: var(--title-color);
  display: block;
}
.contact-box__info a:hover {
  color: var(--theme-color);
}
.contact-box__info span {
  display: block;
}
.contact-box .social-links {
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  align-items: start;
  gap: 10px;
}

/*------------------- 4.24. Event  -------------------*/
/*------------------- 4.25. CTA  -------------------*/
/*------------------- 4.25. CTA  -------------------*/
.cate-style {
  text-align: center;
}
.cate-style .cate-title {
  font-size: 25px;
  margin-bottom: 6px;
}
.cate-style .cate-title__link {
  color: var(--title-color);
}
.cate-style .cate-title__link:hover {
  color: var(--theme-color);
}
.cate-style .cate-num {
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.cate-style .cate-num__link {
  display: block;
  color: var(--text-color);
}
.cate-style .cate-figure {
  position: relative;
  margin-bottom: 44px;
}
.cate-style .cate-circle {
  position: absolute;
  width: 240px;
  height: 240px;
  background-color: var(--theme-color);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
  top: 10px;
}
@media (max-width: 1399px) {
  .cate-style .cate-circle {
    width: 200px;
    height: 200px;
  }
}
.cate-style .cate-circle.color1 {
  background-color: #a9b1fc;
}
.cate-style .cate-circle.color2 {
  background-color: #e9edfe;
}
.cate-style .cate-circle.color3 {
  background-color: #f2f0dc;
}
.cate-style .cate-circle.color4 {
  background-color: #f7e2ff;
}
.cate-style .cate-circle.color5 {
  background-color: #e4ffe2;
}
.cate-style .cate-circle.color6 {
  background-color: #fff9c3;
}
.cate-style.style2 {
  margin-bottom: 30px;
}
.cate-style.style2 .cate-icon {
  min-width: 110px;
  min-height: 110px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--banner-color2);
  border-radius: 50%;
  margin-bottom: 30px;
}
.cate-style.style2 .cate-title {
  font-size: 18px;
  font-weight: 700;
}
.cate-style.style2.slick-slide.slick-active {
  border-right: 1px solid var(--vs-border-color6);
}

.cate-item {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 30px;
  position: relative;
}
.cate-item img {
  width: 100%;
  border-radius: 30px;
}
.cate-item .cate-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 11px;
  background-color: var(--theme-color2);
  border-radius: 999px;
  padding: 12px 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
  transition: all 0.3s ease-in-out;
}
.cate-item .cate-name:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

/*------------------- 4.26. Woocommerce  -------------------*/
.woocommerce-message,
.woocommerce-info {
  position: relative;
  border: 1px solid var(--sec-color);
  padding: 11px 20px;
  background-color: var(--smoke-color);
  color: var(--title-color);
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 30px;
  border-radius: 10px;
}
.woocommerce-message a,
.woocommerce-info a {
  color: inherit;
  text-decoration: underline;
}
.woocommerce-message a:hover,
.woocommerce-info a:hover {
  text-decoration: underline;
}
.woocommerce-message:before,
.woocommerce-info:before {
  content: "\f06a";
  font-family: var(--icon-font);
  font-weight: 900;
  margin-right: 10px;
}

.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--smoke-color);
  color: var(--white-color);
  border-color: transparent;
}
.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "\f14a";
  font-weight: 300;
}

.star-rating {
  overflow: hidden;
  position: relative;
  width: 93px;
  height: 1.2em;
  line-height: 1.2em;
  display: block;
  font-family: var(--icon-font);
  font-weight: 700;
  font-size: 14px;
}
.star-rating:before {
  content: "\f005\f005\f005\f005\f005";
  color: var(--rating-color);
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  letter-spacing: 3px;
  font-weight: 400;
}
.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}
.star-rating span:before {
  content: "\f005\f005\f005\f005\f005";
  top: 0;
  position: absolute;
  left: 0;
  color: var(--rating-color);
  letter-spacing: 3px;
  font-weight: 700;
}

.rating-select {
  margin-top: -1em;
  margin-bottom: 15px;
}
.rating-select label {
  font-size: 16px;
  color: var(--title-color);
  font-weight: 700;
  margin: 0 10px 0 0;
  display: inline-block;
}
.rating-select .stars {
  margin-bottom: 0;
  line-height: 1;
  display: inline-block;
}
.rating-select .stars a {
  position: relative;
  height: 14px;
  width: 18px;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}
.rating-select .stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 14px;
  line-height: 1;
  font-family: var(--icon-font);
  content: "\f005";
  font-weight: 600;
  text-indent: 0;
  color: var(--theme-color);
}
.rating-select .stars a:hover ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select .stars:hover a::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select .stars.selected a.active::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select .stars.selected a.active ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select .stars.selected a:not(.active)::before {
  content: "\f005";
  font-weight: 700;
}

.woocommerce-reviews .vs-post-comment:first-child {
  margin-top: 0;
}
.woocommerce-reviews .vs-post-comment:last-child {
  margin-bottom: 0;
}
.woocommerce-reviews .vs-post-comment .comment-avater {
  width: 130px;
  height: 130px;
}
.woocommerce-reviews .vs-post-comment .comment-content .name {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .woocommerce-reviews .vs-post-comment .comment-content .name {
    flex-wrap: wrap;
  }
}
.woocommerce-reviews .vs-post-comment .comment-content .text {
  font-size: 16px;
  font-weight: 500;
}
.woocommerce-reviews .vs-post-comment .rating-select {
  position: absolute;
  right: 15px;
  top: 15px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 2px 20px;
  background-color: var(--bg2-color);
  border-radius: 30px;
}
.woocommerce-reviews .vs-post-comment .rating-select .stars a::before {
  color: var(--theme-color2);
}

.cart_table {
  margin-bottom: 0;
  border-radius: 20px;
}
.cart_table thead tr {
  background-color: var(--theme-color2);
}
.cart_table thead th {
  background-color: transparent;
  border: none;
  color: #000000;
  font-size: 20px;
  font-family: var(--title-font);
  font-weight: 700;
  border-bottom: 2px solid var(--theme-color2);
  border-left: 2px solid var(--theme-color2);
  border-right: 2px solid var(--theme-color2);
  border-top: 2px solid var(--theme-color2);
  text-align: center;
}
.cart_table thead th.cart-col-productname {
  text-align: left;
}
.cart_table td:before,
.cart_table th {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  border: none;
  padding: 28px 50px;
}
.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  transform: translateY(-50%);
  display: none;
}
.cart_table td {
  border: none;
  border-bottom: 1px solid var(--sec-color);
  border-right: 1px solid var(--sec-color);
  color: var(--body-color);
  padding: 25px 34px;
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-align: center;
}
.cart_table td:last-child {
  border-right: none;
}
.cart_table tr:last-child td {
  border-bottom: none;
  padding: 25px 34px 54px;
}
.cart_table .cart_item__des {
  text-align: left;
}
.cart_table .cart_item__des span {
  display: block;
}
.cart_table .product-quantity {
  color: var(--title-color);
}
.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}
.cart_table .cart-productimage {
  border: 1px solid var(--border-color);
  display: inline-flex;
  width: 100px;
  height: 100px;
  line-height: 85px;
  overflow: hidden;
  border-radius: 0;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--vs-border-color);
  padding: 5px;
}
@media (max-width: 767px) {
  .cart_table .cart-productimage {
    width: 93px;
    height: 93px;
  }
}
.cart_table .cart-productimage img {
  transform: scale(1.001);
  width: 100%;
  transition: all ease 0.4s;
}
.cart_table .cart-productimage:hover img {
  transform: scale(1.1);
}
.cart_table .amount {
  font-size: 20px;
  font-weight: 600;
  color: var(--black-color);
  font-family: var(--title-font);
}
.cart_table .cart-productname {
  font-size: 18px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
  line-height: 1;
  display: block;
  margin-bottom: 0px;
}
.cart_table .cart-productname:hover {
  color: var(--theme-color);
}
.cart_table .remove {
  color: var(--theme-color);
  font-size: 18px;
}
.cart_table .remove:hover {
  color: var(--title-color);
}
.cart_table .quantity {
  width: max-content;
  display: inline-flex;
}
.cart_table .qty-input {
  border: 0;
  padding: 0 25px;
  min-height: 54px;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--black-color);
}
.cart_table .actions {
  text-align: right;
  vertical-align: middle;
  padding: 15px 30px;
}
.cart_table .actions > .vs-btn {
  font-size: 16px;
  padding: 17px 28px;
  margin-right: 15px;
  background-color: var(--smoke-color);
}
.cart_table .actions > .vs-btn:last-child {
  margin-right: 0;
}
.cart_table .vs-cart-coupon {
  float: left;
  margin: 0;
  width: 500px;
  max-width: 100%;
  display: flex;
  border: 2px solid var(--smoke-color);
  border-radius: 9999px;
}
.cart_table .vs-cart-coupon input {
  height: 50px;
  color: var(--title-color);
  border: none;
  flex: 1;
  background-color: transparent;
  border-radius: 0;
}
.cart_table .vs-cart-coupon .vs-btn {
  font-size: 16px;
  padding: 17px 22px;
  width: max-content;
  margin: -2px;
  background-color: var(--smoke-color);
}

.cart-footer {
  margin-bottom: 90px;
  padding: 30px 50px;
  background-color: var(--bg2-color);
  border-radius: 30px;
  margin-top: -30px;
  z-index: 1;
  position: relative;
}

.summary-title {
  position: relative;
  margin-bottom: 22px;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 800;
}

.cart_totals tfoot .order-total td {
  border-top: 2px solid var(--theme-color);
  text-transform: uppercase;
  color: var(--theme-color);
  font-size: 18px;
  padding: 20px 20px;
}
@media (max-width: 767px) {
  .cart_totals tfoot .order-total td {
    width: 40%;
    line-height: 1.4;
    font-size: 15px;
  }
}

.cart_totals {
  background: var(--white-color);
  padding: 25px;
  margin-bottom: 18px;
  border-radius: 20px;
  border: 5px solid var(--vs-border-color5);
}
.cart_totals table {
  border: 1px solid var(--border-color);
  margin-bottom: 0;
}
.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 14px 20px;
  border: none;
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}
.cart_totals th:first-child,
.cart_totals td:first-child {
  font-weight: 800;
  font-size: 16px;
  color: var(--title-color);
  font-family: var(--title-font);
}
.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}
.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}
.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}
.cart_totals .woocommerce-shipping-methods {
  display: grid;
  gap: 6px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .cart_totals .woocommerce-shipping-methods label {
    font-size: 14px;
  }
}
.cart_totals .woocommerce-shipping-methods label::before {
  top: calc((100% - 24px) / 2);
  line-height: 18px;
}
.cart_totals .shipping-calculator-form {
  display: none;
}
.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}
.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}
.cart_totals .shipping-calculator-form .vs-btn {
  padding: 13px 30px;
}
.cart_totals .amount {
  font-weight: 800;
  font-size: 16px;
  font-family: var(--title-font);
}
.cart_totals .order-total .amount {
  color: var(--theme-color);
  font-size: 18px;
}
.cart_totals input,
.cart_totals select {
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: right 20px center;
  font-size: 14px;
}

/* Medium devices */
@media (max-width: 991px) {
  .cart_table .amount {
    font-size: 16px;
  }
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }
  .cart_table .cart-productname {
    font-size: 14px;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
  }
  .cart_table .actions {
    text-align: center;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 0px;
    border: none;
  }
  .cart_table thead {
    display: none;
  }
  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid var(--sec-color);
    border-bottom: none;
  }
  .cart_table td::before {
    display: block;
  }
  .cart_table td:last-child {
    border-bottom: 1px solid var(--sec-color);
  }
  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }
  .cart_table td.actions > .vs-btn {
    margin-top: 10px;
    display: block;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .cart_table td.actions > .vs-btn:last-child {
    margin-right: auto;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    justify-content: center;
    display: block;
    padding-bottom: 10px;
    border: none;
    border-radius: 0;
  }
  .cart_table .vs-cart-coupon input {
    border: 1px solid var(--smoke-color);
    border-radius: 9999px;
    width: 100%;
    margin-bottom: 10px;
  }
  .cart_totals th,
  .cart_totals td {
    padding: 15px 10px;
  }
  .cart_totals th:first-child,
  .cart_totals td:first-child {
    width: 40%;
    line-height: 1.4;
  }
}
.cart_item__des {
  display: grid;
  gap: 15px;
}
.cart_item .star-rating {
  width: 72px;
  font-weight: 700;
  font-size: 10px;
}
.cart_item .star-rating::before {
  font-weight: 700;
  color: var(--rating-color);
}

.cart-totals--wrapper,
.vs-cart-coupon {
  background: var(--theme-color2);
  padding: 50px;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  border-radius: 20px;
}
@media (max-width: 991px) {
  .cart-totals--wrapper,
  .vs-cart-coupon {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .cart-totals--wrapper,
  .vs-cart-coupon {
    padding: 15px;
  }
}

.cart-totals--wrapper {
  background-color: #f9eeea;
}

.vs-cart-coupon {
  background-color: var(--theme-color2);
}
.vs-cart-coupon .form-control {
  height: 63px;
  margin-bottom: 15px;
  border-radius: 999px;
  border: 0;
}
.vs-cart-coupon .vs-btn {
  height: 63px;
}
.vs-cart-coupon .vs-btn:hover {
  color: var(--white-color);
}
.vs-cart-coupon .vs-btn::after, .vs-cart-coupon .vs-btn::before {
  background-color: var(--title-color);
}

.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
}
.woocommerce-checkout-payment .payment_box {
  color: var(--title-color);
  background-color: var(--grey-color);
  border: 1px solid var(--border-color);
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}
.woocommerce-checkout-payment input[type=radio] ~ label {
  color: var(--white-color);
}
.woocommerce-checkout-payment input[type=radio] ~ label::before {
  border-radius: 0%;
  background: transparent;
  color: transparent;
}
.woocommerce-checkout-payment input[type=radio]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: transparent;
}

/*------------------- 4.27. Shop  -------------------*/
.product-style1,
.product-style4,
.product-style5,
.product-style6 {
  background-color: var(--white-color);
  border-radius: 30px;
  margin-bottom: 30px;
  border: 1px solid var(--white-color);
  transition: all 0.3s ease-in-out;
  position: relative;
}
.product-style1 .product-img,
.product-style4 .product-img,
.product-style5 .product-img,
.product-style6 .product-img {
  padding-top: 15px;
}
.product-style1 .product-img .img,
.product-style4 .product-img .img,
.product-style5 .product-img .img,
.product-style6 .product-img .img {
  height: 280px;
  object-fit: contain;
}
.product-style1 .product-tag1,
.product-style1 .product-tag2,
.product-style4 .product-tag1,
.product-style4 .product-tag2,
.product-style5 .product-tag1,
.product-style5 .product-tag2,
.product-style6 .product-tag1,
.product-style6 .product-tag2 {
  position: absolute;
  top: 0;
}
.product-style1 .product-tag1,
.product-style4 .product-tag1,
.product-style5 .product-tag1,
.product-style6 .product-tag1 {
  left: auto;
  right: 20px;
  top: 20px;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
.product-style1 .product-tag2,
.product-style4 .product-tag2,
.product-style5 .product-tag2,
.product-style6 .product-tag2 {
  top: 20px;
  left: 20px;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 1px 14px;
  font-family: var(--title-font);
  border-radius: 15px;
  border: 1px solid #299310;
}
.product-style1 .product-content,
.product-style4 .product-content,
.product-style5 .product-content,
.product-style6 .product-content {
  position: relative;
  padding: 0 30px 30px 30px;
}
@media (max-width: 1199px) {
  .product-style1 .product-content,
  .product-style4 .product-content,
  .product-style5 .product-content,
  .product-style6 .product-content {
    padding: 0 15px 15px 15px;
  }
}
.product-style1 .product-actions,
.product-style4 .product-actions,
.product-style5 .product-actions,
.product-style6 .product-actions {
  display: flex;
  position: absolute;
  right: 30px;
  bottom: 25px;
  align-items: center;
}
.product-style1 .cart-btn,
.product-style4 .cart-btn,
.product-style5 .cart-btn,
.product-style6 .cart-btn {
  width: 47px;
  height: 47px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color2);
  font-size: 20px;
  color: var(--title-color);
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.product-style1 .vs-btn,
.product-style4 .vs-btn,
.product-style5 .vs-btn,
.product-style6 .vs-btn {
  font-size: 14px;
  padding: 11px 59px 11px 22px;
  margin-right: -47px;
  transform: scaleX(0);
  transform-origin: right;
  opacity: 0;
  visibility: hidden;
}
.product-style1 .product-title,
.product-style4 .product-title,
.product-style5 .product-title,
.product-style6 .product-title {
  font-size: 18px;
  margin-bottom: 10px;
  padding-right: 15px;
}
.product-style1 .product-title a,
.product-style4 .product-title a,
.product-style5 .product-title a,
.product-style6 .product-title a {
  color: var(--title-color);
}
.product-style1 .product-title a:hover,
.product-style4 .product-title a:hover,
.product-style5 .product-title a:hover,
.product-style6 .product-title a:hover {
  color: var(--theme-color);
}
.product-style1 .product-cate,
.product-style4 .product-cate,
.product-style5 .product-cate,
.product-style6 .product-cate {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 5px;
}
.product-style1 .product-price,
.product-style4 .product-price,
.product-style5 .product-price,
.product-style6 .product-price {
  font-size: 24px;
  font-weight: 800;
}
.product-style1 .star-rating,
.product-style4 .star-rating,
.product-style5 .star-rating,
.product-style6 .star-rating {
  font-size: 10px;
  margin-bottom: 13px;
}
.product-style1:hover,
.product-style4:hover,
.product-style5:hover,
.product-style6:hover {
  border: 1px solid var(--theme-color2);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
}
.product-style1:hover .vs-btn,
.product-style4:hover .vs-btn,
.product-style5:hover .vs-btn,
.product-style6:hover .vs-btn {
  opacity: 1;
  visibility: visible;
  transform: scaleX(1);
}

.product-style1 {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
}
.product-style1:hover {
  border: 1px solid var(--theme-color2);
}

.product-style4 {
  text-align: center;
  border-radius: 0;
  border: 0;
  margin-bottom: 17px;
}
@media (max-width: 991px) {
  .product-style4 {
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 30px;
  }
}
.product-style4 .product-content {
  padding: 0 40px 0px 40px;
}
@media (max-width: 1399px) {
  .product-style4 .product-content {
    padding: 0 10px;
  }
}
.product-style4 .product-price {
  display: block;
  margin-bottom: 10px;
}
.product-style4 .star-rating {
  margin: 0 auto 13px;
}
.product-style4 .product-actions {
  position: static;
  margin-left: 0px;
  transition: all 0.3s ease-in-out;
  justify-content: center;
}
.product-style4 .product-actions .vs-btn {
  display: none;
}
.product-style4 .product-actions .cart-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.product-style4:hover {
  border: 0;
  box-shadow: none;
}
.product-style4:hover .product-actions {
  margin-left: 0;
}

.product-col {
  border-right: 1px solid var(--vs-border-color);
}
@media (max-width: 991px) {
  .product-col {
    border: 0;
  }
}
.product-col:last-child {
  border-right: 0;
}

.product-layout2 {
  background-color: var(--bg-color1);
  padding: 120px 0 90px;
}
@media (max-width: 767px) {
  .product-layout2 {
    padding: 80px 0 50px;
  }
}
.product-layout2 .slick-dots button {
  background-color: var(--white-color);
}

.product-carousel {
  padding-top: 40px;
}
.product-carousel .slick-dots {
  margin: 40px 0 90px 0;
}

.product-features {
  border-top: 1px solid var(--vs-border-color2);
  padding: 100px 40px 0 40px;
}
@media (max-width: 767px) {
  .product-features {
    padding: 50px 0 0;
  }
}

.product-style2 .counter-title {
  font-weight: 600;
  background-color: var(--theme-color2);
  border-radius: 13.5px;
  font-size: 14px;
  padding: 1px 20px;
  display: block;
  max-width: 45%;
  text-align: center;
  margin-bottom: -14px;
  margin-left: 22px;
}
@media (max-width: 1399px) {
  .product-style2 .counter-title {
    max-width: 53%;
  }
}
@media (max-width: 767px) {
  .product-style2 .counter-title {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 0;
  }
}
.product-style2 .product-tax {
  display: block;
}
.product-style2 .product-text {
  margin-bottom: 5px;
  color: var(--white-color);
}
.product-style2 .product-title {
  color: var(--white-color);
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}
.product-style2 .star-rating {
  margin-bottom: 24px;
}
.product-style2 .product-price {
  font-size: 30px;
  font-weight: 800;
  color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-style2 .product-price del {
  font-size: 19px;
}
.product-style2 .product-tax {
  font-size: 15px;
  color: var(--white-color);
  display: block;
  margin-bottom: 40px;
}
.product-style2 .product-image {
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .product-style2 .product-image {
    text-align: center;
  }
}
.product-style2 .product-image .product-circle {
  width: 411.52px;
  height: 408.01px;
  position: absolute;
  bottom: 0;
  background-color: var(--theme-color2);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media (max-width: 1199px) {
  .product-style2 .product-image .product-circle {
    width: 321.52px;
    height: 321.52px;
  }
}
@media (max-width: 991px) {
  .product-style2 .product-image .product-circle {
    width: 411.52px;
    height: 408.01px;
  }
}
@media (max-width: 991px) {
  .product-style2 .product-image .product-circle {
    width: 300px;
    height: 300px;
  }
}

.item-style {
  display: flex;
  align-items: center;
  gap: 23px;
  max-width: 250px;
}
.item-style .item-icon {
  flex: none;
  position: relative;
  z-index: 1;
}
.item-style .item-icon::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  background-color: var(--bg-color2);
  z-index: -1;
  border-radius: 50%;
  right: -7px;
  top: -7px;
}
.item-style .item-title {
  font-family: var(--body-font);
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
  color: var(--white-color);
  max-width: 152px;
}

.buying-options .buying-title {
  color: var(--white-color);
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
  font-family: var(--body-font);
  margin-bottom: 50px;
}
.buying-options .buying-title::before {
  content: "";
  width: 66px;
  height: 3px;
  background-color: var(--theme-color2);
  display: inline-flex;
  position: absolute;
  bottom: 0;
  left: 0;
}
.buying-options .form-check {
  padding-left: 0;
  margin-bottom: 25px;
}
.buying-options .form-check .form-check-label {
  color: var(--white-color);
  font-weight: 400;
}
.buying-options .form-check .form-check-label::before {
  top: -3px;
  line-height: 14px;
  text-align: center;
  border: 3px solid var(--theme-color);
  background-color: transparent;
  font-size: 0em;
}
.buying-options .form-check input[type=radio]:checked ~ label::before {
  background-color: var(--theme-color);
  font-size: 0.5em;
}
.buying-options .form-check:last-of-type {
  margin-bottom: 45px;
}

.product-style3 {
  display: flex;
  gap: 22px;
  align-items: stretch;
  background-color: var(--white-color);
  border-radius: 30px;
}
@media (max-width: 1399px) {
  .product-style3 {
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .product-style3 {
    flex-direction: column;
  }
}
.product-style3 .product-img {
  flex: none;
  min-width: 300px;
  padding-left: 45px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1399px) {
  .product-style3 .product-img {
    padding-left: 0;
    min-width: 220px;
  }
}
@media (max-width: 1199px) {
  .product-style3 .product-img {
    min-width: 180px;
  }
}
@media (max-width: 767px) {
  .product-style3 .product-img {
    text-align: center;
  }
}
.product-style3 .product-img img {
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1399px) {
  .product-style3 .product-img img {
    max-width: 220px;
  }
}
@media (max-width: 1199px) {
  .product-style3 .product-img img {
    max-width: 180px;
  }
}
.product-style3 .product-img::before {
  position: absolute;
  content: "";
  width: 182px;
  height: 100%;
  background-color: var(--theme-color2);
  z-index: -1;
  left: 0;
  border-radius: 30px;
}
@media (max-width: 1199px) {
  .product-style3 .product-img::before {
    width: 100px;
  }
}
.product-style3 .product-title {
  font-size: 22px;
  margin: 0 0 10px 0;
}
@media (max-width: 1199px) {
  .product-style3 .product-title {
    font-size: 20px;
  }
}
.product-style3 .product-title a {
  color: var(--title-color);
  display: block;
}
.product-style3 .product-title a:hover {
  color: var(--theme-color);
}
.product-style3 .product-cate {
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  font-size: 14px;
  text-decoration: underline;
}
.product-style3 .product-weight {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.product-style3 .product-price {
  font-size: 28px;
  font-weight: 800;
  display: block;
  padding: 8px 0;
}
.product-style3 .product-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
.product-style3 .star-rating {
  font-size: 11px;
  margin-bottom: 20px;
}
.product-style3 .star-rating::before {
  letter-spacing: 6px;
}
.product-style3 .star-rating span::before {
  letter-spacing: 6px;
}
.product-style3 .product-content {
  padding: 35px 35px 35px 0;
  align-self: center;
}
@media (max-width: 767px) {
  .product-style3 .product-content {
    padding: 15px;
  }
}
.product-style3 .vs-btn {
  padding: 17px 38px;
}
.product-style3 .heart-btn {
  height: 50px;
  min-width: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--vs-border-color);
  border-radius: 50%;
  color: var(--title-color);
}
.product-style3 .heart-btn:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
  border-color: var(--theme-color2);
}

.product-tab-menu {
  margin-bottom: -30px;
}
.product-tab-menu .nav-tabs {
  border-bottom: 0;
  justify-content: center;
  gap: 27px;
}
.product-tab-menu .nav-tabs .nav-link {
  display: flex;
  align-items: center;
  gap: 17px;
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 3px 17px 3px 3px;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
  border: 1px solid var(--vs-border-color3);
}
.product-tab-menu .nav-tabs .nav-link span {
  min-width: 52px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  background-color: var(--smoke-color);
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.product-tab-menu .nav-tabs .nav-link:hover, .product-tab-menu .nav-tabs .nav-link.active {
  background-color: var(--theme-color);
  color: var(--white-color);
  border: 1px solid var(--theme-color);
}
.product-tab-menu .nav-tabs .nav-link:hover span, .product-tab-menu .nav-tabs .nav-link.active span {
  background-color: var(--white-color);
}

.product-tab-body {
  background-color: var(--white-color);
  box-shadow: 0px 21px 10px 0px rgba(4, 4, 4, 0.05);
  padding: 78px 0 41px 0;
  border-radius: 30px;
}
@media (max-width: 991px) {
  .product-tab-body {
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 0;
  }
}

.product-style5 {
  text-align: center;
  border: 1px solid var(--vs-border-color);
  border-radius: 30px;
  margin-bottom: 52px;
}
.product-style5 .product-title {
  padding: 0 20px 20px;
  border-bottom: 1px solid var(--vs-border-color3);
  margin-bottom: 20px;
}
.product-style5 .product-cate {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 10px;
}
.product-style5 .star-rating {
  width: auto;
}
.product-style5 .star-rating::before,
.product-style5 .star-rating span::before {
  left: 50%;
  transform: translateX(-50%);
}
.product-style5 .product-actions2 {
  display: inline-flex;
}
.product-style5 .product-content {
  padding: 0 30px 0px 30px;
  margin-bottom: -22px;
}
.product-style5 .product-price {
  margin-bottom: 15px;
  display: block;
}
.product-style5 .vs-btn {
  transform: scaleX(1);
  opacity: 1;
  visibility: visible;
  padding: 0 25px 0 0;
  margin: 0;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 2px solid var(--theme-color2);
  font-size: 16px;
  font-weight: 700;
}
.product-style5 .vs-btn i {
  margin-left: 0px;
  background-color: var(--theme-color2);
  margin-right: 15px;
  color: var(--title-color);
  transform: translateX(-2px);
  width: 42px;
}
.product-style5 .vs-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}
.product-style5 .vs-btn:hover::before, .product-style5 .vs-btn:hover::after {
  background-color: var(--theme-color);
}
.product-style5 .vs-btn:hover i {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.product-style1 .product-title,
.product-style6 .product-title {
  padding-right: 20px;
}

.product-style6 {
  border: 1px solid var(--vs-border-color4);
}

.product-style7 {
  display: flex;
  align-items: stretch;
  border: 1px solid var(--vs-border-color3);
  border-radius: 30px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}
.product-style7 .star-rating {
  margin-bottom: 15px;
}
.product-style7 .product-weight {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.product-style7 .product-price {
  font-size: 16px;
  font-weight: 700;
}
.product-style7 .product-price del {
  font-size: 18px;
  color: var(--text-color);
  padding-left: 5px;
}
.product-style7 .product-img {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  border: 1px solid var(--vs-border-color3);
  border-radius: 30px;
  overflow: hidden;
  margin: -1px;
}
@media (max-width: 767px) {
  .product-style7 .product-img {
    flex: auto;
    border-radius: 30px 30px 0 0;
  }
}
.product-style7 .product-img a {
  height: 100%;
}
.product-style7 .product-img img {
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1199px) {
  .product-style7 .product-img img {
    max-width: 167px;
  }
}
@media (max-width: 767px) {
  .product-style7 .product-img img {
    max-width: 100%;
  }
}
.product-style7 .product-content {
  padding: 20px 60px 20px 40px;
  flex: 1;
  align-self: center;
}
@media (max-width: 1199px) {
  .product-style7 .product-content {
    padding: 20px 20px 20px 20px;
  }
}
.product-style7 .product-title {
  font-size: 18px;
  margin: -0.14em 0 10px 0;
}
.product-style7 .product-title a {
  color: var(--title-color);
}
.product-style7 .product-title a:hover {
  color: var(--theme-color);
}
.product-style7 .cart-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  --icon-size: 47px;
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  background-color: var(--theme-color2);
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--title-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}
.product-style7 .cart-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.product-style8 {
  margin-bottom: 30px;
}
.product-style8:last-child {
  margin-bottom: 0;
}
.product-style8 .product-title {
  font-size: 18px;
  margin-bottom: 10px;
}
.product-style8 .product-title a {
  color: var(--title-color);
}
.product-style8 .product-title a:hover {
  color: var(--theme-color);
}
.product-style8 .product-img {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin-bottom: 16px;
}
.product-style8 .cart-btn {
  width: 47px;
  height: 47px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color2);
  font-size: 20px;
  color: var(--title-color);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 10px;
}
.product-style8 .star-rating {
  margin-bottom: 10px;
}
.product-style8 .product-price {
  font-size: 24px;
  font-weight: 800;
}

.product-about {
  padding-left: 35px;
}
@media (max-width: 1199px) {
  .product-about {
    padding-left: 0;
  }
}
.product-about .product-rating {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}
@media (max-width: 767px) {
  .product-about .product-rating {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.product-about .product-rating__total {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
}
.product-about .star-rating {
  margin-right: 10px;
  font-size: 14px;
  width: 109px;
}
.product-about .star-rating::before,
.product-about .star-rating span::before {
  color: var(--rating-color);
  transform: translate(0%, 0%);
  font-weight: 700;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  letter-spacing: 7px;
}
.product-about .product-title {
  font-size: 38px;
  margin-bottom: 25px;
  font-weight: 700;
  line-height: 1.2;
  max-width: 88%;
  /* Large devices */
}
@media (max-width: 1199px) {
  .product-about .product-title {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .product-about .product-title {
    font-size: 28px;
    margin-bottom: 25px;
  }
}
.product-about .product-ml {
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
  color: var(--text-color);
}
.product-about .qty-input {
  border: 0;
  padding: 0 25px;
  min-height: 54px;
  font-size: 16px;
  font-weight: 600;
  color: var(--black-color);
}
.product-about .product-instock {
  display: inline-flex;
  align-items: center;
  color: #1f9d25;
  font-size: 18px;
  font-weight: 700;
  margin-right: 15px;
  gap: 10px;
  line-height: 1;
  padding-top: 4px;
}
.product-about .product-price {
  font-size: 40px;
  font-weight: 700;
  color: var(--black-color);
  line-height: 1;
  margin: 0 0 40px 0;
}
.product-about .product-price del {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 700;
}
.product-about .product-text {
  margin-bottom: 24px;
}
.product-about .actions {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  margin-bottom: 35px;
  align-items: center;
}
.product-about .pro-btns {
  display: flex;
  align-items: center;
  gap: 14px;
  padding-bottom: 35px;
  border-bottom: 1px solid var(--vs-border-color9);
  margin-bottom: 35px;
}
.product-about .vs-btn {
  padding: 19px 70px;
}
.product-about .vs-btn::after, .product-about .vs-btn::before {
  background-color: var(--theme-color2);
}
.product-about .icon-btn {
  background-color: transparent;
  color: var(--black-color);
  border: 1px solid var(--vs-border-color);
  font-size: 20px;
  width: var(--btn-size, 54px);
  height: var(--btn-size, 54px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.product-about .icon-btn::before {
  background-color: var(--theme-color);
  --border-size: 0;
}
.product-about .icon-btn:hover {
  border-color: var(--theme-color2);
  color: var(--title-color);
  background-color: var(--theme-color2);
}
.product-about .product-getway {
  padding-top: 30px;
}
.product-about .getway-title {
  font-size: 14px;
  color: var(--black-color);
  font-weight: 700;
  margin: -0.15em 0 15px 0;
  line-height: 1;
  font-family: var(--body-font);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: block;
}
.product-about .counter-title {
  font-weight: 600;
  background-color: var(--theme-color2);
  border-radius: 13.5px;
  font-size: 14px;
  padding: 1px 14px;
  display: block;
  text-align: center;
  margin-bottom: -14px;
  margin-left: 22px;
  max-width: 161px;
  z-index: 1;
  position: relative;
}
.product-about .counter-style {
  display: inline-flex;
  background-color: var(--smoke-color);
  border: 0;
  margin-bottom: 35px;
}
.product-about .counter-style .count-number,
.product-about .counter-style .count-name {
  color: var(--title-color);
}
.product-about .counter-style .offer-counter > li {
  padding: 30px 30px 20px 30px;
}
@media (max-width: 767px) {
  .product-about .counter-style .offer-counter > li {
    padding: 0;
  }
}
.product-about .counter-style .offer-counter {
  min-height: 103px;
}
@media (max-width: 767px) {
  .product-about .counter-style .offer-counter {
    gap: 20px;
    padding: 20px;
  }
}

.product-big-img {
  background-color: var(--bg4-color);
  border: 1px solid var(--theme-color2);
  margin-bottom: 25px;
  text-align: center;
  border-radius: 30px;
  overflow: hidden;
}
.product-big-img img {
  width: 100%;
}

@media (max-width: 767px) {
  .product-thumb-slide {
    max-width: 100%;
  }
}
.product-thumb-slide.row {
  --bs-gutter-x: 20px;
}
.product-thumb-slide .thumb {
  background-color: var(--bg4-color);
  overflow: hidden;
  transition: all ease 0.4s;
  border: 1px solid var(--vs-border-color8);
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 0px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .product-thumb-slide .thumb {
    margin-bottom: 0;
    margin: 0 7.5px;
  }
}
.product-thumb-slide .thumb img {
  transition: all ease 0.4s;
  width: 100%;
}
.product-thumb-slide .thumb:hover {
  border-color: var(--theme-color);
}
.product-thumb-slide .slick-current.slick-active .thumb {
  border-color: var(--theme-color2);
}

.qty-btn {
  background: transparent;
  border: 0;
  padding: 0;
  line-height: 1;
  display: inline-flex;
  margin: -4px 0;
  color: var(--text-color);
}

.quantity {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 21px;
  flex-wrap: wrap;
}
.quantity .screen-reader-text {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--text-color);
}
.quantity__field {
  display: flex;
  align-items: center;
  border: 2px solid var(--theme-color2);
  min-width: 144px;
  padding-right: 25px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .quantity__field {
    min-width: 130px;
  }
}
.quantity__buttons {
  display: inline-flex;
  flex-direction: column;
  gap: 0px;
}

.product_meta {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
}
.product_meta > span {
  display: flex;
  margin-bottom: 10px;
  color: var(--theme-color);
  font-weight: 700;
}
@media (max-width: 767px) {
  .product_meta > span {
    flex-wrap: wrap;
  }
}
.product_meta > span > p {
  margin-bottom: 0;
  color: var(--theme-color);
}
.product_meta > span:last-child {
  margin-bottom: 0;
}
.product_meta > span a {
  color: inherit;
}
.product_meta > span a:hover {
  color: var(--theme-color);
}
.product_meta > span > a,
.product_meta > span > span {
  position: relative;
  margin-left: 3px;
  color: var(--text-color);
  text-transform: capitalize;
  font-weight: 700;
}
.product_meta > span > a:first-child,
.product_meta > span > span:first-child {
  margin-left: 5px;
}
.product_meta > span > a:not(:last-child)::after,
.product_meta > span > span:not(:last-child)::after {
  content: ",";
  margin-right: 3px;
}

.pros,
.cons {
  background: #edffd9;
  padding: 50px;
  border-radius: 30px;
}
@media (max-width: 991px) {
  .pros,
  .cons {
    padding: 15px;
  }
}
.pros .list-style4,
.cons .list-style4 {
  margin-bottom: 0;
}
.pros .list-style4 ul li,
.cons .list-style4 ul li {
  margin-bottom: 15px;
}

.cons {
  background-color: #fff1e0;
}

.product-description p {
  font-weight: 500;
  line-height: 32px;
  padding-right: 10px;
}

.product-information__item {
  background-color: #f5f5f5;
  min-height: 66px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--black-color);
  margin-bottom: 10px;
  border-radius: 999px;
}
.product-information__item span {
  color: var(--text-color);
  font-size: 18px;
}
@media (max-width: 767px) {
  .product-information__item {
    font-size: 16px;
  }
}
.product-information__item:last-child {
  margin-bottom: 0;
}
.product-information__name {
  color: var(--text-color);
  background-color: var(--white-color);
  min-height: 66px;
  border: 1px solid var(--vs-border-color10);
  display: inline-flex;
  align-items: center;
  min-width: 310px;
  padding: 10px 47px;
  margin-right: 25px;
  border-radius: 999px;
}
@media (max-width: 991px) {
  .product-information__name {
    min-width: 180px;
    padding: 10px 15px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .product-information__name {
    min-width: 140px;
  }
}

.product-layout2 .ele1,
.product-layout2 .ele2 {
  position: absolute;
}
.product-layout2 .ele1 {
  top: 123px;
  left: 69px;
}
.product-layout2 .ele2 {
  bottom: 345px;
  right: 100px;
}

.product-slider1 .slick-slide.slick-current.slick-active .product-img::before {
  background-color: var(--theme-color);
}

.product-layout3i .ele1 {
  position: absolute;
  right: 0;
  top: 25%;
}

/*------------------- 4.28. Banner  -------------------*/
.banner-style {
  background-color: var(--banner-color1);
  padding: 22px 36px 22px 54px;
  border-radius: 30px;
  margin-bottom: 30px;
  min-height: 279px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .banner-style {
    padding: 15px;
  }
}
.banner-style .banner-content {
  max-width: 255px;
}
.banner-style .banner-title {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 15px;
}
.banner-style .banner-title a {
  color: var(--black-color);
}
.banner-style .banner-text {
  margin-bottom: 30px;
  font-weight: 500;
}
.banner-style .banner-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-color);
  font-size: 16px;
  font-weight: 700;
  color: var(--white-color);
  font-family: var(--title-font);
  gap: 7px;
  padding: 10px 25px;
  border-radius: 9999px;
}
.banner-style .banner-link:hover {
  background-color: var(--title-color);
}
.banner-style .banner-img {
  position: relative;
  z-index: 1;
}
@media (max-width: 1399px) {
  .banner-style .banner-img {
    width: 200px;
  }
}
@media (max-width: 1199px) {
  .banner-style .banner-img {
    padding-top: 30px;
  }
}
.banner-style .banner-circle {
  position: absolute;
  width: 219px;
  height: 219px;
  background-color: var(--white-color);
  bottom: 0;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}
@media (max-width: 1399px) {
  .banner-style .banner-circle {
    width: 190px;
    height: 190px;
  }
}
.banner-style.style2 {
  background-color: var(--banner-color2);
}

/*------------------- 4.29. Banner  -------------------*/
.brand-layout1 {
  padding-bottom: 240px;
  background-size: contain;
  background-position: bottom center;
}
@media (max-width: 991px) {
  .brand-layout1 {
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .brand-layout1 {
    padding-bottom: 70px;
  }
}

/*------------------- 4.30. Banner  -------------------*/
.step-item {
  margin-bottom: 30px;
}
.step-item .step-img {
  position: relative;
  display: inline-flex;
  padding: 10px;
  border: 3px dashed var(--theme-color2);
  border-radius: 50%;
  margin-bottom: 30px;
}
.step-item .step-number {
  position: absolute;
  bottom: 5px;
  left: 5px;
  min-width: 60px;
  min-height: 60px;
  background-color: var(--theme-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  border-radius: 50%;
  padding: 5px;
}
.step-item .step-title {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 15px;
}
.step-item .step-text {
  line-height: 30px;
  margin-bottom: 0;
  padding-right: 15px;
}

/*------------------- 4.31. Banner  -------------------*/
.calc-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}
@media (max-width: 991px) {
  .calc-img {
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
  }
}
.calc-img .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clc-layout2 {
  position: relative;
  z-index: 1;
}
.clc-layout2 .calc-ele2-1 {
  position: absolute;
  right: 166px;
  top: 126px;
}

/*------------------- 4.32. Banner  -------------------*/
.vs-sort-bar {
  margin-bottom: 35px;
}
.vs-sort-bar select {
  height: 50px;
  border: 1px solid #eee;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: auto;
  padding: 0 45px 0 25px;
  font-size: 16px;
  margin: 0;
  background-position: right 22px center;
}
.vs-sort-bar label {
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 15px;
  font-weight: 500;
  line-height: 1;
}
.vs-sort-bar p.woocommerce-result-count {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
}
.vs-sort-bar p.woocommerce-result-count span {
  color: var(--theme-color);
}
.vs-sort-bar .nice-select {
  width: 100%;
  min-width: 250px;
  padding-right: 50px;
  padding-left: 26px;
  height: 60px;
  line-height: 64px;
  border-radius: 40px;
  font-size: 16px;
  font-family: var(--body-font);
  border: 1px solid var(--vs-border-color4);
  /* Small devices */
}
.vs-sort-bar .nice-select .current {
  font-weight: 700;
}
.vs-sort-bar .nice-select .list {
  width: 100%;
  border-radius: 0;
  margin-top: 0px;
  border: 1px solid var(--vs-border-color4);
  box-shadow: none;
  z-index: 2;
  border-radius: 30px;
}
.vs-sort-bar .nice-select::before {
  content: "";
  width: 36px;
  height: 36px;
  background: var(--theme-color2);
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.vs-sort-bar .nice-select::after {
  border-bottom: 2px solid var(--title-color);
  border-right: 2px solid var(--title-color);
  height: 8px;
  margin-top: -4px;
  right: 25px;
  width: 8px;
}
@media (max-width: 767px) {
  .vs-sort-bar .nice-select {
    margin-left: auto;
    margin-right: auto;
  }
}
.vs-sort-bar .nav-tabs {
  border-bottom: 0;
  gap: 5px;
}
.vs-sort-bar .nav-link {
  --icon-size: 60px;
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  background-color: transparent;
  border: 1px solid var(--vs-border-color4);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.vs-sort-bar .nav-link:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
  border-color: var(--theme-color2);
}
.vs-sort-bar .nav-link.active {
  border-color: var(--theme-color2);
  background-color: var(--theme-color2);
  color: var(--title-color);
}
.vs-sort-bar .nav-link.active:hover {
  background-color: var(--theme-color2);
  color: var(--title-color);
  border-color: var(--theme-color2);
}

/*------------------- 4.33. Banner  -------------------*/
.woocommerce-checkout .cart_totals {
  margin-bottom: 45px;
}
.woocommerce-checkout .cart-totals--cart {
  background-color: var(--bg3-color);
  padding: 45px;
  border-radius: 30px;
}
.woocommerce-checkout .form-select {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 999px;
}
.woocommerce-checkout .form-select:focus {
  box-shadow: none;
  border-color: var(--theme-color);
}
.woocommerce-checkout .form-select::after {
  display: none;
}
.woocommerce-checkout .nice-select .list {
  width: 100%;
}
.woocommerce-checkout .form-group textarea.form-control {
  border-radius: 30px;
}
.woocommerce-checkout__form,
.woocommerce-checkout .cart-totals--cart {
  margin-bottom: 30px;
}
.woocommerce-checkout__form .form-control {
  height: 60px;
  border-radius: 999px;
}
.woocommerce-checkout__form .form-control:focus {
  height: 60px;
  border: 1px solid var(--theme-color);
}
.woocommerce-checkout__form .form-control + .form-control {
  margin-top: 30px;
}

.shipping_address {
  display: none;
}

/*=================================
    05. Spacing
==================================*/
/*-- Padding Left And Right --*/
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

/*-- Padding Top And Bottom --*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

/*-- margin Left And Right --*/
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

/*-- margin Top And Bottom --*/
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

/*-- margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

/*-- margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

/*-- margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

/*-- margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.space,
.space-top {
  padding-top: var(--section-space);
}

.space,
.space-bottom {
  padding-bottom: var(--section-space);
}

.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}

.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}

/* Medium devices */
@media (max-width: 991px) {
  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }
  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }
  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }
  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
  .space-top-md-none {
    padding-top: 0;
  }
}
/*=================================
    06. Color Plate
==================================*/
/* Color Plate */
.vs-color-plate {
  position: fixed;
  display: block;
  z-index: 91;
  padding: 20px;
  width: 240px;
  background: #fff;
  left: 0;
  text-align: left;
  top: 30%;
  transition: all 0.4s ease;
  box-shadow: -3px 0px 10px -2px rgba(0, 0, 0, 0.1);
  direction: ltr;
  transform: translateX(-100%);
}
.vs-color-plate.open {
  transform: translateX(0);
}
.vs-color-plate .color-plate-icon {
  position: absolute;
  right: -48px;
  width: 48px;
  height: 45px;
  line-height: 45px;
  font-size: 21px;
  border-radius: 0 5px 5px 0;
  text-align: center;
  color: var(--white-color);
  top: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.2117647059);
  background-color: var(--theme-color);
}
.vs-color-plate h4 {
  display: block;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}
.vs-color-plate p {
  font-size: 12px;
  margin-bottom: 15px;
  line-height: 20px;
}
.vs-color-plate .reset-btn {
  border: none;
  padding: 0;
  height: 20px;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--title-color);
  background-color: var(--smoke-color);
  width: 100%;
  height: 40px;
}
.vs-color-plate .reset-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.vs-color-plate input[type=color] {
  border: none;
  padding: 0;
}

.default-color-btn {
  border: none;
  background-color: #000;
  color: var(--white-color);
  font-weight: 400;
  font-family: var(--body-font);
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 0 0 10px 0;
  padding: 5px 0;
}